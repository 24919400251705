import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { CustomerListResDto } from '../../services/customer/customer';
import { useCustomerService } from '../../services/customer/customer.service';
import { CustomerManagementModal } from './customerManagementModal';

interface CustomerManagementProps {}

export const CustomerManagement: React.FC<CustomerManagementProps> = (props) => {
  const service = useCustomerService();
  const [state, setState] = useState<CustomerListResDto[]>([]);
  const { t } = useTranslation();
  const modal = useModal();
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
  }, [service, setState]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<CustomerListResDto>[] = [
    {
      dataIndex: 'logo',
      title: 'Logo',
      render: (v) => <Avatar src={v} />,
    },
    {
      dataIndex: 'information',
      title: 'Customer',
      render: (v, r) => r.information.customer.name,
    },
    {
      dataIndex: 'information',
      title: 'Manager',
      render: (v, r) => r.information.manager.name,
    },
    {
      dataIndex: 'information',
      title: 'ContactManager',
      render: (v, r) => r.information?.contactManager?.name,
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: r.id, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    title: t('Delete'),
                    content: t('DeleteConfirm', { value: r.information.customer.name }),
                    onOk: async () => {
                      await service.deleteById(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];
  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
        <CustomerManagementModal
          data={modalState}
          onCancel={() => setModalState(ModalDataModel())}
          onDone={() => {
            setModalState(ModalDataModel());
            getList();
          }}
        />
      </PageCard>
    </>
  );
};

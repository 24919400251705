import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import { OpinionsResDto } from './dashboard';

export const useDashboardService = () => {
  const http = useHttp();

  const listOpinions = useCallback(
    async (page: number) => {
      page = page || 1;
      return await http.get<OpinionsResDto>(`/opinions`, { params: { page } });
    },
    [http],
  );

  const getOpinions = useCallback(
    async (uid: string) => {
      return await http.get<OpinionsResDto>(`/opinions/${uid}`);
    },
    [http],
  );

  return {
    listOpinions,
    getOpinions,
  };
};

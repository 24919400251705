import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { QuotaTypesCreateDto, QuotaTypesDto } from "./quotaTypes";

export const useQuotaTypesService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<QuotaTypesDto[]>('/quota-type');
    }, [http])

    const create = useCallback(async (req: QuotaTypesCreateDto) => {
        return await http.post<QuotaTypesCreateDto, QuotaTypesDto>('/quota-type', req)
    }, [http])

    const update = useCallback(async (id: number, req: QuotaTypesCreateDto) => {
        return await http.patch<QuotaTypesCreateDto, QuotaTypesDto>(`/quota-type/${id}`, req);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/quota-type/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        deleteById
    }
}
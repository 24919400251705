import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { ProjectCreateDto, ProjectGetListDto } from "./project";

export const useProjectService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<ProjectGetListDto[]>('/project');
    }, [http])

    const create = useCallback(async (req: ProjectCreateDto) => {
        return await http.post<ProjectCreateDto, WithId<ProjectCreateDto>>('/project', req);
    }, [http])

    const update = useCallback(async (id: number, req: Partial<ProjectCreateDto>) => {
        return http.patch<Partial<ProjectCreateDto>, WithId<ProjectCreateDto>>(`/project/${id}`, req);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<WithId<ProjectCreateDto>>(`/project/${id}`);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/project/${id}`)
    }, [http])

    return {
        list,
        create,
        update,
        getById,
        deleteById
    }
}
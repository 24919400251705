import { useCallback } from "react";
import { IdNameDto, NameDto } from "..";
import { useHttp } from "../../hooks/http"

export const useScaleFactorService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<IdNameDto[]>('/scale-factor')
    }, [http])

    const create = useCallback(async (req: NameDto) => {
        return await http.post<NameDto, IdNameDto>('/scale-factor', req);
    }, [http])

    const update = useCallback(async (id: number, req: NameDto) => {
        return await http.patch<NameDto, IdNameDto>(`/scale-factor/${id}`, req);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete<{ id: number }>(`/scale-factor/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        deleteById
    }
}
import { useCallback } from 'react';
import { WithId } from '..';
import { useHttp } from '../../hooks/http';
import { AnswerCreateDto } from './answer';

export const useAnswerService = () => {
  const http = useHttp();

  const create = useCallback(
    async (req: AnswerCreateDto) => {
      return await http.post<AnswerCreateDto, WithId<AnswerCreateDto>>('/answer', req);
    },
    [http],
  );

  const update = useCallback(
    async (id: number, req: AnswerCreateDto) => {
      return await http.patch<AnswerCreateDto, WithId<AnswerCreateDto>>(`/answer/${id}`, req);
    },
    [http],
  );

  const deleteById = useCallback(
    async (id: number) => {
      return await http.delete<{ id: number }>(`/answer/${id}`);
    },
    [http],
  );

  return {
    create,
    update,
    deleteById,
  };
};

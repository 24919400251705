export interface PersonListDto {
  id: number;
  team: {
    id: number;
    name: string;
    scale: number;
  };
  car: number | null;
  name: string;
  lastname: string;
  birthday: string;
  education: {
    id: number;
    text: string;
  };
  description: string;
  email: string;
  type: {
    id: number;
    text: string;
  };
}

export interface PersonCreateDto {
  name: string;
  lastname: string;
  birthday: string;
  education: number;
  type: number;
  // team: number;
  description: string;
  email: string;
  password: string;
}

export interface PersonUpdateDto {
  name: string;
  lastname: string;
  birthday: string;
  education: number;
  type: number;
  team: number;
  description: string;
  email: string;
  password: string;
  car: number;
}

export interface PersonGetByIdDto {
  id: number;
  team: {
    id: number;
    name: string;
    scale: number;
  };
  car: {
    id: number;
    license: string;
  };
  name: string;
  lastname: string;
  birthday: string;
  education: {
    id: number;
    text: string;
  };
  description: string;
  email: string;
  type: {
    id: number;
    text: string;
  };
}

export interface FiltertypeDto {
  text: string;
  value: string | number;
}

export enum PersonTypeEnum {
  'Harici Yüklenici Personel' = 1,
  'Anka Personeli' = 2,
  'Müşteri Personeli' = 3,
}

import { useCallback } from 'react';
import { IdNameDto } from '..';
import { useHttp } from '../../hooks/http';
import { RoleCreateDto, RoleGetByIdDto, RoleUpdate } from './role';

export const useRoleService = () => {
  const http = useHttp();

  const list = useCallback(async () => {
    return await http.get<IdNameDto[]>('/role');
  }, [http]);

  const create = useCallback(
    async (req: RoleCreateDto) => {
      return http.post<RoleCreateDto, IdNameDto>('/role', req);
    },
    [http],
  );

  const update = useCallback(
    async (id: number, req: RoleUpdate) => {
      return await http.patch<RoleUpdate, IdNameDto>(`/role/${id}`, req);
    },
    [http],
  );

  const getById = useCallback(
    async (id: number) => {
      return await http.get<RoleGetByIdDto>(`/role/${id}`);
    },
    [http],
  );

  const remove = useCallback(
    async (id: number) => {
      return await http.delete(`/role/${id}`);
    },
    [http],
  );

  return {
    list,
    create,
    update,
    getById,
    remove,
  };
};

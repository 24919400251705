import { useCallback } from "react";
import { IdNameDto, NameDto } from "..";
import { useHttp } from "../../hooks/http"

export const useScaleJobService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<IdNameDto[]>('/scale-business')
    }, [http])

    const create = useCallback(async (req: NameDto) => {
        return await http.post<NameDto, IdNameDto>('/scale-business', req);
    }, [http])

    const update = useCallback(async (id: number, req: NameDto) => {
        return await http.patch<NameDto, IdNameDto>(`/scale-business/${id}`, req);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete<{ id: number }>(`/scale-business/${id}`)
    }, [http])

    return {
        list,
        create,
        update,
        deleteById
    }

}
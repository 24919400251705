import { ConfigProvider, MenuTheme, theme } from "antd";
import { ThemeConfig } from "antd/es/config-provider/context";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useBrowserStorage } from "../hooks/browserStorage";
import localeTr from 'antd/locale/tr_TR'


interface ThemeContextType {
    mode: MenuTheme;
    toggleThemeMode(): void;
}

export const ThemeContext = createContext<ThemeContextType>({
    toggleThemeMode: () => { },
    mode: 'light'
});

export const ThemeProvider: React.FC = props => {

    const [mode, setMode] = useState<MenuTheme>('light');
    const browserStorage = useBrowserStorage();

    const toggleThemeMode = useCallback(async () => {
        setMode(p => p === 'dark' ? 'light' : 'dark');
    }, [setMode])

    useEffect(() => {
        let savedMode = browserStorage.get<MenuTheme>('theme-mode');
        if (savedMode && (savedMode === 'dark' || savedMode === 'light')) {
            setMode(savedMode);
        } else {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                setMode('dark')
            }
        }
    }, [setMode, browserStorage])

    useEffect(() => {
        browserStorage.set('theme-mode', mode);
    }, [mode, browserStorage])

    const themeData: ThemeConfig = useMemo(() => {
        return {
            algorithm: mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
        }
    }, [mode]);

    return <ThemeContext.Provider
        value={{
            mode,
            toggleThemeMode
        }}
    >
        <ConfigProvider
            theme={themeData}
            locale={localeTr}
            componentSize="middle"
            csp={{
                nonce: 'default-src'
            }}
        >
            {props.children}
        </ConfigProvider>
    </ThemeContext.Provider>
}
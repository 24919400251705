import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import { PermissionUpdateReqDto, PermissionResDto } from './permission';

export const usePermissionService = () => {
  const http = useHttp();

  const list = useCallback(async () => {
    return await http.get<string[]>('/permission');
  }, [http]);

  const update = useCallback(
    async (id: number, req: PermissionUpdateReqDto) => {
      return await http.patch<PermissionUpdateReqDto, PermissionUpdateReqDto>(`/permission/${id}`, req);
    },
    [http],
  );

  const show = useCallback(
    async (id: number) => {
      return await http.get<PermissionResDto>(`/permission/${id}`);
    },
    [http],
  );

  return {
    list,
    update,
    show,
  };
};

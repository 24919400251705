import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../../components/pageCard';
import { IColumnProps } from '../../../components/table';
import { ITable } from '../../../components/table/table';
import { ModalData, ModalDataModel } from '../../../models/system';
import { IdNameDto } from '../../../services';
import { useRoleService } from '../../../services/role/role.service';
import { useStore } from '../../../store';
import { PermissionModal } from './permissionModal';
import { RoleManagementModal } from './roleManagementModal';

interface UserRoleManagementProps {
}

export const UserRoleManagement: React.FC<UserRoleManagementProps> = props => {
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
    const service = useRoleService();
    const [state, setState] = useState<IdNameDto[]>([]);
    const store = useStore();
    const [permissionModalState, setPermissionModalState] = useState<ModalData<number>>(ModalDataModel());

    const getList = useCallback(async () => {
        const res = await service.list();
        if (!res) return;
        setState(res);
    }, [service, setState]);

    useEffect(() => {
        getList();
        // eslint-disable-next-line 
    }, [])

    const columns: IColumnProps<IdNameDto>[] = [
        {
            dataIndex: 'name',
            title: 'RoleName'
        },
        {
            dataIndex: 'id',
            title: 'Action',
            render: (v, r) => <Dropdown.Button menu={{
                items: [
                    {
                        key: 'detail',
                        label: t('Edit').toString(),
                        icon: <EditOutlined />,
                        onClick: () => setModalState(p => ({ data: r.id, open: true }))
                    },
                    {
                        key: 'permissions',
                        label: t('Permission').toString(),
                        icon: <UserOutlined />,
                        onClick: () => setPermissionModalState(p => ({ data: v, open: true }))
                    },
                    // {
                    //     key: 'delete',
                    //     label: t('Delete'),
                    //     icon: <DeleteOutlined />,
                    //     onClick: () => {
                    //         modal.confirm({
                    //             content: t('DeleteConfirm', { value: r.name }),
                    //             onOk: () => onDelete(1)
                    //         })
                    //     }
                    // }
                ]
            }}>
                {t('Action')}
            </Dropdown.Button>
        }
    ]

    return (
        <>
            <PageCard
                extra={<Button type='primary' onClick={() => setModalState(p => ({ data: null, open: true }))}>{t('New')}</Button>}
            >
                <ITable
                    columns={columns}
                    data={state}
                />
            </PageCard>
            <RoleManagementModal
                data={modalState}
                onCancel={() => setModalState(ModalDataModel())}
                onDone={() => {
                    setModalState(ModalDataModel)
                    store.getRoleList();
                    getList()
                }}
            />
             <PermissionModal
                data={permissionModalState}
                onDone={() => setPermissionModalState(ModalDataModel())}
            />
        </>
    )
}
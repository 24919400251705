import { useCallback } from 'react';
import { WithId } from '..';
import { useHttp } from '../../hooks/http';
import { PersonCreateDto, PersonGetByIdDto, PersonListDto, PersonUpdateDto } from './person';

export const usePersonService = () => {
  const http = useHttp();

  const list = useCallback(async () => {
    return await http.get<PersonListDto[]>('/person');
  }, [http]);

  const create = useCallback(
    async (req: PersonCreateDto) => {
      return await http.post<PersonCreateDto, WithId<PersonCreateDto>>('/person', req);
    },
    [http],
  );

  const update = useCallback(
    async (id: number, req: PersonUpdateDto) => {
      return await http.patch<PersonUpdateDto, WithId<PersonCreateDto>>(`/person/${id}`, req);
    },
    [http],
  );

  const remove = useCallback(
    async (id: number) => {
      return await http.delete(`/person/${id}`);
    },
    [http],
  );

  const getById = useCallback(
    async (id: number) => {
      return await http.get<PersonGetByIdDto>(`/person/${id}`);
    },
    [http],
  );

  const getByscale = useCallback(
    async (scaleId: number) => {
      return await http.get<PersonListDto[]>(`/person?scale=${scaleId}`);
    },
    [http],
  );

  return {
    list,
    create,
    getById,
    getByscale,
    update,
    remove,
  };
};

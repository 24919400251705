import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { PersonListDto, FiltertypeDto } from '../../services/person/person';
import { usePersonService } from '../../services/person/person.service';
import { PersonalManagementModal } from './personalManagementModal';

interface PersonManagementProps {}

export const PersonManagement: React.FC<PersonManagementProps> = (props) => {
  const service = usePersonService();
  const [state, setState] = useState<PersonListDto[]>([]);
  const [tableTypeFileter, setTableFilterType] = useState<FiltertypeDto[]>([]);
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const { t } = useTranslation();
  const modal = useModal();
  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState([...res]);
    const typeFilter = res
      .map((item) => {
        return {
          text: item.type.text,
          value: item.type.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableFilterType(typeFilter);
  }, [service]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<PersonListDto>[] = [
    {
      dataIndex: 'name',
      title: 'Name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      //filters: [
      //  {
      //    text: 'Özgür',
      //    value: 'murat',
      //  },
      //  {
      //    text: 'Özgür',
      //    value: 'Özgür',
      //  },
      //],
      //onFilter: (value, record) => record.name === value,
    },
    {
      dataIndex: 'lastname',
      title: 'LastName',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'email',
      title: 'Email',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'type',
      title: 'Aidiyet',
      render: (v) => v.text,
      sorter: (a, b) => a.type.text.localeCompare(b.type.text),
      sortDirections: ['descend', 'ascend'],
      filters: tableTypeFileter,
      onFilter: (value, record) => record.type.id === value,
    },
    {
      dataIndex: 'description',
      title: 'Description',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: r.id, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.name }),
                    onOk: async () => {
                      await service.remove(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageCard extra={<Button onClick={() => setModalState((p) => ({ data: null, open: true }))}>{t('New')}</Button>}>
      <ITable columns={columns} data={state} pagination={{ pageSize: 50 }} />
      <PersonalManagementModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => {
          setModalState(ModalDataModel());
          getList();
        }}
      />
    </PageCard>
  );
};

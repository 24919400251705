import { Form, Modal, Row, Tag, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { QuestionTypeEnum, WithId } from '../../services';
import { QuestionCreateDto, QuestionGetByIdDto, QuestionGetDto } from '../../services/question/question';
import { useStore } from '../../store';
import { useQuestionService } from '../../services/question/question.service';
import { EnumToDropdownModel } from '../../helpers/utils';
import { ImageUpload } from '../../components/imageUpload';

interface QuestionModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const QuestionModal: React.FC<QuestionModalProps> = props => {
    const [form] = useForm();
    const service = useQuestionService();
    const { t } = useTranslation();
    const [state, setState] = useState<QuestionGetByIdDto | null>(null);
    const store = useStore();
    const [withPicture, setWithPicture] = useState<boolean>(false);

    const onSelectQuestionType = useCallback(async () => {
        setWithPicture(p => form.getFieldValue('type') === QuestionTypeEnum.ImageQuestion)
    }, [setWithPicture, form])

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res) return;
        form.setFieldsValue({
            ...res,
            type: Number(res.type.id),
            factors: res.factors.map(e => e.factor_id)
        });
        store.getQuestionPoolList();
        setState(res);
        onSelectQuestionType()
        store.getQuestionFactors()
    }, [form, onSelectQuestionType, service, store])

    useEffect(() => {
        if (props.data.data && !state) {
            getData(props.data.data)
        }
        // eslint-disable-next-line
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
        setWithPicture(false);
        setState(null);
    }, [form, setWithPicture, setState])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: QuestionCreateDto) => {
        let res: QuestionGetDto | WithId<QuestionCreateDto> | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service, props.data.data])


    const formItems = useCreateFormItems<QuestionCreateDto>([
        {
            colProps: {
                span: withPicture ? 20 : 24,
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input,
            formItem: {
                label: 'Question',
                name: ['question', 'text'],

            },
        },
        {
            colProps: {
                span: 4
            },
            element: <ImageUpload />,
            elementType: ComponentTypes.ImageUpload,
            formItem: {
                label: 'Picture',
                name: ['question', 'image']
            },
            active: withPicture
        },
        {
            colProps: {
                span: 8
            },
            formItem: {
                name: 'pool',
                label: 'QuestionPool'
            },
            element: <StyledSelect
                onDropdownVisibleChange={() => store.getQuestionPoolList()}
                options={store.data.questionPoolList.map(e => ({
                    label: <>
                        <Tooltip title={e.name}>
                            <Tag color={e.color} children={e.color} />
                            <Typography.Text>{e.name}</Typography.Text>
                        </Tooltip>
                    </>, value: e.id
                }))}
            />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 8
            },
            formItem: {
                name: 'type',
                label: 'QuestionType'
            },
            element: <StyledSelect
                onSelect={() => onSelectQuestionType()}
                options={EnumToDropdownModel(QuestionTypeEnum)}

            />,
            elementType: ComponentTypes.Input
        },
        {
            colProps: {
                span: 8
            },
            formItem: {
                name: 'factors',
                label: 'QuestionFactor'
            },
            element: <StyledSelect
                mode='tags'
                onDropdownVisibleChange={() => store.getQuestionFactors()}
                options={store.data.questionFactorList.map(e => ({ label: e.name, value: e.id }))}

            />,
            elementType: ComponentTypes.Input
        },
        // {
        //     colProps: {
        //         span: 8
        //     },
        //     formItem: {
        //         name: 'answer_type',
        //         label: 'AnswerType'
        //     },
        //     element: <StyledSelect
        //         options={EnumToDropdownModel(QuestionAnswerTypeEnum)}

        //     />,
        //     elementType: ComponentTypes.Input
        // },
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'QuestionModalForm'
            }}
            width={"50vw"}
        >
            <Form
                form={form}
                id="QuestionModalForm"
                onFinish={(data) => onSubmit(data)}
            >
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal >
    )
}
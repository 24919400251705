import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { QuotaListResDto } from '../../services/quota/quota';
import { useQuotaService } from '../../services/quota/quota.service';
import { QuotaManagementModal } from './quotaManagementModal';

interface QuotaManagementProps {}

export const QuotaManagement: React.FC<QuotaManagementProps> = (props) => {
  const service = useQuotaService();
  const [state, setState] = useState<QuotaListResDto[]>([]);
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const modal = useModal();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
  }, [service, setState]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<QuotaListResDto>[] = [
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'count',
      title: 'Quota',
    },
    {
      dataIndex: 'id',
      width: '100px',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: r.id, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.name }),
                    onOk: async () => {
                      await service.deleteById(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
      </PageCard>
      <QuotaManagementModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => {
          setModalState(ModalDataModel());
          getList();
        }}
      />
    </>
  );
};

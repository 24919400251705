import { Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScale } from '../../../hooks/scale';
import { QuestionAppender } from './questionAppender';

interface AppendQuestionModalProps {
    onDone(): void;
}

export const AppendQuestionModal: React.FC<AppendQuestionModalProps> = props => {
    const scaleStore = useScale();
    const [form] = useForm();
    const { t } = useTranslation()
    return (
        <>
            <Modal
                open={scaleStore.appendQuestionModal.open}
                title={t(scaleStore.appendQuestionModal.data && scaleStore.appendQuestionModal.data.answerId ? 'ConditionalQuestion' : 'AddReleatedQuestion')}
                onCancel={() => scaleStore.toggleAppendQuestionModal()}
                onOk={() => form.submit()}
            >
                <QuestionAppender
                    inModal={true}
                    form={form}
                    onDone={() => {
                        scaleStore.toggleAppendQuestionModal()
                        props.onDone()
                    }}
                />
            </Modal>
        </>
    )
}
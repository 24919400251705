import { Button, Form, Row, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TurkeyMap from 'turkey-map-react';
import { useCreateFormItems } from '../helpers/form';
import { ComponentTypes } from '../models/system';
import { useStore } from '../store';
import { StyledSelect } from './form';

interface MapProps {
  onSelectCity?(name: string): void;
  onSelectDistrict?(name: string): void;
}

/**
 * TODO
 * ISPARTA NOT WORKING
 */

export const Map: React.FC<MapProps> = (props) => {
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const { t } = useTranslation();
  const store = useStore();
  const [form] = useForm();

  const trToEn = useCallback((value: string) => {
    var string = value;
    var letters: any = { İ: 'i', I: 'ı', Ş: 'ş', Ğ: 'ğ', Ü: 'ü', Ö: 'ö', Ç: 'ç' };
    string = string.replace(/(([İIŞĞÜÇÖ]))+/g, (l: string) => letters[l]);
    return string.toLowerCase();
  }, []);

  useEffect(() => {
    if (selectedCity === null) {
      form.resetFields();
    }
  }, [selectedCity, form]);

  const formItems = useCreateFormItems([
    {
      colProps: {
        span: 12,
      },
      element: (
        <StyledSelect
          options={store.data.district.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => store.getNeighbourhood(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        label: 'District',
        name: 'district',
      },
    },
    //{
    //    colProps: {
    //        span: 6
    //    },
    //    element: <StyledSelect options={store.data.neighbourhoodList.map(e => ({ label: e.name, value: e.id }))} />,
    //    elementType: ComponentTypes.Input,
    //    formItem: {
    //        label: 'Neighbourhood',
    //        name: 'neighbourhood'
    //    }
    //}
  ]);

  return (
    <>
      {!selectedCity && (
        <TurkeyMap
          hoverable={true}
          customStyle={{
            hoverColor: '#1976D2',
            idleColor: '#37474F',
          }}
          showTooltip={true}
          onClick={(v) => {
            store.getDistrict(Number(v.plateNumber));
            setSelectedCity(trToEn(v.name));
            props.onSelectCity && props.onSelectCity(trToEn(v.name));
          }}
        />
      )}
      <div
        style={{
          textAlign: 'center',
          display: selectedCity ? 'block' : 'none',
        }}>
        {selectedCity && (
          <Typography.Title style={{ textAlign: 'center' }}>{selectedCity.toUpperCase()}</Typography.Title>
        )}
        <Form form={form}>
          <Row gutter={24} justify='center' align={'middle'}>
            {formItems}
          </Row>
        </Form>
        {selectedCity && (
          <Button style={{ flex: '1 0 auto' }} onClick={() => setSelectedCity(null)}>
            {t('Back')}
          </Button>
        )}
      </div>
    </>
  );
};

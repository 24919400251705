import { Button, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import CopyToClipboard from 'react-copy-to-clipboard';

interface ProjectManagementModalProps {
  data: ModalData<any>;
  onCancel(): void;
}

interface ApiKey {
  whiteList: string;
  api: string;
}

export const ApiKeyModal: React.FC<ProjectManagementModalProps> = (props) => {
  const [form] = useForm();
  const [copied, setCopied] = useState(false);
  const [modalOpen, setModalOpen] = useState(props.data.open);

  useEffect(() => {
    setModalOpen(props.data.open);
    form.setFieldValue('api', props.data.data?.api);
  }, [form, props]);

  const formItems = useCreateFormItems<ApiKey>([
    {
      formItem: {
        name: 'api',
        label: 'Api Key',
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput readOnly={true} />,
      elementType: ComponentTypes.Input,
    },
  ]);

  return (
    <Modal
      open={modalOpen}
      title={props.data.data?.whiteList === null ? 'Api Key' : `${props.data.data?.whiteList} ip adresi için Api Key`}
      onCancel={() => setModalOpen(false)}
      footer={[
        <Button key='scaleModalCancelBtn' onClick={() => setModalOpen(false)}>
          Kapat
        </Button>,
        <CopyToClipboard key='ApiKeyCopyBtn' text={props.data.data?.api} onCopy={() => setCopied(true)}>
          <Button type='primary'>{copied ? 'Kopyalandı' : 'Kopyala'}</Button>
        </CopyToClipboard>,
      ]}>
      <Form form={form} id='ApiKeyView'>
        <Row gutter={24}>{formItems}</Row>
      </Form>
    </Modal>
  );
};

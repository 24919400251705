import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { WithId } from '../../services';
import { QuotaCreateDto, QuotaGetByIdResDto } from '../../services/quota/quota';
import { useQuotaService } from '../../services/quota/quota.service';
import { useStore } from '../../store';

interface QuotaManagementModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const QuotaManagementModal: React.FC<QuotaManagementModalProps> = props => {
    const [form] = useForm();
    const service = useQuotaService();
    const { t } = useTranslation();
    const [state, setState] = useState<QuotaGetByIdResDto | null>(null);
    const store = useStore();

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res) return;
        form.setFieldsValue(res);
        setState(res);
        if (res.city) {
            store.getDistrict(res.city)
        }

        if (res.district) {
            store.getNeighbourhood(res.district)
        }
    }, [form, service, setState, store])

    useEffect(() => {
        if (props.data.data && !state) {
            getData(props.data.data)
        }
        // eslint-disable-next-line
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
        setState(null)
    }, [form, setState])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: QuotaCreateDto) => {
        let res: WithId<QuotaCreateDto> | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service, props.data.data])

    const formItems = useCreateFormItems<QuotaGetByIdResDto>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'count',
                label: 'Count',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledInput pattern="[0-9]*" />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'city',
                label: 'City',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect options={store.data.cityList.map(e => ({ label: e.name, value: e.id }))} onSelect={(v) => store.getDistrict(Number(v))} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'district',
                label: 'District',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect
                options={store.data.district.map(e => ({ label: e.name, value: e.id }))}
                onSelect={(v) => store.getNeighbourhood(Number(v))}
            />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'neighbourhood',
                label: 'Neighbourhood',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect options={store.data.neighbourhoodList.map(e => ({ label: e.name, value: e.id }))} />,
            elementType: ComponentTypes.Input
        },
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'QuotaManagementForm'
            }}
        >
            <Form form={form} id="QuotaManagementForm" onFinish={(data) => onSubmit(data)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
import { Form, Modal, Row, Switch, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDatePicker } from '../../components/datepicker';
import { StyledInput, StyledNumberInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { EnumToDropdownModel } from '../../helpers/utils';
import { ComponentTypes, ModalData } from '../../models/system';
//import { StreetDto } from '../../services/cities/cities.service';
import { PlanCreateDto, PlanListDto, PlanPrincipal } from '../../services/plan/plan';
import { usePlanService } from '../../services/plan/plan.service';
import { useStore } from '../../store';
import dayjs from 'dayjs';
const { TextArea } = StyledInput;

interface PersonPlanModalProps {
  data: ModalData<PlanListDto>;
  onDone(): void;
  onCancel(): void;
}

export const PersonPlanModal: React.FC<PersonPlanModalProps> = (props) => {
  const [form] = useForm<PlanCreateDto>();
  const service = usePlanService();
  const { t } = useTranslation();
  const store = useStore();
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const [mutliplePlan, setMutliplePlan] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();

  const onClear = useCallback(async () => {
    form.resetFields();
  }, [form]);

  const calculateTotalDuration = useCallback(() => {
    // target_digit and target bussines calculate
    const target_unit = Number(form.getFieldValue('target_unit'));
    const duration = Number(form.getFieldValue('duration'));
    const totalDuration = Number(target_unit * duration);
    if (typeof totalDuration !== 'number') return;
    form.setFieldValue('total_duration', totalDuration);
  }, [form]);

  useEffect(() => {
    if (!props.data.data) return;
    const data = props.data.data;
    console.log(data);
    form.setFieldsValue({
      ...(props.data.data as any),
      city: data.city.id,
      district: data.district.id,
      neighbourhood: data.neighbourhood.id,
      street: data.street,
      person: data.person.id,
      scale: data.scale.id,
      principal: Number(data.principal.id),
      car: data.car.id,
      units_count: Number(data.units_count),
      show_address: data.show_address,
    });
    store.getAllCars();
    store.getAllPerson();
    store.getScaleList();
    store.getCityList();
    store.getDistrict(data.city.id);
    store.getNeighbourhood(data.district.id);
    store.getStreetList(data.neighbourhood.id);
    setShowAddress(data.show_address);
    // eslint-disable-next-line
  }, [props.data.data]);

  const formItems = useCreateFormItems<PlanCreateDto>([
    {
      formItem: {
        name: 'show_address',
        label: 'Adresli Plan Oluştur',
        //style: { textAlign: 'end' },
        valuePropName: 'checked',
      },
      colProps: {
        span: 12,
      },
      element: <Switch defaultChecked={showAddress} onChange={setShowAddress} />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'mutliple_plan',
        label: 'Çoklu Plan ?',
        //style: { textAlign: 'end' },
        valuePropName: 'checked',
      },
      colProps: {
        span: 12,
      },
      element: <Switch defaultChecked={mutliplePlan} onChange={setMutliplePlan} />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'name',
        label: 'Name',
        required: !mutliplePlan,
        style: { display: !mutliplePlan ? 'block' : 'none' },
        rules: [
          {
            required: !mutliplePlan,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },

    {
      formItem: {
        name: 'plan_list',
        label: 'Kişi Listesi',
        style: { display: mutliplePlan ? 'block' : 'none' },
      },
      colProps: {
        span: 24,
      },
      element: <TextArea />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'description',
        label: 'Description',
      },
      colProps: {
        span: 24,
      },
      element: <TextArea />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'started_at',
        label: 'StartDate',
      },
      element: <IDatePicker disabledDate={(date) => date.endOf('date') < dayjs().endOf('date')} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'finished_at',
        label: 'FinishedDate',
      },
      element: <IDatePicker disabledDate={(date) => date.endOf('date') < dayjs().endOf('date')} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      element: <StyledSelect options={EnumToDropdownModel(PlanPrincipal)} />,
      elementType: ComponentTypes.Input,
      formItem: {
        name: 'principal',
        label: 'Principal',
        required: true,
      },
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'car',
        label: 'Car',
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.carList.map((e) => ({ label: `${e.license}`, value: e.id }))}
          onDropdownVisibleChange={() => store.getAllCars()}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'person',
        label: 'Person',
        required: true,
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.personList.map((e) => ({ label: `${e.name} ${e.lastname}`, value: e.id }))}
          onDropdownVisibleChange={() => store.getAllPerson()}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      element: (
        <StyledSelect
          options={store.data.scaleList.filter((e) => e.status === true).map((e) => ({ label: e.name, value: e.id }))}
          onDropdownVisibleChange={() => store.getScaleList()}
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        name: 'scale',
        label: 'Scale',
        required: true,
      },
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'city',
        label: 'City',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.cityList.filter((e) => e.id !== 41).map((e) => ({ label: e.name, value: e.id }))}
          onDropdownVisibleChange={() => store.getCityList()}
          onSelect={(v) => store.getDistrict(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'district',
        label: 'District',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.district.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => store.getNeighbourhood(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'neighbourhood',
        label: 'Neighbourhood',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          onSelect={(v) => store.getStreetList(Number(v))}
          options={store.data.neighbourhoodList.map((e) => ({ label: e.name, value: e.id }))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'street',
        label: 'Street',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          mode='tags'
          showSearch
          optionFilterProp='label'
          options={store.data.streetList.map((e) => ({ label: e.name, value: e.id }))}
        />
      ),
      elementType: ComponentTypes.Input,
    },

    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'building_count',
        label: 'BuildingCount',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput min={1} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'units_count',
        label: 'IndependedBuildingCount',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput min={1} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'target_unit',
        label: 'TargetUnit',
        required: true,
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledNumberInput
          onChange={(event) => {
            calculateTotalDuration();
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'duration',
        label: 'Duration',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledNumberInput
          onChange={(event) => {
            calculateTotalDuration();
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'total_duration',
        label: 'TotalDuration',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput disabled />,
      elementType: ComponentTypes.Input,
    },
  ]);

  const personListLengthError = useCallback(() => {
    messageApi.open({
      type: 'error',
      content: 'Kişi Listesi 10 dan fazla olamaz.',
    });
  }, [messageApi]);

  const onDone = useCallback(async () => {
    onClear();
    props.onDone();
  }, [onClear, props]);

  const onCancel = useCallback(async () => {
    onClear();
    props.onCancel();
  }, [onClear, props]);

  const onSubmit = useCallback(
    async (data: any) => {
      let res: any = false;
      let reData = data;
      if (data.plan_list !== '') {
        const ppData = data.plan_list.split('\n').filter((e: string) => e !== '');
        if (ppData.length > 10) {
          personListLengthError();
        } else {
          ppData.map(async (e: string) => {
            if (!showAddress) {
              reData = {
                name: e,
                show_address: data.show_address,
                description: data.description,
                started_at: data.started_at,
                finished_at: data.finished_at,
                principal: data.principal,
                car: data.car,
                person: data.person,
                scale: data.scale,
                city: 41,
                district: 487,
                neighbourhood: 36583,
                street: [1253834],
                units_count: 1,
                target_unit: 1,
                duration: 1,
                total_duration: 1,
                building_count: 1,
              };
            } else {
              reData = {
                name: e,
                show_address: data.show_address,
                description: data.description,
                started_at: data.started_at,
                finished_at: data.finished_at,
                principal: data.principal,
                car: data.car,
                person: data.person,
                scale: data.scale,
                city: data.city,
                district: data.district,
                neighbourhood: data.neighbourhood,
                street: data.street,
                duration: data.duration,
                total_duration: data.total_duration,
                building_count: Number(data.building_count),
                target_unit: Number(data.target_unit),
                units_count: Number(data.units_count),
              };
            }
            res = await service.create(reData);
          });
        }
      } else {
        if (!showAddress) {
          reData = {
            name: data.name,
            show_address: data.show_address,
            description: data.description,
            started_at: data.started_at,
            finished_at: data.finished_at,
            principal: data.principal,
            car: data.car,
            person: data.person,
            scale: data.scale,
            city: 41,
            district: 487,
            neighbourhood: 36583,
            street: [1253834],
            units_count: 1,
            target_unit: 1,
            duration: 1,
            total_duration: 1,
            building_count: 1,
          };
        } else {
          reData = {
            name: data.name,
            show_address: data.show_address,
            description: data.description,
            started_at: data.started_at,
            finished_at: data.finished_at,
            principal: data.principal,
            car: data.car,
            person: data.person,
            scale: data.scale,
            city: data.city,
            district: data.district,
            neighbourhood: data.neighbourhood,
            street: data.street,
            duration: data.duration,
            total_duration: data.total_duration,
            building_count: Number(data.building_count),
            target_unit: Number(data.target_unit),
            units_count: Number(data.units_count),
          };
        }
        if (props.data.data) {
          res = await service.update(props.data.data.id, reData);
        } else {
          res = await service.create(reData);
        }
        if (!res) return;
        onDone();
      }
    },
    [personListLengthError, showAddress, service, props.data.data, onDone],
  );

  return (
    <Modal
      forceRender
      open={props.data.open}
      title={t(props.data.data ? 'UpdatePersonPlan' : 'NewPersonPlan')}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: 'submit',
        form: 'PersonPlanModalForm',
      }}>
      {contextHolder}
      <Form form={form} id='PersonPlanModalForm' onFinish={(data) => onSubmit(data)}>
        <Row gutter={24}>{formItems}</Row>
      </Form>
    </Modal>
  );
};

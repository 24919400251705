import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { CarListDto } from '../../services/car/car';
import { useCarService } from '../../services/car/car.service';

interface CarManagementModalProps {
    data: ModalData<CarListDto>;
    onDone(): void;
    onCancel(): void;
}

export const CarManagementModal: React.FC<CarManagementModalProps> = props => {
    const [form] = useForm();
    const service = useCarService();
    const { t } = useTranslation();

    useEffect(() => {
        if (!props.data.data) return;
        form.setFieldsValue(props.data.data);
        // eslint-disable-next-line
    }, [props.data])

    const onReset = useCallback(async () => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: CarListDto) => {
        let res: CarListDto | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data.id, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service, props.data.data])

    const formItems = useCreateFormItems<CarListDto>([
        {
            formItem: {
                name: 'license',
                label: 'PlateAndCarType',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        }
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'CarManagementForm'
            }}
        >
            <Form form={form} id="CarManagementForm" onFinish={(data) => onSubmit(data)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
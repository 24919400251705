import { InputProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import { useToken } from 'antd/es/theme/internal';

const DEFAULT_INITIAL_DATA = () => {
  return {
    time: new Date().getTime(),
    blocks: [
      // {
      //     "type": "header",
      //     "data": {
      //         "text": "...",
      //         "level": 1
      //     }
      // },
    ],
  };
};

interface RichEditorProps extends InputProps {}

export const RichEditor: React.FC<RichEditorProps> = (props) => {
  const [holderId] = useState(props.name || props.id);
  const ejInstance = useRef<EditorJS | null>();
  const [editorData] = React.useState(DEFAULT_INITIAL_DATA);
  const theme = useToken();

  useEffect(() => {
    if (typeof props.value === 'object') {
      if (ejInstance.current?.isReady) {
        ejInstance.current?.destroy();
      }
      initEditor();
    }
    // eslint-disable-next-line
  }, [props.value]);

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      if (ejInstance.current?.isReady) {
        ejInstance.current.clear();
        ejInstance.current?.destroy();
        ejInstance.current = null;
      }
    };
    // eslint-disable-next-line
  }, []);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: holderId,
      data: (props.value as any) || editorData,
      onReady: () => {
        ejInstance.current = editor;
        editor.toolbar.open();
      },
      onChange: async (api, event) => {
        let content = await api.saver.save();
        props.onChange && props.onChange(content as any);
      },
      autofocus: true,
      tools: {},
    });
  };

  return (
    <>
      <div
        id={holderId}
        style={{
          borderColor: theme[1].colorBorder,
          borderRadius: theme[1].borderRadius,
          borderWidth: '1px',
          borderStyle: 'solid',
        }}></div>
    </>
  );
};

//@ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {Button, Modal} from 'antd';
import {ModalData} from '../../models/system';
import {GoogleMap, MarkerF, useLoadScript, InfoWindow} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import {useReportService} from '../../services/report/report.service';
import {MapLocationsDto} from '../../services/scaleQuestion/scaleQuestion';
import {ScaleUidShowModal} from './scaleUidShowModal';
import {ModalDataModel} from '../../models/system';
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

interface MapViewModalProps {
    data: ModalData<number>;

    onDone(): void;

    onCancel(): void;
}

const mapViewModal: React.FC<MapViewModalProps> = (props) => {
    const {t} = useTranslation();
    const service = useReportService();
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyD5LAK9mb-gwGDDvRFXSHq-p6FZshfQMKA',
    });
    const center = useMemo(() => ({lat: 38.73222, lng: 35.48528}), []);
    const [state, setState] = useState<MapLocationsDto[]>([]);
    const [infoOpen, setInfoOpen] = useState(false);
    const [activeMarkerIndex, setActiveMarkerIndex] = useState<number | null>(null);
    const [modalState, setModalState] = useState<ModalData<string>>(ModalDataModel());
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    const onDone = useCallback(async () => {
        props.onDone();
    }, [props]);

    const onCancel = useCallback(async () => {
        props.onCancel();
    }, [props]);

    const containerStyle = {
        width: '100%',
        height: '500px',
    };

    const handleMarkerClick = (index) => {
        setActiveMarkerIndex(index);
        setInfoOpen(true);
    };

    const handleMarkerClose = () => {
        setActiveMarkerIndex(null);
        setInfoOpen(false);
    };

    const getLocations = useCallback(async () => {
        if (!props.data.data) return;
        const res = await service.mapLocations(props.data.data);
        if (!res) return;
        const filteredData = Object.entries(res)
            .filter(([_, value]) => {
                return value.longitude !== null && value.latitude !== null;
            })
            .map(([_, value]) => {
                return {id: _, lat: value.latitude, lng: value.longitude, created_at: value.created_at};
            });
        setState(filteredData);
    }, [props.data.data, service]);

    useEffect(() => {
        getLocations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.data]);

    if (!isLoaded) {
        return (
            <Modal
                afterClose={() => onDone()}
                open={props.data.open}
                title={t('Ölçek Konumları')}
                onCancel={() => onCancel()}
                footer={[
                    <Button key='scaleModalCancelBtn' onClick={() => onCancel()}>
                        {t('Cancel')}
                    </Button>,
                ]}
                style={{
                    minWidth: '80vw',
                    height: '500px',
                }}>
                <h1>Loading...</h1>
            </Modal>
        );
    } else {
        return (
            <Modal
                afterClose={() => onDone()}
                open={props.data.open}
                title={t('Ölçek Konumları')}
                onCancel={() => onCancel()}
                footer={[
                    <Button key='scaleModalCancelBtn' onClick={() => onCancel()}>
                        {t('Kapat')}
                    </Button>,
                ]}
                style={{
                    minWidth: '80vw',
                    height: '500px',
                }}>
                <div
                    style={{
                        minHeight: '400px',
                    }}>
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={6}>
                        {state.map((item, index) => (
                            <MarkerF
                                key={item.id}
                                title={item.id}
                                icon={{
                                    url: `http://maps.google.com/mapfiles/ms/icons/${dayjs(item.created_at).isSameOrAfter(dayjs().endOf('day'), 'day') ? 'green' : dayjs(item.created_at).isSameOrAfter(dayjs().subtract(1, 'day').endOf('day'), 'day') ? 'blue' : 'red'}-dot.png`,
                                }}
                                position={{lat: item.lat, lng: item.lng}}
                                onClick={() => handleMarkerClick(index)}>
                                {infoOpen && activeMarkerIndex === index && (
                                    <InfoWindow
                                        key={item.id}
                                        position={{lat: item.lat, lng: item.lng}}
                                        onCloseClick={() => handleMarkerClose()}>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {item.id}
                                            <Button
                                                block
                                                htmlType='button'
                                                type='primary'
                                                style={{marginTop: '10px'}}
                                                onClick={() => setModalState((p) => ({data: item.id, open: true}))}>
                                                Ölçeği Göster
                                            </Button>
                                        </div>
                                    </InfoWindow>
                                )}
                            </MarkerF>
                        ))}
                    </GoogleMap>
                </div>
                <ScaleUidShowModal
                    data={modalState}
                    onCancel={() => setModalState(ModalDataModel())}
                    onDone={() => setModalState(ModalDataModel())}
                />
            </Modal>
        );
    }
};

export default mapViewModal;

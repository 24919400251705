import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { PlanCreateDto, PlanListDto, PlanUpdateDto } from "./plan";

export const usePlanService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<PlanListDto[]>('/plan');
    }, [http])

    const create = useCallback(async (req: PlanCreateDto) => {
        return await http.post<PlanCreateDto, WithId<PlanCreateDto>>('/plan', req);
    }, [http])

    const update = useCallback(async (id: number, req: PlanUpdateDto) => {
        return await http.patch<PlanUpdateDto, boolean>(`/plan/${id}`, req);
    }, [http])

    const remove = useCallback(async (id: number) => {
        return await http.delete(`/plan/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        remove
    }
}
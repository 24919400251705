import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { CarListDto } from '../../services/car/car';
import { useCarService } from '../../services/car/car.service';
import { useStore } from '../../store';
import { CarManagementModal } from './carManagementModal';

interface CarManagementProps {}

export const CarManagement: React.FC<CarManagementProps> = (props) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalData<CarListDto>>(ModalDataModel());
  const service = useCarService();
  const [state, setState] = useState<CarListDto[]>([]);
  const store = useStore();
  const modal = useModal();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
  }, [service, setState]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<CarListDto>[] = [
    {
      dataIndex: 'license',
      title: 'PlateAndCarType',
    },
    {
      width: '100px',
      dataIndex: 'id',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: r, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.license }),
                    onOk: async () => {
                      const res = await service.remove(r.id);
                      if (!res) return;
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
      </PageCard>
      <CarManagementModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => {
          setModalState(ModalDataModel);
          store.getRoleList();
          getList();
        }}
      />
    </>
  );
};

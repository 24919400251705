import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { createContext, useCallback, useMemo } from 'react';
import { useAuth } from '../hooks/auth';
import { useConfig } from '../hooks/config';
import { useLoading } from '../hooks/loading';
import { useNotification } from '../hooks/notification';

export interface HttpContextType {
  instance: AxiosInstance;
  methods: {
    post<Req, Res>(url: string, data: Req, options?: AxiosRequestConfig): Promise<Res | false>;
    get<Res>(url: string, options?: AxiosRequestConfig): Promise<Res | false>;
    put<Req, Res>(url: string, data: Req, options?: AxiosRequestConfig): Promise<Res | false>;
    delete<Res>(url: string, options?: AxiosRequestConfig): Promise<Res | false>;
    patch<Req, Res>(url: string, req: Req, options?: AxiosRequestConfig): Promise<Res | false>;
  };
}

export const HttpContext = createContext<HttpContextType | null>(null);

export const HttpProvider: React.FC = (props) => {
  const { apiBaseUrl, httpTimeout } = useConfig();
  const { clearAuth, authData } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const notification = useNotification();

  const errorHandler = useCallback(
    async (value: AxiosResponse) => {
      switch (value.status) {
        case 401:
          clearAuth();
          break;
        default:
          break;
      }
      return value;
    },
    [clearAuth],
  );

  const onRequest = useCallback(
    async (value: AxiosRequestConfig) => {
      addLoading(value.url as string);
      return value;
    },
    [addLoading],
  );

  const onRequestRejected = useCallback(
    async (value) => {
      removeLoading(value.config.url as string);
      return await Promise.resolve({ data: false });
    },
    [removeLoading],
  );

  const onResponse = useCallback(
    async (value: AxiosResponse) => {
      removeLoading(value.config.url as string);
      errorHandler(value);
      return value;
    },
    [errorHandler, removeLoading],
  );

  const onResponseRejected = useCallback(
    async (data: AxiosError<any>) => {
      console.warn('Http Response Rejected', data);
      errorHandler(data.request as any);
      if (data.response && data.response.data && data.response.data.message) {
        notification.error({ message: data.response.data.message });
      }
      return await Promise.reject({ data: false });
    },
    [notification, errorHandler],
  );

  const instance = useMemo(() => {
    const instance = axios.create({
      baseURL: apiBaseUrl,
      timeout: 120000 || httpTimeout,
      headers: {
        common: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
        },
      },
    });

    instance.interceptors.request.use(onRequest, onRequestRejected);
    instance.interceptors.response.use(onResponse, onResponseRejected);

    return instance;
  }, [apiBaseUrl, httpTimeout, onRequest, onResponse, onRequestRejected, onResponseRejected]);

  const setHeader = useCallback(async () => {
    let header: any = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    };
    if (authData) {
      header['Authorization'] = `${authData.token.token_type} ${authData.token.access_token}`;
    }

    instance.defaults.headers.common = header;
  }, [instance, authData]);

  const methods = useMemo(() => {
    return {
      post: async <Req = any, Res = any>(
        url: string,
        data: Req,
        options?: AxiosRequestConfig,
      ): Promise<Res | false> => {
        setHeader();
        return await instance
          .post(url, data, options)
          .then((res) => res.data)
          .catch((e) => {
            removeLoading(url);
            return false;
          });
      },
      put: async <Req = any, Res = any>(url: string, data: Req, options?: AxiosRequestConfig): Promise<Res | false> => {
        setHeader();
        return await instance
          .put(url, data, options)
          .then((res) => res.data)
          .catch(() => {
            removeLoading(url);
            return false;
          });
      },
      get: async <Res = any,>(url: string, options?: AxiosRequestConfig): Promise<Res | false> => {
        setHeader();
        return await instance
          .get(url, options)
          .then((res) => res.data)
          .catch(() => {
            removeLoading(url);
            return false;
          });
      },
      delete: async <Res = any,>(url: string, options?: AxiosRequestConfig): Promise<Res | false> => {
        setHeader();
        return await instance
          .delete(url, options)
          .then((res) => res.data)
          .catch(() => {
            removeLoading(url);
            return false;
          });
      },
      patch: async <Res = any, Req = any>(
        url: string,
        req: Req,
        options?: AxiosRequestConfig,
      ): Promise<Res | false> => {
        setHeader();
        return await instance
          .patch<Res>(url, req, options)
          .then((res) => res.data)
          .catch(() => {
            removeLoading(url);
            return false;
          });
      },
    };
  }, [instance, setHeader, removeLoading]);

  return (
    <HttpContext.Provider
      value={{
        instance: instance,
        methods: methods,
      }}>
      {props.children}
    </HttpContext.Provider>
  );
};

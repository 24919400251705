import { useCallback } from "react";
import { IdNameDto, WithId } from "..";
import { useHttp } from "../../hooks/http"
import { QuestionCreateDto, QuestionGetByIdDto, QuestionGetDto, QuestionUpdateDto } from "./question";

export const useQuestionService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<QuestionGetDto[]>('/question');
    }, [http])

    const create = useCallback(async (req: QuestionCreateDto) => {
        return await http.post<QuestionCreateDto, WithId<QuestionCreateDto>>('/question', req);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<QuestionGetByIdDto>(`/question/${id}`);
    }, [http])

    const update = useCallback(async (id: number, req: QuestionUpdateDto) => {
        return await http.patch<QuestionUpdateDto, QuestionGetDto>(`/question/${id}`, req);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete<Omit<IdNameDto, 'name'>>(`/question/${id}`);
    }, [http])

    return {
        list,
        create,
        getById,
        update,
        deleteById
    }
}
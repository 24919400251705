import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import { HttpProvider } from './context/http';
import { mainRouteList } from './routes/routes';
import { getAuthRouters } from 'react-router-auth-plus';
import { AppConfigProvider } from './context/config';
import { ThemeProvider } from './context/theme';
import { NotificationProvider } from './context/notification';
import { LoadingProvider } from './context/loading';
import 'antd/dist/reset.css';
import { Suspense, useMemo, useState } from 'react';
import { ModalProvider } from './context/modal';
import { StoreProvider } from './store';
import { ScaleProvider } from './context/scale';
import { NotAuth } from './pages/NotAuth';

function App() {
  const [permissions, setPermissions] = useState<string[]>([]);

  const _routers = useMemo(() => {
    const result = getAuthRouters({
      routers: mainRouteList,
      noAuthElement: (router) => <NotAuth />,
      render: (element) => element,
      auth: permissions || [],
    });
    return result;
  }, [permissions]);

  return (
    <Suspense fallback='Loading...'>
      <AppConfigProvider>
        <ThemeProvider>
          <LoadingProvider>
            <NotificationProvider>
              <ModalProvider>
                <AuthProvider>
                  <HttpProvider>
                    <StoreProvider permissions={permissions} setPermissions={setPermissions}>
                      <ScaleProvider>
                        <RouterProvider router={createBrowserRouter(_routers)} />
                      </ScaleProvider>
                    </StoreProvider>
                  </HttpProvider>
                </AuthProvider>
              </ModalProvider>
            </NotificationProvider>
          </LoadingProvider>
        </ThemeProvider>
      </AppConfigProvider>
    </Suspense>
  );
}
export default App;

import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import { ScaleLinkGenerateResDto, ScaleLinkGenerateDto } from './scale';

export const useScaleLinkService = () => {
  const http = useHttp();

  const generate = useCallback(
    async (req: ScaleLinkGenerateDto) => {
      return await http.post<ScaleLinkGenerateDto, ScaleLinkGenerateResDto>('/web-reply', req);
    },
    [http],
  );

  return {
    generate,
  };
};

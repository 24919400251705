import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { CarCreateDto, CarListDto } from "./car";

export const useCarService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<CarListDto[]>('/car');
    }, [http])

    const create = useCallback(async (req: CarCreateDto) => {
        return await http.post<CarCreateDto, CarListDto>('/car', req);
    }, [http])

    const update = useCallback(async (id: number, req: CarCreateDto) => {
        return await http.patch<CarCreateDto, WithId<CarCreateDto>>(`/car/${id}`, req);
    }, [http])

    const remove = useCallback(async (id: number) => {
        return await http.delete<WithId<any>>(`/car/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        remove
    }
}
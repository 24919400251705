import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../../components/form';
import { useCreateFormItems } from '../../../helpers/form';
import { ComponentTypes, ModalData } from '../../../models/system';
import { IdNameDto } from '../../../services';
import { useQuestionFactorService } from '../../../services/questionFactor/questionFactor';

interface QuestionFactorModalProps {
    data: ModalData<IdNameDto>;
    onDone(): void;
    onCancel(): void;
}

export const QuestionFactorModal: React.FC<QuestionFactorModalProps> = props => {
    const [form] = useForm();
    const service = useQuestionFactorService();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.data.data) {
            form.setFieldsValue(props.data.data);
        }
    }, [form, props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: IdNameDto) => {
        let res: IdNameDto | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data.id, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service,props.data.data])

    const formItems = useCreateFormItems<IdNameDto>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        }
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'QuestionFactorModalForm'
            }}
        >
            <Form form={form} id="QuestionFactorModalForm" onFinish={(data) => onSubmit(data)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
import { useTranslation } from "react-i18next";

export function ObjectNestedAssign(target: any, source: any) {
    target = Object.create(target === undefined ? {} : target);
    Object.keys(source).forEach(sourceKey => {
        if (Object.keys(source).find(targetKey => targetKey === sourceKey) !== undefined && typeof source[sourceKey] === "object") {
            target[sourceKey] = ObjectNestedAssign(target[sourceKey], source[sourceKey]);
        } else if (!Array.isArray(source)) {
            target[sourceKey] = source[sourceKey];
        } else {
            target = source;
        }
    });
    return target;
}

export const EnumToDropdownModel = (enumObj: any): any[] => {
    const { t } = useTranslation();
    const list: any[] = [];
    Object.keys(enumObj).filter(e => !Number(e)).forEach(e => {

        list.push({
            value: enumObj[e],
            label: t(e)
        });
    });

    return list;
}

import { DeleteOutlined, EditOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { FiltertypeDto, QuestionGetDto } from '../../services/question/question';
import { useQuestionService } from '../../services/question/question.service';
import { QuestionAnswerModal } from './questionAnswerModal';
import { QuestionModal } from './questionModal';

interface QuestionManagementProps {}

export const QuestionManagement: React.FC<QuestionManagementProps> = (props) => {
  const service = useQuestionService();
  const [state, setState] = useState<QuestionGetDto[]>([]);
  const [tableFilterPool, setTableFilterPool] = useState<FiltertypeDto[]>([]);
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const [answerModal, setAnswerModal] = useState<ModalData<number>>(ModalDataModel());
  const modal = useModal();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
    const poolFilter = res
      .map((item) => {
        return {
          text: item.pool.name,
          value: item.pool.name,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableFilterPool(poolFilter);
  }, [service, setState]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: IColumnProps<QuestionGetDto>[] = [
    {
      dataIndex: 'question',
      title: 'Question',
      // width:'100px',
      sorter: (a, b) => a.question.text.localeCompare(b.question.text),
      sortDirections: ['descend', 'ascend'],
      render: (v, r) =>
        r.deleted_at !== null ? <span style={{ color: 'red' }}>{r.question.text}</span> : r.question.text,
    },
    {
      dataIndex: 'pool',
      title: 'QuestionPool',
      width: '200px',
      sorter: (a, b) => a.pool.name.localeCompare(b.pool.name),
      sortDirections: ['descend', 'ascend'],
      render: (v, r) => r.pool.name,
      filters: tableFilterPool,
      onFilter: (value, r) => r.pool.name === value,
    },
    {
      dataIndex: 'factors',
      title: 'QuestionFactor',
      width: '200px',
      sorter: (a, b) => a.factors[0].factor.name.localeCompare(b.factors[0].factor.name),
      sortDirections: ['descend', 'ascend'],
      render: (v, r) => r.factors[0].factor.name,
      filters: tableFilterPool,
      onFilter: (value, r) => r.factors[0].factor.name === value,
    },
    {
      dataIndex: 'type',
      title: 'QuestionType',
      width: '100px',
      render: (v, r) => r.type.text,
    },
    {
      dataIndex: 'id',
      title: 'Action',
      width: '100px',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: v, open: true })),
              },
              {
                key: 'answer-manage',
                label: t('AnswersManagement'),
                icon: <FormOutlined />,
                onClick: () => setAnswerModal((p) => ({ data: r.id, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.question.text }),
                    onOk: async () => {
                      await service.deleteById(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
      </PageCard>
      <QuestionModal
        data={modalState}
        onCancel={() => setModalState((p) => ModalDataModel())}
        onDone={() => {
          setModalState((p) => ModalDataModel());
          getList();
        }}
      />
      <QuestionAnswerModal
        data={answerModal}
        onCancel={() => setAnswerModal(ModalDataModel())}
        onDone={() => {
          setAnswerModal(ModalDataModel());
        }}
      />
    </>
  );
};

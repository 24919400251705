import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { UserListResDto } from '../../services/users/user';
import { useUserSerivce } from '../../services/users/users.service';
import { UserManagementModal } from './userManagementModal';
import {useStore} from "../../store";

interface UserManagementProps {
}


export const UserManagement: React.FC<UserManagementProps> = props => {
    const service = useUserSerivce();
    const [state, setState] = useState<UserListResDto[]>([]);
    const { t } = useTranslation();
    const modal = useModal();
    const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
    const store = useStore();


    const getList = useCallback(async () => {
        const res = await service.list();
        if (!res) return;
        setState(res)
    }, [setState, service])

    useEffect(() => {
        getList();
        store.getProjectList()
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<UserListResDto>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'email',
            title: 'Email'
        },
        {
            dataIndex: 'project',
            title: 'Project',
            render: (v) => store.data.projectList.find(p => p.id === v)?.name
        },
        {
            dataIndex: 'id',
            render: (v, r) => <Dropdown.Button menu={{
                items: [
                    {
                        key: 'detail',
                        label: t('Edit').toString(),
                        icon: <EditOutlined />,
                        onClick: () => setModalState(p => ({ data: v, open: true }))
                    },
                    {
                        key: 'delete',
                        label: t('Delete'),
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                content: t('DeleteConfirm', { value: r.name }),
                                onOk: async () => {
                                    await service.deleteById(r.id);
                                    getList();
                                }
                            })
                        }
                    }
                ]
            }}>
                {t('Action')}
            </Dropdown.Button>
        }
    ]

    return (
        <>
            <PageCard
                extra={<Button type='primary' onClick={() => setModalState(p => ({ data: null, open: true }))}>{t('New')}</Button>}
            >
                <ITable
                    columns={columns}
                    data={state}
                />
            </PageCard>

            <UserManagementModal
                data={modalState}
                onCancel={() => setModalState(p => ModalDataModel())}
                onDone={() => {
                    setModalState(ModalDataModel());
                    getList()
                }}
            />
        </>
    )
}
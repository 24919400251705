import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../../components/form';
import { useCreateFormItems } from '../../../helpers/form';
import { ComponentTypes, ModalData } from '../../../models/system';
import { QuotaTypesDto } from '../../../services/quotaTypes/quotaTypes';
import { useQuotaTypesService } from '../../../services/quotaTypes/quotaTypes.service';

interface QuotaTypeModalProps {
    data: ModalData<QuotaTypesDto>
    onDone(): void;
    onCancel(): void;
}

export const QuotaTypeModal: React.FC<QuotaTypeModalProps> = props => {
    const quotaTypeService = useQuotaTypesService();
    const { t } = useTranslation();
    const [form] = useForm();

    useEffect(() => {
        if (!props.data.data) return;
        form.setFieldsValue(props.data.data)
    }, [props.data, form])

    const onReset = useCallback(async () => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel()
    }, [props, onReset])

    const onSubmit = useCallback(async (data: QuotaTypesDto) => {
        let res: QuotaTypesDto | false = false;

        if (!props.data.data) {
            res = await quotaTypeService.create(data);
        } else {
            res = await quotaTypeService.update(props.data.data.id, data);
        }
        if (!res) return;
        onDone()
    }, [quotaTypeService, onDone, props])

    const formItems = useCreateFormItems<QuotaTypesDto>([
        {
            colProps: {
                span: 24,
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input,
            formItem: {
                label: 'Name',
                name: 'name'
            }
        },
        {
            colProps: {
                span: 24,
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input,
            formItem: {
                label: 'Question',
                name: 'question'
            }
        }
    ])

    return (
        <>
            <Modal
                open={props.data.open}
                title={t(props.data.data ? 'Update' : 'New')}
                onCancel={() => onCancel()}
                okButtonProps={{
                    form: 'QuotaTypeModalForm',
                    htmlType: 'submit'
                }}
            >
                <Form id="QuotaTypeModalForm" form={form} onFinish={(data) => onSubmit(data)}>
                    <Row gutter={24}>
                        {formItems}
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
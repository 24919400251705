import { KeyOutlined, MailFilled } from '@ant-design/icons';
import { Button, Card, Col, Form, Layout, Row, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../components/form';
import { FormItemGeneratorData, useCreateFormItems } from '../../helpers/form';
import { useAuth } from '../../hooks/auth';
import { ComponentTypes } from '../../models/system';
import { useAuthService } from '../../services/auth/auth.service';
import { AuthLoginReqDto } from '../../services/auth/auth';
import dayjs from 'dayjs';
//import { useHttp } from '../../hooks/http';

interface LoginProps {}

export const Login: React.FC<LoginProps> = (props) => {
  const { setAuth } = useAuth();
  const [form] = useForm();
  const { t } = useTranslation();
  const service = useAuthService();

  const onLogin = useCallback(
    async (data: AuthLoginReqDto) => {
      const res = await service.login(data);
      if (!res) return;
      setAuth({ token: res.token, expires_in: dayjs().unix() });
      window.location.pathname = '/';
    },
    [service, setAuth],
  );

  const formItems: FormItemGeneratorData<AuthLoginReqDto>[] = [
    {
      colProps: {
        span: 24,
      },
      element: <StyledInput prefix={<MailFilled />} />,
      elementType: ComponentTypes.InputEmail,
      formItem: {
        label: t('Email'),
        name: 'email',
      },
    },
    {
      colProps: {
        span: 24,
      },
      element: <StyledInput.Password prefix={<KeyOutlined />} />,
      elementType: ComponentTypes.Input,
      formItem: {
        label: t('Password'),
        name: 'password',
      },
    },
    {
      colProps: {
        span: 24,
      },
      element: <Switch defaultChecked={false} />,
      elementType: ComponentTypes.Switch,
      formItem: {
        name: 'remember',
        label: 'Remember',
      },
    },
  ];

  return (
    <Layout style={{ height: '100vh', margin: 0 }}>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          height: '100vh',
          top: 0,
          right: 0,
          zIndex: 1,
        }}>
        <video
          style={{ width: '100%', height: '100vh', padding: 0, margin: 0, objectFit: 'cover' }}
          autoPlay
          preload='metadata'
          muted
          loop
          playsInline
          id='myVideo'>
          <source src='/loginvideo.mp4' type='video/mp4' />
        </video>
      </div>
      <Content style={{ zIndex: 1000, position: 'relative' }}>
        <Row justify={'center'} align='middle' style={{ height: '100%' }}>
          <Col md={6} xs={20}>
            <Card>
              <Form form={form} onFinish={(data) => onLogin(data)}>
                {useCreateFormItems(formItems)}
                <Form.Item>
                  <Button type='primary' htmlType='submit' block>
                    {t('LoginIn')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

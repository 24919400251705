import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { PageCard } from '../../../components/pageCard';
import { IColumnProps } from '../../../components/table';
import { ITable } from '../../../components/table/table';
import { useModal } from '../../../hooks/modal';
import { ModalData, ModalDataModel } from '../../../models/system';
import { QuotaTypesDto } from '../../../services/quotaTypes/quotaTypes';
import { useQuotaTypesService } from '../../../services/quotaTypes/quotaTypes.service';
import { QuotaTypeModal } from './quotaTypeModal';

interface QuotaTypeManagementProps {
}

export const QuotaTypeManagement: React.FC<QuotaTypeManagementProps> = props => {
    const service = useQuotaTypesService();
    const [state, setState] = useState<QuotaTypesDto[]>([]);
    const [modalState, setModalState] = useState<ModalData<QuotaTypesDto>>(ModalDataModel())
    const modal = useModal()

    const getList = useCallback(async () => {
        const res = await service.list();
        if (!res) return;
        setState(res)
    }, [service, setState])

    useEffect(() => {
        getList();
        // eslint-disable-next-line
    }, [])

    const columns: IColumnProps<QuotaTypesDto>[] = [
        {
            dataIndex: 'name',
            title: 'Name'
        },
        {
            dataIndex: 'question',
            title: "Question"
        },
        {
            dataIndex: 'id',
            width: '100px',
            title: 'Action',
            render: (v, r) => <Dropdown.Button menu={{
                items: [
                    {
                        key: 'detail',
                        label: t('Edit').toString(),
                        icon: <EditOutlined />,
                        onClick: () => setModalState(p => ({ data: r, open: true }))
                    },
                    {
                        key: 'delete',
                        label: t('Delete'),
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: t('Delete'),
                                content: t('DeleteConfirm', { value: r.name }),
                                onOk: async () => {
                                    await service.deleteById(r.id);
                                    getList();
                                }
                            })
                        }
                    }
                ]
            }}>
                {t('Action')}
            </Dropdown.Button>
        }
    ]

    return (
        <>
            <PageCard
                extra={<Button type="primary" onClick={() => setModalState(p => ({ data: null, open: true }))}>{t('New')}</Button>}
            >
                <ITable
                    data={state}
                    columns={columns}
                />
                <QuotaTypeModal
                    data={modalState}
                    onCancel={() => setModalState(ModalDataModel())}
                    onDone={() => {
                        setModalState(ModalDataModel())
                        getList()
                    }}
                />
            </PageCard>
        </>
    )
}
import { Button, Col, Dropdown, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { IColumnProps } from '../../components/table';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { AnswerCreateDto } from '../../services/answer/answer';
import { QuestionAnswersDto } from '../../services/question/question';
import { ITable } from '../../components/table/table';
import { useQuestionService } from '../../services/question/question.service';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useModal } from '../../hooks/modal';
import { useAnswerService } from '../../services/answer/answer.service';
import { ImageUpload } from '../../components/imageUpload';
import { EnumToDropdownModel } from '../../helpers/utils';
import { AnswerTypeEnum, WithId } from '../../services';

interface QuestionAnswerModalProps {
  data: ModalData<number>;
  onDone(): void;
  onCancel(): void;
}

export const QuestionAnswerModal: React.FC<QuestionAnswerModalProps> = (props) => {
  const [form] = useForm();
  const service = useQuestionService();
  const { t } = useTranslation();
  const [state, setState] = useState<QuestionAnswersDto[]>([]);
  const [activeAnswer, setActiveAnswer] = useState<number | null>(null);
  const [withPicture, setWithPicture] = useState<boolean>(false);
  const modal = useModal();
  const answerService = useAnswerService();

  const getData = useCallback(
    async (id: number) => {
      const res = await service.getById(id);
      if (!res) return;
      setState(res.answers);
    },
    [setState, service],
  );

  useEffect(() => {
    if (props.data.data) {
      getData(props.data.data);
    }
    // eslint-disable-next-line
  }, [props.data.data]);

  const onReset = useCallback(async () => {
    form.resetFields();
    setState([]);
  }, [form]);

  const onDone = useCallback(async () => {
    onReset();
    props.onDone();
  }, [onReset, props]);

  const onCancel = useCallback(async () => {
    onReset();
    props.onCancel();
  }, [onReset, props]);

  const onSubmit = useCallback(
    async (data: AnswerCreateDto) => {
      if (!props.data.data) return;
      let res: WithId<AnswerCreateDto> | false = false;
      if (activeAnswer) {
        res = await answerService.update(activeAnswer, {
          ...data,
          question: props.data.data,
        });
      } else {
        res = await answerService.create({
          ...data,
          question: props.data.data,
        });
      }
      if (!res) return;
      getData(props.data.data);
      form.resetFields();
      setActiveAnswer(null);
      setWithPicture(false);
    },
    [getData, answerService, form, props.data.data, activeAnswer, setActiveAnswer, setWithPicture],
  );

  const onEditAnswer = useCallback(
    async (row: QuestionAnswersDto) => {
      setActiveAnswer(row.id);
      form.setFieldsValue({
        ...row,
        type: row.type.id,
      });
    },
    [setActiveAnswer, form],
  );

  const formItems = useCreateFormItems<AnswerCreateDto>([
    {
      formItem: {
        name: ['answer', 'text'],
        label: 'Answer',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 6,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'point',
        label: 'Point',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 4,
      },
      element: <StyledInput pattern='[0-9]*' />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'factor',
        label: 'Katsayı',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 4,
      },
      element: <StyledInput pattern='[0-9]*' />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'type',
        label: 'AnswerType',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 6,
      },
      element: (
        <StyledSelect
          onSelect={(v) => setWithPicture((p) => v === AnswerTypeEnum.AnswerTypeImage)}
          options={EnumToDropdownModel(AnswerTypeEnum)}
        />
      ),
      elementType: ComponentTypes.Input,
    },
  ]);

  const columns: IColumnProps<QuestionAnswersDto>[] = [
    {
      dataIndex: 'answer',
      title: 'Answer',
      render: (v, r) => {
        console.log(typeof r.deleted_at);
        return r.deleted_at !== null ? <span style={{ color: 'red' }}>{r.answer.text}</span> : r.answer.text;
      },
    },
    {
      title: 'AnswerPoint',
      dataIndex: 'point',
      render: (v, r) => (r.deleted_at !== null ? <span style={{ color: 'red' }}>{r.point}</span> : r.point),
    },
    {
      title: 'AnswerFactor',
      dataIndex: 'factor',
      render: (v, r) => (r.deleted_at !== null ? <span style={{ color: 'red' }}>{r.factor}</span> : r.factor),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '100px',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => onEditAnswer(r),
              },

              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.answer.text }),
                    onOk: async () => {
                      await answerService.deleteById(r.id);
                      if (!props.data.data) return;
                      getData(props.data.data);
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <Modal
      open={props.data.open}
      style={{ minWidth: '60vw' }}
      title={t(props.data.data ? 'Update' : 'New')}
      onCancel={() => onCancel()}
      onOk={() => onDone()}>
      <Form form={form} id='QuestionAnswerForm' onFinish={(data) => onSubmit(data)} style={{ marginBottom: '20px' }}>
        <Row gutter={24}>
          {withPicture && (
            <Col span={4}>
              <Form.Item
                name={['answer', 'image']}
                labelCol={{ span: 24 }}
                label={t('Picture')}
                required
                rules={[{ required: true }]}>
                <ImageUpload />
              </Form.Item>
            </Col>
          )}
          {formItems}
        </Row>
        <Row justify={'end'}>
          <Col span={3}>
            {activeAnswer && (
              <Button
                type='primary'
                onClick={() => {
                  setActiveAnswer(null);
                  form.resetFields();
                }}
                htmlType='reset'>
                {t('Cancel')}
              </Button>
            )}
          </Col>
          <Col span={3}>
            <Button type='primary' htmlType='submit'>
              {t(activeAnswer ? 'Update' : 'Save')}
            </Button>
          </Col>
        </Row>
      </Form>

      <ITable columns={columns} data={state} />
    </Modal>
  );
};

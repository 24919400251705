import { ApiOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { FiltertypeDto, ProjectGetListDto } from '../../services/project/project';
import { useProjectService } from '../../services/project/project.service';
import { useStore } from '../../store';
import { ProjectManagementModal } from './projectManagementModal';
import { ApiKeyModal } from './apiKeyModal';

interface ProjectManagementProps {}

export const ProjectManagement: React.FC<ProjectManagementProps> = (props) => {
  const service = useProjectService();
  const [state, setState] = useState<ProjectGetListDto[]>([]);
  const [filterCity, setFilterCity] = useState<FiltertypeDto[]>([]);
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const [apiModalState, setApiModalState] = useState<ModalData<any>>(ModalDataModel());

  const modal = useModal();
  const store = useStore();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
    store.getCityList();
    res.forEach((r) => {
      const city = store.data.cityList.find((e) => e.id === r.city);
      if (city) {
        setFilterCity((p) => {
          if (p.find((e) => e.value === city.name)) return p;
          return [...p, { text: city.name, value: city.name }];
        });
      }
    });
  }, [service, store]);

  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<ProjectGetListDto>[] = [
    {
      dataIndex: 'logo',
      title: 'Logo',
      render: (v) => <Avatar src={v} size='large' />,
    },
    {
      dataIndex: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'city',
      title: 'City',
      render: (v) => store.data.cityList.find((e) => e.id === v)?.name,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        const aName = store.data.cityList.find((e) => e.id === a.city)?.name;
        const bName = store.data.cityList.find((e) => e.id === b.city)?.name;
        return aName?.localeCompare(bName || '') || 0;
      },
      filters: filterCity,
      onFilter: (value, r) => r.name === value,
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => setModalState((p) => ({ data: r.id, open: true })),
              },
              {
                key: 'apiKey',
                label: 'Api Key',
                icon: <ApiOutlined />,
                onClick: () => setApiModalState((p) => ({ data: { whiteList: r?.whiteList, api: r.api }, open: true })),
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', { value: r.name }),
                    onOk: async () => {
                      await service.deleteById(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
      </PageCard>
      <ProjectManagementModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => {
          setModalState(ModalDataModel());
          getList();
        }}
      />
      <ApiKeyModal data={apiModalState} onCancel={() => setApiModalState(ModalDataModel())} />
    </>
  );
};

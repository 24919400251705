import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { ImageUpload } from '../../components/imageUpload';
import { useCreateFormItems } from '../../helpers/form';
import { ObjectNestedAssign } from '../../helpers/utils';
import { ComponentTypes, ModalData, ModalDataModel } from '../../models/system';
import { WithId } from '../../services';
import { ProjectCreateDto } from '../../services/project/project';
import { useProjectService } from '../../services/project/project.service';
import { useStore } from '../../store';
import { ApiKeyModal } from './apiKeyModal';
interface ProjectManagementModalProps {
  data: ModalData<number>;
  onDone(): void;
  onCancel(): void;
}

export const ProjectManagementModal: React.FC<ProjectManagementModalProps> = (props) => {
  const [form] = useForm();
  const service = useProjectService();
  const { t } = useTranslation();
  const store = useStore();
  const [state, setState] = useState<WithId<ProjectCreateDto> | null>(null);
  const [formData, setFormData] = useState<Partial<ProjectCreateDto>>({});
  const [apiModalState, setApiModalState] = useState<ModalData<any>>(ModalDataModel());

  const getData = useCallback(
    async (id: number) => {
      const res = await service.getById(id);
      if (!res) return;
      form.setFieldsValue(res);
      setState(res);
      if (res.city) {
        store.getDistrict(res.city);
      }
    },
    [form, setState, service, store],
  );

  useEffect(() => {
    if (props.data.data && !state) {
      getData(props.data.data);
      store.getCustomers();
    }
    // eslint-disable-next-line
  }, [props.data.data]);

  const onReset = useCallback(async () => {
    form.resetFields();
    setState(null);
  }, [form, setState]);

  const onDone = useCallback(async () => {
    onReset();
    props.onDone();
  }, [onReset, props]);

  const onCancel = useCallback(async () => {
    onReset();
    props.onCancel();
  }, [onReset, props]);

  const onSubmit = useCallback(
    async (data: ProjectCreateDto) => {
      let res: WithId<ProjectCreateDto> | any = false;
      if (props.data.data) {
        res = await service.update(props.data.data, formData);
      } else {
        res = await service.create(data);
        setApiModalState((p) => ({ data: { whiteList: res?.whiteList, api: res.api }, open: true }));
      }
      if (!res) return;
      onDone();
    },
    [onDone, service, props.data.data, formData],
  );

  const formItems = useCreateFormItems<ProjectCreateDto>([
    {
      formItem: {
        name: 'name',
        label: 'Name',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'description',
        label: 'Description',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'customer',
        label: 'Customer',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 12,
      },
      element: (
        <StyledSelect
          options={
            store.data.customerList &&
            store.data.customerList.map((e) => ({ label: e.information.customer.name, value: e.id }))
          }
          onDropdownVisibleChange={() => store.getCustomers()}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'logo',
        label: 'Logo',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 12,
      },
      element: <ImageUpload />,
      elementType: ComponentTypes.ImageUpload,
    },
    {
      formItem: {
        name: 'city',
        label: 'City',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 12,
      },
      element: (
        <StyledSelect
          onDropdownVisibleChange={() => store.getCityList()}
          options={store.data.cityList
            .filter((e) => e.name !== 'ADRES YOK')
            .map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => {
            form.resetFields(['district']);
            store.getDistrict(Number(v));
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'district',
        label: 'District',
        required: false,
        rules: [
          {
            required: false,
          },
        ],
      },
      colProps: {
        span: 12,
      },
      element: <StyledSelect options={store.data.district.map((e) => ({ label: e.name, value: e.id }))} />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'whiteList',
        label: 'Api erişimi İçin ip adresiniz',
        required: false,
        rules: [
          {
            required: false,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
  ]);

  return (
    <Modal
      open={props.data.open}
      title={t(props.data.data ? 'Update' : 'New')}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: 'submit',
        form: 'ProjectManagementForm',
      }}>
      <Form
        form={form}
        id='ProjectManagementForm'
        onValuesChange={(e) => setFormData((p) => ObjectNestedAssign(Object.create(p), e))}
        onFinish={(data) => onSubmit(data)}>
        <Row gutter={24}>{formItems}</Row>
      </Form>
      <ApiKeyModal data={apiModalState} onCancel={() => setApiModalState(ModalDataModel())} />
    </Modal>
  );
};

import { Form, Modal, Row, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDatePicker } from '../../components/datepicker';
import { StyledNumberInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { EnumToDropdownModel } from '../../helpers/utils';
import { ComponentTypes, ModalData } from '../../models/system';
import { PlanCreateDto, PlanListDto, PlanPrincipal } from '../../services/plan/plan';
import { usePlanService } from '../../services/plan/plan.service';
import { useCitiesService } from '../../services/cities/cities.service';
import { useStore } from '../../store';
import dayjs from 'dayjs';
//const { TextArea } = StyledInput;

interface AddressPlanModalProps {
  data: ModalData<PlanListDto>;
  onDone(): void;
  onCancel(): void;
}

export const AddressPlanModal: React.FC<AddressPlanModalProps> = (props) => {
  const [form] = useForm<PlanCreateDto>();
  const service = usePlanService();
  const { t } = useTranslation();
  const store = useStore();
  const [showAddress, setShowAddress] = useState<boolean>(true);
  const cityService = useCitiesService();
  const [buildingCount, setBuildingCount] = useState<number>(0);
  const [unitCount, setUnitCount] = useState<number>(0);

  const calculateTotalDuration = useCallback(() => {
    // target_digit and target bussines calculate
    const target_unit = Number(form.getFieldValue('target_unit'));
    const duration = Number(form.getFieldValue('duration'));
    const totalDuration = Number(target_unit * duration);
    if (typeof totalDuration !== 'number') return;
    form.setFieldValue('total_duration', totalDuration);
  }, [form]);

  useEffect(() => {
    if (!props.data.data) return;
    const data = props.data.data;
    console.log(data);
    form.setFieldsValue({
      ...(props.data.data as any),
      city: data.city.id,
      district: data.district.id,
      neighbourhood: data.neighbourhood.id,
      street: data.street,
      person: data.person.id,
      scale: data.scale.id,
      principal: Number(data.principal.id),
      car: data.car.id,
      units_count: Number(data.units_count),
      show_address: data.show_address,
    });
    store.getAllCars();
    store.getAllPerson();
    store.getScaleList();
    store.getCityList();
    store.getDistrict(data.city.id);
    store.getNeighbourhood(data.district.id);
    store.getStreetList(data.neighbourhood.id);
    setShowAddress(data.show_address);
    // eslint-disable-next-line
  }, [props.data.data]);

  const onStreetSelect = useCallback(
    async (v) => {
      const res = await cityService.streetBuildingsCount(Number(v));
      if (!res) return;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let uc = 0;
      res[0].buildings?.forEach((element) => {
        uc += element.independent.length;
      });
      setBuildingCount(buildingCount + res[0].buildings?.length);
      setUnitCount(unitCount + uc);
    },
    [buildingCount, cityService, unitCount],
  );

  const onStreetDeselect = useCallback(
    async (v) => {
      const res = await cityService.streetBuildingsCount(Number(v));
      if (!res) return;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let uc = 0;
      res[0].buildings?.forEach((element) => {
        uc += element.independent.length;
      });
      setBuildingCount(buildingCount - res[0].buildings?.length);
      setUnitCount(unitCount - uc);
    },
    [buildingCount, cityService, unitCount],
  );

  useEffect(() => {
    form.setFieldsValue({
      building_count: buildingCount < 1 ? 1 : buildingCount,
      units_count: unitCount < 1 ? 1 : unitCount,
    });
  }, [buildingCount, form, unitCount]);

  const formItems = useCreateFormItems<PlanCreateDto>([
    {
      formItem: {
        name: 'show_address',
        label: 'Adresli Plan Oluştur',
        style: { textAlign: 'start' },
        valuePropName: 'checked',
      },
      colProps: {
        span: 24,
      },
      element: <Switch defaultChecked={showAddress} onChange={setShowAddress} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'started_at',
        label: 'StartDate',
      },
      element: <IDatePicker disabledDate={(date) => date.endOf('date') < dayjs().endOf('date')} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'finished_at',
        label: 'FinishedDate',
      },
      element: <IDatePicker disabledDate={(date) => date.endOf('date') < dayjs().endOf('date')} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      element: <StyledSelect options={EnumToDropdownModel(PlanPrincipal)} />,
      elementType: ComponentTypes.Input,
      formItem: {
        name: 'principal',
        label: 'Principal',
        required: true,
      },
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'car',
        label: 'Car',
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.carList.map((e) => ({ label: `${e.license}`, value: e.id }))}
          onDropdownVisibleChange={() => store.getAllCars()}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'person',
        label: 'Person',
        required: true,
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.personList.map((e) => ({ label: `${e.name} ${e.lastname}`, value: e.id }))}
          onDropdownVisibleChange={() => store.getAllPerson()}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      element: (
        <StyledSelect
          options={store.data.scaleList.filter((e) => e.status === true).map((e) => ({ label: e.name, value: e.id }))}
          onDropdownVisibleChange={() => store.getScaleList()}
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        name: 'scale',
        label: 'Scale',
        required: true,
      },
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'city',
        label: 'City',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.cityList.filter((e) => e.id !== 41).map((e) => ({ label: e.name, value: e.id }))}
          onDropdownVisibleChange={() => store.getCityList()}
          onSelect={(v) => store.getDistrict(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'district',
        label: 'District',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.district.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => store.getNeighbourhood(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'neighbourhood',
        label: 'Neighbourhood',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          onSelect={(v) => store.getStreetList(Number(v))}
          options={store.data.neighbourhoodList.map((e) => ({ label: e.name, value: e.id }))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'street',
        label: 'Street',
        style: { display: showAddress ? 'block' : 'none' },
        initialValue: [],
      },
      element: (
        <StyledSelect
          mode='tags'
          showSearch
          optionFilterProp='label'
          options={store.data.streetList.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => onStreetSelect(Number(v))}
          onDeselect={(v) => onStreetDeselect(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },

    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'building_count',
        label: 'BuildingCount',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput min={0} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'units_count',
        label: 'IndependedBuildingCount',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput min={0} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'target_unit',
        label: 'TargetUnit',
        required: true,
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledNumberInput
          onChange={(event) => {
            calculateTotalDuration();
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'duration',
        label: 'Duration',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: (
        <StyledNumberInput
          onChange={(event) => {
            calculateTotalDuration();
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'total_duration',
        label: 'TotalDuration',
        style: { display: showAddress ? 'block' : 'none' },
      },
      element: <StyledNumberInput disabled />,
      elementType: ComponentTypes.Input,
    },
  ]);

  const onClear = useCallback(async () => {
    form.resetFields();
  }, [form]);

  const onDone = useCallback(async () => {
    onClear();
    props.onDone();
  }, [onClear, props]);

  const onCancel = useCallback(async () => {
    onClear();
    props.onCancel();
  }, [onClear, props]);

  const onSubmit = useCallback(
    async (data: any) => {
      let res: any = false;
      let reData = data;
      if (!showAddress) {
        reData = {
          ...data,
          city: 41,
          district: 487,
          neighbourhood: 36583,
          street: [1253834],
          units_count: 1,
          target_unit: 1,
          duration: 1,
          total_duration: 1,
          building_count: 1,
          show_address: false,
        };
      } else {
        reData = {
          ...data,
          building_count: Number(data.building_count),
          target_unit: Number(data.target_unit),
          units_count: Number(data.units_count),
          show_address: true,
        };
      }
      if (props.data.data) {
        res = await service.update(props.data.data.id, reData);
      } else {
        res = await service.create(reData);
      }
      if (!res) return;
      onDone();
    },
    [props.data.data, onDone, service, showAddress],
  );

  return (
    <Modal
      forceRender
      open={props.data.open}
      title={t(props.data.data ? 'UpdateAddressPlan' : 'NewAddressPlan')}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: 'submit',
        form: 'AddressPlanModalForm',
      }}>
      <Form form={form} id='AddressPlanModalForm' onFinish={(data) => onSubmit(data)}>
        <Row gutter={24}>{formItems}</Row>
      </Form>
    </Modal>
  );
};

import { DatePicker, DatePickerProps } from 'antd';
import dayjs from 'dayjs';
import React, { createElement } from 'react';

type IPickerProps = DatePickerProps & {};

export const IDatePicker: React.FC<IPickerProps> = (props) => {
  return (
    <>
      {createElement<IPickerProps>(DatePicker, {
        ...props,
        format: props.format || 'DD.MM.YYYY',
        defaultValue: props.defaultValue
          ? typeof props.defaultValue === 'string'
            ? dayjs(props.defaultValue).locale('tr')
            : (props.defaultValue as any)
          : undefined,
        value: props.value ? (dayjs(props.value).locale('tr') as any) : undefined,
        style: {
          ...props.style,
          width: '100%',
        },
      })}
    </>
  );
};

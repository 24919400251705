import { Table, TableProps } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IColumnProps } from '.';

interface ITableProps extends Omit<TableProps<any>, 'columns'> {
    data?: any;
    result?: any[];
    onPageChange?(page: number, size: number): void;
    columns: IColumnProps<any>[];
}

export const ITable: React.FC<ITableProps> = props => {

    const { t } = useTranslation()

    const data = useMemo(() => {
        return props.data ? props.data : props.dataSource || [];
    }, [props.data, props.dataSource])

    const columns = useMemo(() => {
        return props.columns.map(column => {
            if (column.title) {
                column.title = t(column.title).toString()
            }

            return column;

        })
    }, [props.columns, t])

    return (
        <>
            <Table
                {...props}
                columns={columns}
                dataSource={data}
                rowKey="id"
                pagination={props.pagination ? props.pagination : props.data && props.pagination !== false ? {
                    defaultCurrent: props.data?.Page,
                    total: props.data.TotalSize,
                    onChange: (page, size) => props.onPageChange && props.onPageChange(page, size)
                } : false}
            />
        </>
    )
}
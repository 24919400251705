import { Card, CardProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { UseMatchesDto } from '../models/system';

interface PageCardProps extends CardProps {
}

export const PageCard: React.FC<PageCardProps> = props => {
    const activeRoutes = useMatches().filter(m => m.id !== 'layout') as UseMatchesDto[];
    const { t } = useTranslation();
    return (
        <Card
            {...props}
            style={{
                ...props.style,
                width:'100%'
            }}
            title={t(`Navigation.${activeRoutes[activeRoutes.length - 1].handle.title || 'NoKey'}`)}
        >
            {props.children}
        </Card>
    )
}
import {Button, Card, Col, Form, Row} from 'antd';
import React, {useCallback, useEffect, useState} from 'react';
import {StyledSelect} from '../../components/form';
import {useStore} from '../../store';
import {ThemeProvider, useTheme} from '@nivo/core';
import {useCitiesService} from '../../services/cities/cities.service';
import {useTranslation} from 'react-i18next';
import {ScaleUidShowModal} from './scaleUidShowModal';
import {ComponentTypes, ModalData, ModalDataModel} from '../../models/system';
import {ReportReqDto} from '../../services/report/report';
import {useCreateFormItems} from '../../helpers/form';
import {useForm} from 'antd/es/form/Form';
import {IDatePicker} from '../../components/datepicker';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {RangePickerProps} from 'antd/es/date-picker';
import {IdNameDto} from '../../services';
import {EyeOutlined} from '@ant-design/icons';
import {IColumnProps} from '../../components/table';
import {ITable} from '../../components/table/table';
import {useScaleService} from '../../services/scale/scale.service';
import {ScaleUidListDto} from '../../services/scale/scale';
import {PersonListDto} from '../../services/person/person';
import {usePersonService} from '../../services/person/person.service';

interface ReportsProps {
}

export const ScaleUidManagement: React.FC<ReportsProps> = (props) => {
    const store = useStore();
    const theme = useTheme();
    const service = useScaleService();
    const citiesService = useCitiesService();
    const [state, setState] = useState<ScaleUidListDto[]>([]);
    const [selectedScale, setSelectedScale] = useState(0);
    const [selectedFilterStartDate, setSelectedFilterStartDate] = useState<any>(null);
    const [disabledStartedDate, setDisabledStartedDate] = useState<any>(false);
    const [disabledFinishedDate, setDisabledFinishedDate] = useState<any>(false);
    const [scaleCities, setScaleCities] = useState<IdNameDto[]>([]);
    const [scalePersons, setScalePersons] = useState<PersonListDto[]>([]);
    const [scaleDistricts, setScaleDistricts] = useState<IdNameDto[]>([]);
    const [scaleNeighbourhoods, setScaleNeighbourhoods] = useState<IdNameDto[]>([]);
    const [scaleStreets, setScaleStreets] = useState<IdNameDto[]>([]);
    const [scaleBuildings, setScaleBuildings] = useState<IdNameDto[]>([]);
    const {t} = useTranslation();
    const [modalState, setModalState] = useState<ModalData<string>>(ModalDataModel());
    const [form] = useForm();
    const personService = usePersonService();
    dayjs.extend(isBetween);
    // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
    const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < disabledStartedDate;
    };

    // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
    const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && (current > disabledFinishedDate || current < selectedFilterStartDate);
    };

    useEffect(() => {
        if (store.data.scaleList.length === 0) {
            store.getScaleList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = useCallback(
        async (scaleId: number, _filter: ReportReqDto = {}) => {
            if (!scaleId) return;
            if (_filter.started_at) {
                _filter.started_at = dayjs(_filter.started_at).format('YYYY-MM-DD');
            }
            if (_filter.finished_at) {
                _filter.finished_at = dayjs(_filter.finished_at).format('YYYY-MM-DD');
            }
            const res = await service.getUidList(scaleId, _filter); //
            if (!res) return;
            setSelectedScale(scaleId);
            setState(res.reverse());
        },
        [service],
    );

    const getFirstFilter = useCallback(
        async (scaleId: number) => {
            const scale = store.data.scaleList.find((e) => e.id === scaleId);
            if (!scale) return;
            setDisabledStartedDate(dayjs(scale.started_at).startOf('day'));
            setDisabledFinishedDate(dayjs(scale.finished_at).endOf('day'));
            const getScaleCities = await citiesService.scaleCity(scaleId);
            if (!getScaleCities) return;
            setScaleCities(getScaleCities);
            const getScalePersons = await personService.getByscale(scaleId);
            if (!getScalePersons) return;
            setScalePersons(getScalePersons);
        },
        [citiesService, personService, store],
    );

    const getDistrictFilter = useCallback(
        async (scaleId: number, cityId: number) => {
            const getScaleDistricts = await citiesService.scaleDistrict(scaleId, cityId);
            if (!getScaleDistricts) return;
            setScaleDistricts(getScaleDistricts);
        },
        [citiesService],
    );

    const getNeighbourhoodFilter = useCallback(
        async (scaleId: number, districtId: number) => {
            const getScaleNeighbourhood = await citiesService.scaleNeighbourhood(scaleId, districtId);
            if (!getScaleNeighbourhood) return;
            setScaleNeighbourhoods(getScaleNeighbourhood);
        },
        [citiesService],
    );

    const getStreetFilter = useCallback(
        async (scaleId: number, neighbourhoodId: number) => {
            const getScaleStreets = await citiesService.scaleStreet(scaleId, neighbourhoodId);
            if (!getScaleStreets) return;
            setScaleStreets(getScaleStreets);
        },
        [citiesService],
    );

    const getBuildingFilter = useCallback(
        async (scaleId: number, streetId: number) => {
            const getScaleBuildings = await citiesService.scaleBuilding(scaleId, streetId);
            if (!getScaleBuildings) return;
            setScaleBuildings(getScaleBuildings);
        },
        [citiesService],
    );

    const formItems = useCreateFormItems<ReportReqDto>([
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'started_at',
                label: 'StartDate',
            },
            element: (
                <IDatePicker
                    disabledDate={disabledStartDate}
                    onChange={(value) => {
                        setSelectedFilterStartDate(value);
                        setDisabledFinishedDate(dayjs(value).add(1, 'month').endOf('day'));
                    }}
                    disabled={!disabledStartedDate}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'finished_at',
                label: 'FinishedDate',
            },
            element: (
                <IDatePicker
                    defaultPickerValue={
                        selectedFilterStartDate != null
                            ? dayjs(selectedFilterStartDate).add(1, 'month').endOf('day')
                            : dayjs().endOf('day')
                    }
                    disabledDate={disabledEndDate}
                    disabled={!disabledFinishedDate || selectedFilterStartDate === null}
                    onChange={(value) => {
                        setDisabledStartedDate(dayjs(value).subtract(1, 'month').startOf('day'));
                    }}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'person',
                label: 'Person',
            },
            element: (
                <StyledSelect
                    options={scalePersons.map((e) => ({label: `${e.name} ${e.lastname}`, value: e.id}))}
                    disabled={scalePersons.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'city',
                label: 'City',
            },
            element: (
                <StyledSelect
                    options={scaleCities.map((e) => ({label: e.name, value: e.id}))}
                    onSelect={(v) => {
                        form.resetFields(['district', 'neighbourhood', 'streets']);
                        getDistrictFilter(selectedScale, Number(v));
                    }}
                    disabled={scaleCities.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'district',
                label: 'District',
            },
            element: (
                <StyledSelect
                    options={scaleDistricts.map((e) => ({label: e.name, value: e.id}))}
                    onSelect={(v) => {
                        //store.getNeighbourhood(Number(v));
                        form.resetFields(['neighbourhood', 'streets']);
                        getNeighbourhoodFilter(selectedScale, Number(v));
                    }}
                    disabled={scaleDistricts.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'neighbourhood',
                label: 'Neighbourhood',
            },
            element: (
                <StyledSelect
                    options={scaleNeighbourhoods.map((e) => ({label: e.name, value: e.id}))}
                    onSelect={(v) => {
                        form.resetFields(['streets']);
                        getStreetFilter(selectedScale, Number(v));
                    }}
                    disabled={scaleNeighbourhoods.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'streets',
                label: 'Street',
            },
            element: (
                <StyledSelect
                    options={scaleStreets.map((e) => ({label: e.name, value: e.id}))}
                    onSelect={(v) => {
                        //store.getStreetList(Number(v));
                        form.resetFields(['building']);
                        getBuildingFilter(selectedScale, Number(v));
                    }}
                    disabled={scaleStreets.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
        {
            colProps: {
                span: 24,
            },
            formItem: {
                name: 'building',
                label: 'Building',
            },
            element: (
                <StyledSelect
                    options={scaleBuildings.map((e) => ({label: e.name, value: e.id}))}
                    disabled={scaleBuildings.length === 0}
                />
            ),
            elementType: ComponentTypes.Input,
        },
    ]);

    interface UidList {
        created_at: string;
        uuid: string;
    }

    const columns: IColumnProps<UidList>[] = [
        {
            dataIndex: 'uuid',
            title: 'Uid Listesi',
            key: (dayjs().unix() * Math.random() * 1000).toString(),
            //render: (v, r) => {r.uuid},
        },
        {
            dataIndex: 'created_at',
            title: 'Tarih',
            key: (dayjs().unix() * Math.random() * 1000).toString(),
            //render: (v, r) => {v},
        },
        {
            dataIndex: 'uuid',
            key: (dayjs().unix() * Math.random() * 1000).toString(),
            title: 'Aksiyon',
            width: '100px',
            render: (v, r) => (
                <Button key={r.uuid} type='primary' onClick={() => setModalState((p) => ({data: r.uuid, open: true}))}>
                    <EyeOutlined key={r.uuid}/>
                    Göster
                </Button>
            ),
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Card>
                <Row gutter={[24, 24]} align='middle'>
                    <Col span={24}>
                        <Form.Item labelCol={{span: 24}} label={t('Scale')}>
                            <StyledSelect
                                onSelect={(v) => {
                                    setSelectedScale(Number(v));
                                    getFirstFilter(Number(v));
                                    getData(Number(v));
                                }} //}
                                style={{width: '100%'}}
                                optionFilterProp='label'
                                showSearch
                                options={store.data.scaleList.map((e) => ({label: e.name, value: e.id}))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24} md={4}>
                        <Form
                            form={form}
                            onFinish={(e) => {
                                getData(selectedScale, e);
                            }}
                            onReset={() => getData(selectedScale)}>
                            <Row gutter={24}>{formItems}</Row>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Button disabled={!selectedScale} block htmlType='submit' type='primary'>
                                        {t('Filter')}
                                    </Button>
                                </Col>
                                <Col span={24}>
                                    <Button
                                        block
                                        htmlType='button'
                                        type='dashed'
                                        onClick={() => {
                                            form.resetFields();
                                        }}>
                                        {t('Reset')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24} md={20}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <ITable columns={columns} data={state}  />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <ScaleUidShowModal
                data={modalState}
                onCancel={() => setModalState(ModalDataModel())}
                onDone={() => setModalState(ModalDataModel())}
            />
        </ThemeProvider>
    );
};

import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import { ScaleQuestionGetDto, ScaleQuestionSaveDto } from './scaleQuestion';

export const useScaleQuestionService = () => {
  const http = useHttp();

  const save = useCallback(
    async (req: ScaleQuestionSaveDto) => {
      return await http.post<ScaleQuestionSaveDto, ScaleQuestionGetDto[]>('/scale-to-question', req);
    },
    [http],
  );

  const get = useCallback(
    async (id: number) => {
      return await http.get<ScaleQuestionGetDto[]>(`/scale-to-question/${id}`);
    },
    [http],
  );

  return { save, get };
};

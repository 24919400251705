import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../../components/pageCard';
import { IColumnProps } from '../../../components/table';
import { ITable } from '../../../components/table/table';
import { useModal } from '../../../hooks/modal';
import { ModalData, ModalDataModel } from '../../../models/system';
import { IdNameDto } from '../../../services';
import { useScaleJobService } from '../../../services/scaleJob/scaleJob.service';
import { ScaleJobModal } from './scaleJobModa';

interface ScaleJobManagementProps {
}

export const ScaleJobManagement: React.FC<ScaleJobManagementProps> = props => {
    const service = useScaleJobService();
    const [state, setState] = useState<IdNameDto[]>([]);
    const modal = useModal();
    const { t } = useTranslation();
    const [modalState, setModalState] = useState<ModalData<IdNameDto>>(ModalDataModel());

    const getList = useCallback(async () => {
        const res = await service.list();
        if (!res) return;
        setState(res);
    }, [service, setState])

    useEffect(() => {
        getList();
         // eslint-disable-next-line
    }, [])


    const columns: IColumnProps<IdNameDto>[] = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Action',
            dataIndex: 'id',
            width: '100px',
            render: (v, r) => <Dropdown.Button menu={{
                items: [
                    {
                        key: 'detail',
                        label: t('Edit').toString(),
                        icon: <EditOutlined />,
                        onClick: () => setModalState(p => ({ data: r, open: true }))
                    },
                    {
                        key: 'delete',
                        label: t('Delete'),
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: t('Delete'),
                                content: t('DeleteConfirm', { value: r.name }),
                                onOk: async () => {
                                    await service.deleteById(r.id);
                                    getList();
                                }
                            })
                        }
                    }
                ]
            }}>
                {t('Action')}
            </Dropdown.Button>
        }
    ]

    return (
        <>
            <PageCard
                extra={<Button type='primary' onClick={() => setModalState(p => ({ data: null, open: true }))}>{t('New')}</Button>}
            >
                <ITable
                    data={state}
                    columns={columns}
                />
            </PageCard>
            <ScaleJobModal
                data={modalState}
                onCancel={() => setModalState(ModalDataModel())}
                onDone={()=>{
                    setModalState(ModalDataModel())
                    getList()
                }}
            />
        </>
    )
}
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { PlanListDto, FilterDto } from '../../services/plan/plan';
import { usePlanService } from '../../services/plan/plan.service';
import { AddressPlanModal } from './addressPlanModal';
import { PersonPlanModal } from './personPlanModal';

interface PlanningAndGoalManagementProps {}

export const PlanningAndGoalManagement: React.FC<PlanningAndGoalManagementProps> = (props) => {
  const service = usePlanService();
  const [state, setState] = useState<PlanListDto[]>([]);
  const [addressModalState, setAddressModalState] = useState<ModalData<PlanListDto>>(ModalDataModel());
  const [personModalState, setPersonModalState] = useState<ModalData<PlanListDto>>(ModalDataModel());
  const [tableCityFilter, setTableCityFilter] = useState<FilterDto[]>([]);
  const [tableDistrictFilter, setTableDistrictFilter] = useState<FilterDto[]>([]);
  const [tableNeighbourhoodFilter, setTableNeighbourhoodFilter] = useState<FilterDto[]>([]);
  const [tableProjectFilter, setTableProjectFilter] = useState<FilterDto[]>([]);
  const [tableScaleFilter, setTableScaleFilter] = useState<FilterDto[]>([]);
  const [tablePersonFilter, setTablePersonFilter] = useState<FilterDto[]>([]);
  const [tableNameFilter, setTableNameFilter] = useState<FilterDto[]>([]);
  const { t } = useTranslation();
  const modal = useModal();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    setState(res);
    const cityFilter = res
      .map((item) => {
        return {
          text: item.city?.name,
          value: item.city?.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableCityFilter(cityFilter);
    const districtFilter = res
      .map((item) => {
        return {
          text: item.district?.name,
          value: item.district?.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableDistrictFilter(districtFilter);
    const neighbourhoodFilter = res
      .map((item) => {
        return {
          text: item.neighbourhood?.name,
          value: item.neighbourhood?.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableNeighbourhoodFilter(neighbourhoodFilter);
    const projectFilter = res
      .map((item) => {
        return {
          text: item.scale.project.name,
          value: item.scale.project.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableProjectFilter(projectFilter);
    const scaleFilter = res
      .map((item) => {
        return {
          text: item.scale.name,
          value: item.scale.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTableScaleFilter(scaleFilter);
    const pesonFilter = res
      .map((item) => {
        return {
          text: `${item.person.name} ${item.person.lastname}`,
          value: item.person.id,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.value === item.value) === index;
      });
    setTablePersonFilter(pesonFilter);
    const nameFilter = res
      .map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      })
      .filter((item, index, array) => {
        return array.findIndex((element) => element.text === item.text) === index;
      });
    setTableNameFilter(nameFilter);
  }, [service, setState]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<PlanListDto>[] = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (v, r) => (r.name === 'Plan' ? 'Adres Planı' : r.name),
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      filters: tableNameFilter,
      filterSearch: true,
      onFilter: (value, r) => r.name === value,
    },
    {
      dataIndex: 'city',
      title: 'City',
      render: (v, r) => r.city?.name,
      sorter: (a, b) => a.city?.name.localeCompare(b.city.name),
      sortDirections: ['descend', 'ascend'],
      filters: tableCityFilter,
      filterSearch: true,
      onFilter: (value, r) => r.city?.id === value,
    },
    {
      dataIndex: 'district',
      title: 'District',
      render: (v, r) => r.district?.name,
      sorter: (a, b) => a.district?.name.localeCompare(b.district.name),
      sortDirections: ['descend', 'ascend'],
      filters: tableDistrictFilter,
      filterSearch: true,
      onFilter: (value, r) => r.district?.id === value,
    },
    {
      dataIndex: 'neighbourhood',
      title: 'Neighbourhood',
      render: (v, r) => r.neighbourhood?.name,
      sorter: (a, b) => a.neighbourhood?.name.localeCompare(b.neighbourhood.name),
      sortDirections: ['descend', 'ascend'],
      filters: tableNeighbourhoodFilter,
      filterSearch: true,
      onFilter: (value, r) => r.neighbourhood.id === value,
    },
    //{
    //  dataIndex: 'street',
    //  title: 'Street',
    //  render: (v, r) => r.street,
    //},
    {
      dataIndex: 'started_at',
      title: 'StartDate',
      sorter: (a, b) => a.started_at.localeCompare(b.started_at),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'finished_at',
      title: 'FinishedDate',
      sorter: (a, b) => a.finished_at.localeCompare(b.finished_at),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'project',
      title: 'Project',
      render: (v, r) => r.scale.project.name,
      filters: tableProjectFilter,
      filterSearch: true,
      onFilter: (value, r) => r.scale.project.id === value,
    },
    {
      dataIndex: 'scale',
      title: 'Scale',
      render: (v, r) => r.scale.name,
      filters: tableScaleFilter,
      filterSearch: true,
      onFilter: (value, r) => r.scale.id === value,
    },
    {
      title: 'Person',
      dataIndex: 'person',
      render: (v, r) => `${r.person.name} ${r.person.lastname}`,
      filters: tablePersonFilter,
      filterSearch: true,
      onFilter: (value, r) => r.person.id === value,
    },
    {
      title: 'Car',
      dataIndex: 'car',
      render: (v, r) => r.car.license,
    },
    {
      dataIndex: 'completedGlobal',
      title: 'CompletedGlobal',
      sorter: (a, b) => a.completedGlobal.localeCompare(b.completedGlobal),
      sortDirections: ['descend', 'ascend'],
    },
    {
      dataIndex: 'id',
      title: 'Action',
      render: (v, r) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                key: 'detail',
                label: t('Edit').toString(),
                icon: <EditOutlined />,
                onClick: () => {
                  if (r.name !== 'Plan') {
                    setPersonModalState((p) => ({ data: r, open: true }));
                  } else {
                    setAddressModalState((p) => ({ data: r, open: true }));
                  }
                },
              },
              {
                key: 'delete',
                label: t('Delete'),
                icon: <DeleteOutlined />,
                onClick: () => {
                  modal.confirm({
                    content: t('DeleteConfirm', {
                      value: `${r.city.name}-${r.district.name}-${r.neighbourhood.name}-${r.street}-${r.scale.name}-${r.person.name}`,
                    }),
                    onOk: async () => {
                      await service.remove(r.id);
                      getList();
                    },
                  });
                },
              },
            ],
          }}>
          {t('Action')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageCard
      extra={
        <>
          <Button onClick={() => setAddressModalState((p) => ({ data: null, open: true }))}>{t('AddressPlan')}</Button>{' '}
          &nbsp;
          <Button onClick={() => setPersonModalState((p) => ({ data: null, open: true }))}>{t('PersonPlan')}</Button>
        </>
      }>
      <ITable columns={columns} data={state} />
      <AddressPlanModal
        data={addressModalState}
        onCancel={() => setAddressModalState(ModalDataModel())}
        onDone={() => {
          setAddressModalState(ModalDataModel());
          getList();
        }}
      />
      <PersonPlanModal
        data={personModalState}
        onCancel={() => setPersonModalState(ModalDataModel())}
        onDone={() => {
          setPersonModalState(ModalDataModel());
          getList();
        }}
      />
    </PageCard>
  );
};

import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { QuestionPoolCreateDto, QuestionPoolDto, QuestionPoolGetByIdDto } from "./questionPool";

export const useQuestionPoolService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<QuestionPoolDto[]>('/question-pool');
    }, [http])

    const create = useCallback(async (req: QuestionPoolCreateDto) => {
        return await http.post<QuestionPoolCreateDto, WithId<QuestionPoolCreateDto>>('/question-pool', req);
    }, [http])

    const update = useCallback(async (id: number, req: QuestionPoolCreateDto) => {
        return await http.patch<QuestionPoolCreateDto, WithId<QuestionPoolDto>>(`/question-pool/${id}`, req);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/question-pool/${id}`);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<QuestionPoolGetByIdDto>(`/question-pool/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        deleteById,
        getById
    }
}
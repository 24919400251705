import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Switch, Tooltip } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { DecimalInput, StyledNumberInput } from '../../../components/form';
import { IColumnProps } from '../../../components/table';
import { ITable } from '../../../components/table/table';
import { useScale } from '../../../hooks/scale';
import { QuestionAnswerTypeEnum } from '../../../services';
import {
  ScaleAssigmentDto,
  ScaleQuestionGetQuestionDto,
  ScaleQuestionSaveAnswerDto,
} from '../../../services/scaleQuestion/scaleQuestion';
import { Question } from './question';

interface AnswersProps {
  data: ScaleAssigmentDto[];
  answerType?: number;
  question: ScaleQuestionGetQuestionDto;
  getScale(): void;
  onChangeAnswer(data: ScaleAssigmentDto): void;
}

export const Answers: React.FC<AnswersProps> = (props) => {
  const [state, setState] = useState<ScaleAssigmentDto[]>(props.data.sort((a, b) => a.sort - b.sort));
  const scale = useScale();

  const answerType = useMemo(() => {
    return props.question.answer_type ? Number(props.question.answer_type.id) : props.answerType;
  }, [props]);

  const canNotConditionalQuestionAdd = useMemo(() => {
    return props.question.parent !== null;
  }, [props.question]);

  const updateRow = useCallback(
    async (id: number, value: any, propName: Extract<keyof ScaleQuestionSaveAnswerDto, string>) => {
      setState((p) => {
        const answers = [...p];
        answers.forEach((answer) => {
          if (answer.id !== id) return;
          (answer as any)[propName] = value;
          scale.updateAnswer(answer.answer.id, value, propName);
        });

        return answers.sort((a, b) => a.sort - b.sort);
      });
    },
    [setState, scale],
  );

  const columns: IColumnProps<ScaleAssigmentDto>[] = [
    {
      title: 'Answer',
      dataIndex: 'answer',
      width: '150px',
      render: (v, r) => <p style={{ whiteSpace: 'pre-line' }}>{r.answer.answer.text}</p>,
    },
    {
      title: 'Arrangement',
      dataIndex: 'sort',
      render: (v, r) => (
        <StyledNumberInput
          style={{ textAlign: 'center' }}
          min={1}
          value={v}
          onChange={(e) => {
            updateRow(r.id, Number(e.target.value), 'sort');
          }}
        />
      ),
    },
    {
      title: 'AnswerPoint',
      dataIndex: 'point',
      render: (v, r) => (
        <DecimalInput
          inputMode='decimal'
          value={v}
          onChange={(e) => {
            updateRow(r.id, e, 'point');
          }}
        />
      ),
    },
    {
      title: 'AnswerFactor',
      dataIndex: 'factor',
      render: (v, r) => (
        <DecimalInput
          inputMode='decimal'
          value={v}
          onChange={(e) => {
            updateRow(r.id, e, 'factor');
          }}
        />
      ),
    },
    {
      title: 'FinishScale',
      align: 'center',
      dataIndex: 'closed',
      render: (v, r) => (
        <Switch
          checked={v}
          onChange={(e) => {
            updateRow(r.id, e, 'closed');
          }}
        />
      ),
    },
    {
      title: 'WhyTextArea',
      dataIndex: 'text',
      align: 'center',
      width: '50px',
      render: (v, r) => (
        <Switch
          checked={v}
          onChange={(e) => {
            updateRow(r.id, e, 'text');
          }}
          disabled={answerType === QuestionAnswerTypeEnum.QuestionManyToMany}
        />
      ),
    },
    {
      title: 'Yazı Alanı',
      dataIndex: 'text_question',
      align: 'center',
      width: '50px',
      render: (v, r) => (
        <Select
          defaultValue={v.text}
          style={{ width: 120 }}
          disabled={!r.text || answerType === QuestionAnswerTypeEnum.QuestionManyToMany}
          options={[
            { value: null, label: 'Yazınız' },
            { value: 1, label: 'Neden ?' },
            { value: 2, label: 'Diğer' },
            { value: 4, label: 'Açıklama' },
          ]}
          onSelect={(e) => {
            updateRow(r.id, e, 'textQuestion');
          }}
        />
      ),
    },
    {
      title: 'Active',
      align: 'center',
      dataIndex: 'status',
      render: (v, r) => (
        <Switch
          checked={v}
          onChange={(e) => {
            updateRow(r.id, e, 'status');
          }}
        />
      ),
    },
    {
      title: 'ConditionalQuestionAdd',
      dataIndex: 'id',
      align: 'right',
      render: (v, r) => {
        const disabled = canNotConditionalQuestionAdd || answerType === QuestionAnswerTypeEnum.QuestionManyToMany;
        const AddButton = (
          <Button
            onClick={() => scale.toggleAppendQuestionModal({ answerId: r.answer.id })}
            disabled={canNotConditionalQuestionAdd || answerType === QuestionAnswerTypeEnum.QuestionManyToMany}
            type='primary'>
            <PlusOutlined />
          </Button>
        );

        if (disabled) {
          return <Tooltip title={t('CanNotConditionalQuestionAdd')}>{AddButton}</Tooltip>;
        } else {
          return AddButton;
        }
      },
    },
  ];

  return (
    <>
      <ITable
        columns={columns}
        data={state}
        pagination={false}
        expandable={{
          expandedRowRender: (record: ScaleAssigmentDto) =>
            record.realeted && (
              <Question
                sort={0}
                getScale={() => props.getScale()}
                onChangeAnswer={props.onChangeAnswer}
                isReleated
                data={record.realeted}
                answerType={record.parent_answer_type}
              />
            ),
          rowExpandable: (record: ScaleAssigmentDto) => record.realeted !== null,
        }}
      />
    </>
  );
};

import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import {
  AuthLoginReqDto,
  AuthLoginResDto,
  AuthSignInReqDto,
  AuthSignInResDto,
  AuthUpdateReqDto,
  AuthUpdateResDto,
} from './auth';

export const useAuthService = () => {
  const http = useHttp();

  const signIn = useCallback(
    async (req: AuthSignInReqDto) => {
      return await http.post<AuthSignInReqDto, AuthSignInResDto>('/auth/sign-in', req);
    },
    [http],
  );

  const forgetPassword = useCallback(async () => {
    return await http.post<undefined, boolean>('/auth/forget-password', undefined);
  }, [http]);

  const login = useCallback(
    async (req: AuthLoginReqDto) => {
      return await http.post<AuthLoginReqDto, AuthLoginResDto>('/auth/login', req);
    },
    [http],
  );

  const me = useCallback(async () => {
    return await http.get<AuthUpdateResDto>('/auth/me');
  }, [http]);

  const update = useCallback(
    async (req: AuthUpdateReqDto) => {
      return await http.post<AuthUpdateReqDto, boolean>('/auth/me', req);
    },
    [http],
  );

  const remove = useCallback(async () => {
    return await http.delete('/auth/me');
  }, [http]);

  return {
    signIn,
    forgetPassword,
    login,
    me,
    update,
    remove,
  };
};

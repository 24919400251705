import { useCallback } from 'react';
import { useHttp } from '../../hooks/http';
import {
  ScaleQuestionGetDto,
  ScaleExportGetDto,
  ScaleMapLocationDto,
  TagCloudDto,
} from '../scaleQuestion/scaleQuestion';
import { ReportReqDto } from './report';

export const useReportService = () => {
  const http = useHttp();

  const get = useCallback(
    async (scaleId: number, params: ReportReqDto) => {
      return await http.get<ScaleQuestionGetDto[]>(`/scale-report/${scaleId}`, { params });
    },
    [http],
  );

  const rexport = useCallback(
    async (scaleId: number, params: ReportReqDto) => {
      return await http.get<ScaleExportGetDto>(`/export/${scaleId}`, { params });
    },
    [http],
  );

  const mapLocations = useCallback(
    async (scaleId: number) => {
      return await http.get<ScaleMapLocationDto>(`/map/${scaleId}`);
    },
    [http],
  );

  const getTagClouds = useCallback(
    async (scaleId: number, questionId: number) => {
      return await http.get<TagCloudDto[]>(`/scale-report/${scaleId}/word/${questionId}`);
    },
    [http],
  );

  return {
    get,
    rexport,
    mapLocations,
    getTagClouds,
  };
};

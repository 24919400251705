import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Register: React.FC = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <h1>Register</h1>
            <button onClick={() => {
                navigate('/login')
            }}>Register</button>
        </>
    )
}
import { InputProps } from 'antd';
import React, { useMemo } from 'react';
import InputColor from 'react-input-color';

interface ColorPickerProps extends InputProps {
}

export const ColorPicker: React.FC<ColorPickerProps> = props => {
    const initValue = useMemo(() => {
        return String(props.value || props.defaultValue || '')
    }, [props.value, props.defaultValue])

    return (
        <>
            <InputColor
                initialValue={initValue}
                onChange={(color) => {
                    props.onChange && props.onChange(color.hex as any)
                }}
            />
        </>
    )
}
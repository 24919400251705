import { Button, Card, Col, Form, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledSelect } from '../../components/form';
import { useStore } from '../../store';
import { ThemeProvider, useTheme } from '@nivo/core';
import { ScaleQuestionGetDto } from '../../services/scaleQuestion/scaleQuestion';
import { useReportService } from '../../services/report/report.service';
import { useCitiesService } from '../../services/cities/cities.service';
import { ReportQuestion } from './reportQuestion';
import { useTranslation } from 'react-i18next';
import { ScaleGeneralInformationModal } from '../scaleManagement/scaleModal';
import { ComponentTypes, ModalData, ModalDataModel } from '../../models/system';
import { ReportReqDto } from '../../services/report/report';
import { useCreateFormItems } from '../../helpers/form';
import { useForm } from 'antd/es/form/Form';
import { IDatePicker } from '../../components/datepicker';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { RangePickerProps } from 'antd/es/date-picker';
import { IdNameDto } from '../../services';
import MapViewModal from './mapViewModal';
import { PersonListDto } from '../../services/person/person';
import { usePersonService } from '../../services/person/person.service';

interface ReportsProps {}

export const Reports: React.FC<ReportsProps> = (props) => {
  const store = useStore();
  const theme = useTheme();
  const service = useReportService();
  const citiesService = useCitiesService();
  const [state, setState] = useState<ScaleQuestionGetDto[]>([]);
  const [selectedScale, setSelectedScale] = useState(0);
  const [selectedFilterStartDate, setSelectedFilterStartDate] = useState<any>(null);
  const [disabledStartedDate, setDisabledStartedDate] = useState<any>(false);
  const [disabledFinishedDate, setDisabledFinishedDate] = useState<any>(false);
  const [scaleCities, setScaleCities] = useState<IdNameDto[]>([]);
  const [scalePersons, setScalePersons] = useState<PersonListDto[]>([]);
  const [scaleDistricts, setScaleDistricts] = useState<IdNameDto[]>([]);
  const [scaleNeighbourhoods, setScaleNeighbourhoods] = useState<IdNameDto[]>([]);
  const [scaleStreets, setScaleStreets] = useState<IdNameDto[]>([]);
  const [scaleBuildings, setScaleBuildings] = useState<IdNameDto[]>([]);
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const [mapModalState, setMapModalState] = useState<ModalData<number>>(ModalDataModel());
  const [form] = useForm();
  const personService = usePersonService();
  dayjs.extend(isBetween);

  // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < disabledStartedDate;
  };

  // eslint-disable-next-line arrow-body-style, @typescript-eslint/no-unused-vars
  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && (current > disabledFinishedDate || current < selectedFilterStartDate);
  };

  const getData = useCallback(
    async (scaleId: number, _filter: ReportReqDto = {}) => {
      if (!scaleId) return;
      if (_filter.started_at) {
        _filter.started_at = dayjs(_filter.started_at).format('YYYY-MM-DD');
      }
      if (_filter.finished_at) {
        _filter.finished_at = dayjs(_filter.finished_at).format('YYYY-MM-DD');
      }
      const res = await service.get(scaleId, _filter);
      if (!res) return;
      setSelectedScale(scaleId);
      setState(res);
    },
    [service],
  );

  const getFirstFilter = useCallback(
    async (scaleId: number) => {
      const scale = store.data.scaleList.find((e) => e.id === scaleId);
      if (!scale) return;
      setDisabledStartedDate(dayjs(scale.started_at).startOf('day'));
      setDisabledFinishedDate(dayjs(scale.finished_at).endOf('day'));
      const getScaleCities = await citiesService.scaleCity(scaleId);
      if (!getScaleCities) return;
      setScaleCities(getScaleCities);
      const getScalePersons = await personService.getByscale(scaleId);
      if (!getScalePersons) return;
      setScalePersons(getScalePersons);
    },
    [citiesService, personService, store],
  );

  const getDistrictFilter = useCallback(
    async (scaleId: number, cityId: number) => {
      const getScaleDistricts = await citiesService.scaleDistrict(scaleId, cityId);
      if (!getScaleDistricts) return;
      setScaleDistricts(getScaleDistricts);
    },
    [citiesService],
  );

  const getNeighbourhoodFilter = useCallback(
    async (scaleId: number, districtId: number) => {
      const getScaleNeighbourhood = await citiesService.scaleNeighbourhood(scaleId, districtId);
      if (!getScaleNeighbourhood) return;
      setScaleNeighbourhoods(getScaleNeighbourhood);
    },
    [citiesService],
  );

  const getStreetFilter = useCallback(
    async (scaleId: number, neighbourhoodId: number) => {
      const getScaleStreets = await citiesService.scaleStreet(scaleId, neighbourhoodId);
      if (!getScaleStreets) return;
      setScaleStreets(getScaleStreets);
    },
    [citiesService],
  );

  const getBuildingFilter = useCallback(
    async (scaleId: number, streetId: number) => {
      const getScaleBuildings = await citiesService.scaleBuilding(scaleId, streetId);
      if (!getScaleBuildings) return;
      setScaleBuildings(getScaleBuildings);
    },
    [citiesService],
  );

  useEffect(() => {
    store.getScaleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = async (scaleId: number, _filter: ReportReqDto = {}) => {
    if (!scaleId) return;
    if (!scaleId) return;
    if (_filter.started_at) {
      _filter.started_at = dayjs(_filter.started_at).format('YYYY-MM-DD');
    }
    if (_filter.finished_at) {
      _filter.finished_at = dayjs(_filter.finished_at).format('YYYY-MM-DD');
    }
    const res = await service.rexport(scaleId, _filter);
    if (res) {
      window.open(res.url || '', '_blank');
    } else {
      return;
    }
  };

  const formItems = useCreateFormItems<ReportReqDto>([
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'started_at',
        label: 'StartDate',
      },
      element: (
        <IDatePicker
          disabledDate={disabledStartDate}
          onChange={(value) => {
            setSelectedFilterStartDate(value);
            setDisabledFinishedDate(dayjs(value).add(1, 'month').endOf('day'));
          }}
          allowClear={false}
          disabled={!disabledStartedDate}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'finished_at',
        label: 'FinishedDate',
      },
      element: (
        <IDatePicker
          defaultPickerValue={
            selectedFilterStartDate != null
              ? dayjs(selectedFilterStartDate).add(1, 'month').endOf('day')
              : dayjs().endOf('day')
          }
          allowClear={false}
          disabledDate={disabledEndDate}
          disabled={!disabledFinishedDate || selectedFilterStartDate === null}
          onChange={(value) => {
            setDisabledStartedDate(dayjs(value).subtract(1, 'month').startOf('day'));
          }}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'person',
        label: 'Person',
      },
      element: (
        <StyledSelect
          options={scalePersons.map((e) => ({ label: `${e.name} ${e.lastname}`, value: e.id }))}
          disabled={scalePersons.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'city',
        label: 'City',
      },
      element: (
        <StyledSelect
          options={scaleCities.map((e) => ({ label: e.name, value: e.id }))}
          //onDropdownVisibleChange={() => store.getCityList()}
          onSelect={(v) => {
            //store.getDistrict(Number(v));
            form.resetFields(['district', 'neighbourhood', 'streets']);
            getDistrictFilter(selectedScale, Number(v));
          }}
          disabled={scaleCities.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'district',
        label: 'District',
      },
      element: (
        <StyledSelect
          options={scaleDistricts.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => {
            //store.getNeighbourhood(Number(v));
            form.resetFields(['neighbourhood', 'streets']);
            getNeighbourhoodFilter(selectedScale, Number(v));
          }}
          disabled={scaleDistricts.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'neighbourhood',
        label: 'Neighbourhood',
      },
      element: (
        <StyledSelect
          options={scaleNeighbourhoods.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => {
            //store.getStreetList(Number(v));
            form.resetFields(['streets']);
            getStreetFilter(selectedScale, Number(v));
          }}
          disabled={scaleNeighbourhoods.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'streets',
        label: 'Street',
      },
      element: (
        <StyledSelect
          options={scaleStreets.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => {
            //store.getStreetList(Number(v));
            form.resetFields(['building']);
            getBuildingFilter(selectedScale, Number(v));
          }}
          disabled={scaleStreets.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'building',
        label: 'Building',
      },
      element: (
        <StyledSelect
          options={scaleBuildings.map((e) => ({ label: e.name, value: e.id }))}
          disabled={scaleBuildings.length === 0}
        />
      ),
      elementType: ComponentTypes.Input,
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <Row gutter={[24, 24]} align='middle'>
          <Col span={20}>
            <Form.Item labelCol={{ span: 24 }} label={t('Scale')}>
              <StyledSelect
                onSelect={(v) => {
                  setSelectedScale(Number(v));
                  //getData(Number(v));
                  getFirstFilter(Number(v));
                }} //}
                style={{ width: '100%' }}
                optionFilterProp='label'
                showSearch
                options={store.data.scaleList.map((e) => ({ label: e.name, value: e.id }))}
                //onFocus={() => store.getScaleList()}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              disabled={!selectedScale}
              type='primary'
              onClick={() => {
                setModalState((p) => ({ data: selectedScale, open: true }));
              }}>
              {t('ScaleDetail')}
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} md={4}>
            <Form
              form={form}
              onFinish={(e) => {
                getData(selectedScale, e);
              }}
              onReset={() => getData(selectedScale)}>
              <Row gutter={24}>{formItems}</Row>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Button disabled={!selectedScale} block htmlType='submit' type='primary'>
                    {t('Filter')}
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    block
                    htmlType='button'
                    type='dashed'
                    onClick={() => {
                      form.resetFields();
                    }}>
                    {t('Reset')}
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    disabled={!selectedScale}
                    block
                    htmlType='button'
                    type='primary'
                    onClick={() => download(selectedScale, form.getFieldsValue())}>
                    {t('İndir')}
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    disabled={!selectedScale}
                    block
                    htmlType='button'
                    type='primary'
                    onClick={() => setMapModalState((p) => ({ data: selectedScale, open: true }))}>
                    {t('Haritada Görüntüle')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={{ span: 24 }} md={20}>
            <Row gutter={[24, 24]}>
              {state
                .sort((a, b) => a.sort - b.sort)
                .map((data, index) => (
                  <Col key={index.toString()} span={12}>
                    <ReportQuestion
                      data={data}
                      question_id={data.question_id}
                      scale_id={selectedScale}
                      //words={scaleWordCloud[data.question_id] !== undefined ? scaleWordCloud[data.question_id] : null}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Card>
      <ScaleGeneralInformationModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => setModalState(ModalDataModel())}
      />
      <MapViewModal
        data={mapModalState}
        onCancel={() => setMapModalState(ModalDataModel())}
        onDone={() => setMapModalState(ModalDataModel())}
      />
    </ThemeProvider>
  );
};

import { Alert, Button, Card, Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ScaleGetByIdDto } from '../../services/scale/scale';
import { ScaleQuestionGetDto, } from '../../services/scaleQuestion/scaleQuestion';
import { useScaleQuestionService } from '../../services/scaleQuestion/scaleQuestion.service';
import { useScaleService } from '../../services/scale/scale.service';
import { Question } from './components/question';
import { useScale } from '../../hooks/scale';
import { AppendQuestionModal } from './components/appendQuestionModal';
import { QuestionAppender } from './components/questionAppender';

interface ScaleManageQuestionProps {
}

export const ScaleManageQuestion: React.FC<ScaleManageQuestionProps> = props => {
    const scaleQuestionService = useScaleQuestionService();
    const scaleService = useScaleService();

    const { id } = useParams();
    const [state, setState] = useState<ScaleGetByIdDto | null>(null)
    const [currentQuestions, setCurrentQuestions] = useState<ScaleQuestionGetDto[]>([])
    const { t } = useTranslation();
    const scaleStore = useScale();

    useEffect(()=>{
        scaleStore.init()
        // eslint-disable-next-line
    },[])

    //#region Fetch Data
    const getQuestions = useCallback(async (scale: number) => {
        const res = await scaleQuestionService.get(scale);
        if (!res) return;
        setCurrentQuestions(p => res.sort((a, b) => a.sort > b.sort ? 1 : -1));
    }, [scaleQuestionService, setCurrentQuestions])

    useEffect(() => {
        scaleStore.createSaveData(currentQuestions, Number(id));
        // eslint-disable-next-line
    }, [currentQuestions])

    const getDetail = useCallback(async (id: number) => {
        const res = await scaleService.getById(id);
        if (!res) return;
        setState(res)
        getQuestions(id);
    }, [scaleService, setState, getQuestions])


    useEffect(() => {
        if (!id) return;
        getDetail(Number(id))
        // eslint-disable-next-line
    }, [])
    //#endregion Fetch Data

    const onSave = useCallback(async () => {
        const res = await scaleStore.save();
        if (!res) return;
        getQuestions(scaleStore.data.scale);
    }, [getQuestions, scaleStore])

    return (
        <>
            <Card
                title={t('ScaleQuestionManagement', { title: state ? state.name : '' })}
                extra={<Button type='primary' onClick={() => onSave()}>{t('Save')}</Button>}
            >

                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Alert type='info' message={t('ScaleQuestionManageInformation')} />
                    </Col>
                    <Col span={5}>
                        <QuestionAppender
                            onDone={() => getQuestions(Number(id))}
                        />
                    </Col>
                    <Col span={19} style={{
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        height: '100%',
                        maxHeight: 'calc(100vh - 3 00px)'
                    }} >
                        {
                            currentQuestions.map(e => {
                                return <Question
                                    key={e.id} data={e.question[0]}
                                    sort={e.sort}
                                    answerType={Number(e.answer_type.id)}
                                    getScale={() => getQuestions(Number(id))}
                                    onChangeAnswer={(data) => {

                                    }}
                                    onChangeQuestion={() => {
                                        onSave();
                                    }}
                                />
                            })
                        }
                    </Col>
                </Row>
                <AppendQuestionModal
                    onDone={() => getQuestions(Number(id))}
                />
            </Card>
        </>
    )
}
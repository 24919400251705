import { useCallback } from 'react';
import { WithId } from '..';
import { useHttp } from '../../hooks/http';
import {
  ScaleCreateDto,
  ScaleGetByIdDto,
  ScaleListResDto,
  ScaleUidListDto,
  ScaleUidResDto,
  ScaleUpdateStatusDto
} from './scale';
import { ReportReqDto } from '../report/report';

export const useScaleService = () => {
  const http = useHttp();

  const list = useCallback(async () => {
    return await http.get<ScaleListResDto[]>('/scale');
  }, [http]);

  const create = useCallback(
    async (req: ScaleCreateDto) => {
      return await http.post<ScaleCreateDto, WithId<ScaleCreateDto>>('/scale', req);
    },
    [http],
  );

  const update = useCallback(
    async (id: number, req: ScaleCreateDto) => {
      return await http.patch<ScaleCreateDto, ScaleListResDto>(`/scale/${id}`, req);
    },
    [http],
  );

  const updateStatus = useCallback(
    async (id: number, req: ScaleUpdateStatusDto) => {
      return await http.patch<ScaleUpdateStatusDto, ScaleListResDto>(`/scale/${id}`, req);
    },
    [http],
  );

  const getById = useCallback(
    async (id: number) => {
      return await http.get<ScaleGetByIdDto>(`/scale/${id}`);
    },
    [http],
  );

  const deleteById = useCallback(
    async (id: number) => {
      return await http.delete(`/scale/${id}`);
    },
    [http],
  );

  const getUidList = useCallback(
    async (scaleId: number, params: ReportReqDto) => {
      return await http.get<ScaleUidListDto[]>(`/reply?scale=${scaleId}`, { params }); //${scaleId}
    },
    [http],
  );

  const showUid = useCallback(
    async (uid: string) => {
      return await http.get<ScaleUidResDto[]>(`/uuid-report/${uid}`); //${uid}
    },
    [http],
  );

  return {
    list,
    create,
    update,
    updateStatus,
    getById,
    deleteById,
    getUidList,
    showUid,
  };
};

import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageCard } from '../../components/pageCard';
import { IColumnProps } from '../../components/table';
import { ITable } from '../../components/table/table';
import { useModal } from '../../hooks/modal';
import { ModalData, ModalDataModel } from '../../models/system';
import { ScaleListResDto } from '../../services/scale/scale';
import { useScaleService } from '../../services/scale/scale.service';
import { ScaleGeneralInformationModal } from './scaleModal';
import ScaleLinkGenerateModal from './ScaleLinkGenerateModal';
import { useStore } from '../../store';
import dayjs from 'dayjs';

interface ScaleManagementProps {}

export const ScaleManagement: React.FC<ScaleManagementProps> = (props) => {
  const service = useScaleService();
  const [state, setState] = useState<ScaleListResDto[]>([]);
  const [modalState, setModalState] = useState<ModalData<number>>(ModalDataModel());
  const [modalLinkGenerateState, setModalLinkGenerateState] = useState<ModalData<number>>(ModalDataModel());
  const navigate = useNavigate();
  const modal = useModal();
  const store = useStore();

  const getList = useCallback(async () => {
    const res = await service.list();
    if (!res) return;
    let newRes = res;
    if (store.data.userPermissions.includes('scale.update')) {
      newRes = res.filter((item) => item.status === true);
    }
    setState(newRes);
    //console.log(store.data.userPermissions);
    //console.log(!store.data.userPermissions.includes('scale.edit'));
    //console.log(!store.data.userPermissions.includes('*'));
  }, [service, store.data.userPermissions]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const columns: IColumnProps<ScaleListResDto>[] = [
    {
      dataIndex: 'name',
      title: 'Name',
      render: (v, r) => {
        if (r.deleted_at !== null) {
          return <span style={{ color: 'gray', textDecoration: 'line-through' }}>{v}</span>;
        } else {
          if (r.status === false) {
            return <span style={{ color: 'orange' }}>{v}</span>;
          } else {
            if (dayjs().isBefore(dayjs(r.finished_at))) {
              return v;
            } else {
              return <span style={{ color: 'red' }}>{v}</span>;
            }
          }
        }
      },
    },
    {
      dataIndex: 'id',
      title: 'Action',
      width: '100px',
      render: (v, r) => {
        let menu: any = {};
        menu.items = [
          {
            key: 'detail',
            label: t('Edit').toString(),
            icon: <EditOutlined />,
            onClick: () => setModalState((p) => ({ data: r.id, open: true })),
            //disabled: store.data.userPermissions.includes('scale.edit')
            //  ? false
            //  : !store.data.userPermissions.includes('*'),
          },
          {
            key: 'generateLink',
            label: t('GenerateLink').toString(),
            icon: <UnorderedListOutlined />,
            onClick: () => setModalLinkGenerateState((p) => ({ data: r.id, open: true })),
          },
          {
            key: 'manage-questions',
            label: t('ManageQuestions'),
            icon: <SettingOutlined />,
            onClick: () => {
              navigate(`/scale-management/manage-question/${r.id}`);
            },
          },
          {
            key: 'remove',
            label: t('Delete'),
            icon: <DeleteOutlined />,
            onClick: () => {
              modal.confirm({
                title: t('Delete'),
                content: t('DeleteConfirm', { value: r.name }),
                onOk: async () => {
                  const res = await service.deleteById(r.id);
                  if (!res) return;
                  getList();
                },
              });
            },
          },
        ];
        if (r.status === false) {
          menu.items.push({
            key: 'adminApprove',
            label: t('AdminApprove').toString(),
            icon: <CheckCircleOutlined />,
            onClick: async () => {
              await service.updateStatus(r.id, {
                status: true,
              });
              getList();
            },
          });
        } else {
          //
        }
        return <Dropdown.Button menu={menu}>{t('Action')}</Dropdown.Button>;
      },
    },
  ];
  return (
    <>
      <PageCard
        extra={
          <Button type='primary' onClick={() => setModalState((p) => ({ data: null, open: true }))}>
            {t('New')}
          </Button>
        }>
        <ITable columns={columns} data={state} />
      </PageCard>
      <ScaleLinkGenerateModal
        data={modalLinkGenerateState}
        onCancel={() => setModalLinkGenerateState(ModalDataModel())}
        onDone={() => {
          setModalLinkGenerateState(ModalDataModel());
        }}
      />
      <ScaleGeneralInformationModal
        data={modalState}
        onCancel={() => setModalState(ModalDataModel())}
        onDone={() => {
          setModalState(ModalDataModel());
          getList();
        }}
      />
    </>
  );
};

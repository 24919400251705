import { Button, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IDatePicker } from '../../components/datepicker';
import { StyledInput, StyledSelect } from '../../components/form';
import { RichEditor } from '../../components/richEditor';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { ScaleCreateDto, ScaleGetByIdDto } from '../../services/scale/scale';
import { useScaleService } from '../../services/scale/scale.service';
import { useStore } from '../../store';

interface ScaleGeneralInformationModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const ScaleGeneralInformationModal: React.FC<ScaleGeneralInformationModalProps> = props => {
    const [form] = useForm();
    const service = useScaleService();
    const { t } = useTranslation();
    const [state, setState] = useState<ScaleGetByIdDto | null>(null);
    const store = useStore();
    const navigate = useNavigate();

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res) return;
        form.setFieldsValue(res);
        store.getQuotaList();
        store.getProjectList();
        store.getScaleFactorList();
        store.getScaleJobList();
        setState(res);
    }, [form, service, setState, store])

    useEffect(() => {
        if (props.data.data && !state) {
            getData(props.data.data)
        }
        // eslint-disable-next-line
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
        setState(null);
    }, [form])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (saveAndContinue?: boolean) => {
        let res: any | false = false;
        const valid = await form.validateFields().then(e => true).catch(e => false);
        if (!valid) return;
        const data = form.getFieldsValue();
        if (props.data.data) {
            res = await service.update(props.data.data, data);
        } else {
            res = await service.create(data);
        }
        if (!res) return;
        onDone();
        if (saveAndContinue) {
            navigate(`/scale-management/manage-question/${res.id}`)
        }
    }, [onDone, service, navigate, form, props.data.data])

    const formItems = useCreateFormItems<ScaleCreateDto>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 6
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'quota',
                label: 'Quota',
                rules: [
                    {
                        required: false
                    }
                ]
            },
            colProps: {
                span: 5
            },
            element: <StyledSelect options={store.data.quotaList.map(e => ({ label: e.name, value: e.id }))} onDropdownVisibleChange={() => store.getQuotaList()} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'project',
                label: 'Project',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 5
            },
            element: <StyledSelect options={store.data.projectList.map(e => ({ label: e.name, value: e.id }))} onDropdownVisibleChange={() => store.getProjectList()} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'started_at',
                label: 'StartDate',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 4
            },
            element: <IDatePicker />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'finished_at',
                label: 'FinishedDate',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 4
            },
            element: <IDatePicker />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'factor',
                label: 'ScaleType',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 5
            },
            element: <StyledSelect options={store.data.scaleFactorList.map(e => ({ label: e.name, value: e.id }))} onDropdownVisibleChange={() => store.getScaleFactorList()} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'business',
                label: 'BusinessType',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 5
            },
            element: <StyledSelect options={store.data.scaleJobList.map(e => ({ label: e.name, value: e.id }))} onDropdownVisibleChange={() => store.getScaleJobList()} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'description',
                label: 'ScaleDescription'
            },
            colProps: {
                span: 24
            },
            element: <RichEditor />,
            elementType: ComponentTypes.Input,
        },
        {
            formItem: {
                name: 'managerSummary',
                label: 'ManagerSummary'
            },
            colProps: {
                span: 24
            },
            element: <RichEditor />,
            elementType: ComponentTypes.Input,
        }
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            footer={[
                <Button key="scaleModalCancelBtn" onClick={() => onCancel()}>{t('Cancel')}</Button>,
                <Button key="scaleModalSaveBtn" type='primary' onClick={() => onSubmit()}>{t('Save')}</Button>,
                <Button key="scaleModalSaveAndContinue" type='primary' onClick={() => onSubmit(true)}>{t('SaveAndContinue')}</Button>,
            ]}
            style={{
                minWidth: '80vw'
            }}
        >
            <Form form={form} id="UserManagementForm">
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
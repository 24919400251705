import { DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Form } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledNumberInput } from '../../../components/form';
import { useModal } from '../../../hooks/modal';
import { useScale } from '../../../hooks/scale';
import { ScaleAssigmentDto, ScaleQuestionGetQuestionDto } from '../../../services/scaleQuestion/scaleQuestion';
import { Answers } from './answers';

interface QuestionProps {
    data: ScaleQuestionGetQuestionDto;
    onChangeAnswer(data: ScaleAssigmentDto): void;
    answerType?: number;
    getScale(): void;
    sort?: number;
    onChangeQuestion?(): void;
    isReleated?: boolean;
}

export const Question: React.FC<QuestionProps> = props => {
    const { t } = useTranslation();
    const scaleStore = useScale();
    const modal = useModal();

    const canAddReleatedQuestion = useMemo(() => {
        if (props.data.parent) return false;
        if (!props.data.assigment) return true;

        return props.data.assigment.filter(e => e.realeted !== null).length === 0;
    }, [props.data])

    return (
        <>
            <Card
                size='small'
                title={props.data.question.text}
                style={{
                    marginBottom: '20px',
                }}
                extra={<div style={{ display: 'flex', gap: '10px' }}>
                    {!props.isReleated && <Form.Item style={{ margin: '0px' }} label={t('QuestionArrangement')}>
                        <StyledNumberInput
                            min={1}
                            style={{ width: '80px', textAlign: 'center' }}
                            onChange={async (v) => {
                                await scaleStore.updateQuestionSort(props.data.id, Number(v.target.value))
                                props.onChangeQuestion && await props.onChangeQuestion()
                            }} defaultValue={props.sort}
                        />
                    </Form.Item>}
                    <Button key="addRelatedQuestion" disabled={!canAddReleatedQuestion} onClick={() => scaleStore.toggleAppendQuestionModal({ questionId: props.data.id })}>{t('AddReleatedQuestion')}</Button>
                    <Button key="removeQuestion" onClick={() => {
                        modal.confirm({
                            title: t('Delete'),
                            content: t('DeleteConfirm', { value: props.data.question.text }),
                            onOk: async () => {
                                const res = await scaleStore.removeQuestion(props.data.id)
                                if (!res) return;
                                props.getScale()
                            }
                        })
                    }}><DeleteOutlined /></Button>
                </div>}
            >
                {
                    props.data.parent &&
                    <>
                        <Question
                            getScale={() => props.getScale()}
                            // sort={props.data.parent.sort}
                            onChangeAnswer={props.onChangeAnswer}
                            data={props.data.parent.question[0]}
                            isReleated
                        />
                    </>
                }
                <Answers
                    question={props.data}
                    answerType={props.data.answer_type ? Number(props.data.answer_type.id) : props.answerType}
                    onChangeAnswer={props.onChangeAnswer}
                    data={props.data.assigment || []}
                    getScale={() => props.getScale()}
                />
            </Card >
        </>
    )
}
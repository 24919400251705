import { useCallback } from "react"
import { useHttp } from "../../hooks/http"
import { UserCreateReqDto, UserCreateResDto, UserListResDto, UserUpdateReqDto } from "./user";

export const useUserSerivce = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<UserListResDto[]>('/user')
    }, [http]);

    const create = useCallback(async (req: UserCreateReqDto) => {
        return await http.post<UserCreateReqDto, UserCreateResDto>('/user', req);
    }, [http]);

    const update = useCallback(async (id: number, req: UserUpdateReqDto) => {
        return await http.patch<UserUpdateReqDto, UserCreateResDto>(`/user/${id}`, req);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<UserCreateResDto>(`/user/${id}`);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/user/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        getById,
        deleteById
    }
}
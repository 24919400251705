import { Button, Form, FormInstance, Tag, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useState } from 'react';
import { StyledSelect } from '../../../components/form';
import { useCreateFormItems } from '../../../helpers/form';
import { ComponentTypes } from '../../../models/system';
import { QuestionPoolGetByIdDto } from '../../../services/questionPool/questionPool';
import { useQuestionPoolService } from '../../../services/questionPool/questionPool.service';
import { useStore } from '../../../store';
import { ScaleAssigmentDto } from '../../../services/scaleQuestion/scaleQuestion';
import { t } from 'i18next';
import { useQuestionService } from '../../../services/question/question.service';
import { useScale } from '../../../hooks/scale';
import { QuestionAnswerTypeEnum } from '../../../services';
import { EnumToDropdownModel } from '../../../helpers/utils';

export interface QuestionAppenderSubmitDto {
  questionId: number;
  answer: ScaleAssigmentDto[];
}

interface QuestionAppenderProps {
  inModal?: boolean;
  onDone(): void;
  form?: FormInstance<any>;
}

interface QuestionAppenderFormDto {
  questionPool: number;
  questionId: number;
  answer_type: QuestionAnswerTypeEnum;
}

export const QuestionAppender: React.FC<QuestionAppenderProps> = (props) => {
  const [form] = useForm();
  const store = useStore();
  const questionPoolService = useQuestionPoolService();
  const questionService = useQuestionService();
  const scaleStore = useScale();
  const [questionList, setQuestionList] = useState<QuestionPoolGetByIdDto>({
    color: '',
    id: 0,
    name: '',
    question: [],
  });

  const onSelectQuestionPool = useCallback(
    async (id: number) => {
      const res = await questionPoolService.getById(id);
      if (!res) return;
      setQuestionList(res);
    },
    [questionPoolService, setQuestionList],
  );

  const onSubmit = useCallback(
    async (data: QuestionAppenderFormDto) => {
      const res = await questionService.getById(data.questionId);
      if (!res) return;

      const parentAnswer = scaleStore.appendQuestionModal.data
        ? scaleStore.appendQuestionModal.data.answerId
        : undefined;
      const parentQuestion = scaleStore.appendQuestionModal.data
        ? scaleStore.appendQuestionModal.data.questionId
        : undefined;
      await scaleStore.addAnswers(res.answers, data.questionId);
      await scaleStore.addQuestion(data.questionId, data.answer_type, parentQuestion, parentAnswer, true);

      const save = await scaleStore.save();
      if (!save) return;
      props.onDone();
      if (props.form) {
        props.form.resetFields();
      } else {
        form.resetFields();
      }
    },
    [form, props, questionService, scaleStore],
  );

  const formItem = useCreateFormItems<QuestionAppenderFormDto>([
    {
      colProps: {
        span: 24,
      },
      element: (
        <StyledSelect
          onDropdownVisibleChange={() => store.getQuestionPoolList()}
          options={store.data.questionPoolList.map((e) => ({
            label: (
              <Tooltip title={e.name}>
                <Tag color={e.color} children={e.color} />
                <Typography.Text>{e.name}</Typography.Text>
              </Tooltip>
            ),
            value: e.id,
          }))}
          onSelect={(value) => onSelectQuestionPool(Number(value))}
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        label: 'QuestionPool',
        name: 'questionPool',
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      colProps: {
        span: 24,
      },
      element: (
        <StyledSelect
          options={questionList.question.map((e) => ({ label: e.question.text, value: e.id }))}
          showSearch
          optionFilterProp='label'
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        label: 'Question',
        name: 'questionId',
        rules: [
          {
            required: true,
          },
        ],
      },
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'answer_type',
        label: 'AnswerType',
        rules: [
          {
            required: true,
          },
        ],
      },
      element: <StyledSelect options={EnumToDropdownModel(QuestionAnswerTypeEnum)} />,
      elementType: ComponentTypes.Input,
    },
  ]);
  return (
    <>
      <Form form={props.form || form} id='questionAppenderForm' onFinish={(data) => onSubmit(data)}>
        {formItem}
        {!props.inModal && (
          <Button type='primary' htmlType='submit'>
            {t('Add')}
          </Button>
        )}
      </Form>
    </>
  );
};

import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDatePicker } from '../../components/datepicker';
import { StyledInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { EnumToDropdownModel } from '../../helpers/utils';
import { ComponentTypes, ModalData } from '../../models/system';
import { EducationEnum } from '../../services';
import { PersonCreateDto, PersonGetByIdDto, PersonTypeEnum } from '../../services/person/person';
import { usePersonService } from '../../services/person/person.service';
import dayjs from 'dayjs';

interface PersonalManagementModalProps {
  data: ModalData<number>;
  onDone(): void;
  onCancel(): void;
}

export const PersonalManagementModal: React.FC<PersonalManagementModalProps> = (props) => {
  const [, setState] = useState<PersonGetByIdDto | null>(null);
  const service = usePersonService();
  const [form] = useForm();
  const { t } = useTranslation();

  const getData = useCallback(
    async (id: number) => {
      const res = await service.getById(id);
      if (!res) return;
      setState(res);
      form.setFieldsValue({
        ...res,
        education: res.education.id,
        type: res.type.id,
        description: res.description,
      });
    },
    [service, setState, form],
  );

  useEffect(() => {
    if (!props.data.data) return;
    getData(props.data.data);
    // eslint-disable-next-line
  }, [props.data]);

  const onClear = useCallback(async () => {
    form.resetFields();
    setState(null);
  }, [form, setState]);

  const onDone = useCallback(async () => {
    onClear();
    props.onDone();
  }, [onClear, props]);

  const onCancel = useCallback(async () => {
    onClear();
    props.onCancel();
  }, [onClear, props]);

  const onSubmit = useCallback(
    async (data: any) => {
      let res: any = false;
      if (props.data.data) {
        res = await service.update(props.data.data, data);
      } else {
        res = await service.create(data);
      }

      if (!res) return;
      onDone();
    },
    [service, props, onDone],
  );

  const formItems = useCreateFormItems<PersonCreateDto>([
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'name',
        label: 'Name',
        rules: [{ required: true }],
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'lastname',
        label: 'LastName',
        rules: [{ required: true }],
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'education',
        label: 'Education',
        rules: [{ required: true }],
      },
      element: <StyledSelect options={EnumToDropdownModel(EducationEnum)} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'birthday',
        label: 'BirthDay',
        rules: [{ required: true }],
      },
      element: <IDatePicker disabledDate={(date) => date.endOf('date') > dayjs().set('year', 2005).endOf('date')} />,
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'email',
        label: 'Email',
        rules: [{ required: true }],
      },
      element: <StyledInput />,
      elementType: ComponentTypes.InputEmail,
    },
    {
      colProps: {
        span: 12,
      },
      formItem: {
        name: 'password',
        label: 'Password',
        rules: [{ required: props.data.data ? false : true, max: 20, min: 8 }],
      },
      element: <StyledInput.Password />,
      elementType: ComponentTypes.InputPassword,
    },
    {
      colProps: {
        span: 12,
      },
      element: <StyledSelect options={EnumToDropdownModel(PersonTypeEnum)} />,
      elementType: ComponentTypes.Input,
      formItem: {
        label: 'PersonalType',
        name: 'type',
      },
    },
    {
      colProps: {
        span: 24,
      },
      formItem: {
        name: 'description',
        label: 'Description',
        rules: [{ required: true }],
      },
      element: <TextArea />,
      elementType: ComponentTypes.Textarea,
    },
  ]);

  return (
    <Modal
      open={props.data.open}
      title={t(props.data.data ? 'Update' : 'New')}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: 'submit',
        form: 'PersonalManagementModalForm',
      }}>
      <Form form={form} id='PersonalManagementModalForm' onFinish={(data) => onSubmit(data)}>
        <Row gutter={24}>{formItems}</Row>
      </Form>
    </Modal>
  );
};

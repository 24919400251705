import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { QuotaCreateDto, QuotaGetByIdResDto, QuotaListResDto } from "./quota";

export const useQuotaService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<QuotaListResDto[]>('/quota')
    }, [http])

    const create = useCallback(async (req: QuotaCreateDto) => {
        return await http.post<QuotaCreateDto, WithId<QuotaCreateDto>>('/quota', req);
    }, [http])

    const update = useCallback(async (id: number, req: QuotaCreateDto) => {
        return await http.patch<QuotaCreateDto, WithId<QuotaCreateDto>>(`/quota/${id}`, req)
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/quota/${id}`);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<QuotaGetByIdResDto>(`/quota/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        deleteById,
        getById
    }
}
import { Button, Form, Modal, Row, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../components/form';
import { IDatePicker } from '../../components/datepicker';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { ScaleLinkGenerateDto } from '../../services/scale/scale';
import { useScaleLinkService } from '../../services/scale/scaleLink.service';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const { TextArea } = Input;

interface ScaleGenerateLinkModalProps {
  data: ModalData<number>;
  onDone(): void;
  onCancel(): void;
}

const ScaleLinkGenerateModal: React.FC<ScaleGenerateLinkModalProps> = (props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const service = useScaleLinkService();
  const [generatedLinksStatus, setGeneratedLinksStatus] = useState(false);
  const [generatedLinks, setGeneratedLinks] = useState<string>('');
  const [copied, setCopied] = useState(false);

  const onReset = useCallback(async () => {
    form.resetFields();
  }, [form]);

  const onDone = useCallback(async () => {
    onReset();
    props.onDone();
    setGeneratedLinksStatus(false);
  }, [onReset, props]);

  const onCancel = useCallback(async () => {
    onReset();
    props.onCancel();
    setGeneratedLinksStatus(false);
  }, [onReset, props]);

  const onSubmit = useCallback(async () => {
    let res: any | false = false;
    const valid = await form
      .validateFields()
      .then((e) => true)
      .catch((e) => false);
    if (!valid) return;
    const data = form.getFieldsValue();
    data.piece = data.piece - 1;
    data.expired_at = data.expired_at.format('YYYY-MM-DD HH:mm:ss');
    res = await service.generate(data);
    if (!res) return;
    let links = '';
    res.tokens.forEach((element: string) => {
      links += `http://web.kentmetre.com/${element}\n\n`;
    });
    setGeneratedLinksStatus(true);
    setGeneratedLinks(links);
    form.setFieldValue('links', links);
  }, [form, service]);

  useEffect(() => {
    if (props.data.data) {
      form.setFieldValue('scale', props.data.data);
    }
    // eslint-disable-next-line
  }, [props.data.data]);

  const generateFormItems = useCreateFormItems<ScaleLinkGenerateDto>([
    {
      formItem: {
        name: 'scale',
        label: '',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput type='hidden' />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'piece',
        label: 'Oluşturulacak Link Adedi',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput type='number' />,
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'expired_at',
        label: 'Link Geçerlilik Tarihi',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <IDatePicker showTime={{ format: 'HH:mm:ss' }} format='YYYY-MM-DD HH:mm:ss' />,
      elementType: ComponentTypes.Input,
    },
  ]);

  const generatedFormItems = useCreateFormItems<any>([
    {
      formItem: {
        name: 'links',
        label: 'Linkler',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <TextArea autoSize>{generatedLinks}</TextArea>,
      elementType: ComponentTypes.Textarea,
    },
  ]);

  return (
    <Modal
      afterClose={() => onDone()}
      open={props.data.open}
      title={t(props.data.data ? 'Update' : 'New')}
      onCancel={() => onCancel()}
      footer={
        !generatedLinksStatus
          ? [
              <Button key='scaleModalCancelBtn' onClick={() => onCancel()}>
                {t('Cancel')}
              </Button>,
              <Button key='scaleModalSaveBtn' type='primary' onClick={() => onSubmit()}>
                Linkleri Oluştur
              </Button>,
            ]
          : [
              <CopyToClipboard key='scaleModalCopyBtn' text={generatedLinks} onCopy={() => setCopied(true)}>
                <Button type='primary'>{copied ? 'Kopyalandı' : 'Kopyala'}</Button>
              </CopyToClipboard>,
            ]
      }
      style={{
        minWidth: '80vw',
      }}>
      {!generatedLinksStatus ? (
        <Form form={form} id='GenerateLink'>
          <Row gutter={24}>{generateFormItems}</Row>
        </Form>
      ) : (
        <Form form={form} id='GenerateLink'>
          <Row gutter={24}>{generatedFormItems}</Row>
        </Form>
      )}
    </Modal>
  );
};

export default ScaleLinkGenerateModal;

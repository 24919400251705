import { IdNameDto } from '..';

export interface PlanCreateDto {
  name: string;
  description: string;
  started_at: string;
  finished_at: string;
  principal: number;
  show_address: boolean;
  mutliple_plan: boolean;
  plan_list: string;
  city: number;
  district: number;
  neighbourhood: number;
  street: number;
  team: number;
  person: number;
  car: number;
  street_count: number;
  building_count: number;
  units_count: number;
  digits_count: number;
  business_count: number;
  duration: number;
  target_digit: number;
  target_unit: number;
  target_business: number;
  total_duration: number;
  zone: number;
  scale: number;
}

export interface FilterDto {
  text: string;
  value: string | number;
}

export interface PlanUpdateDto extends PlanCreateDto {}

export interface PlanListDto {
  id: number;
  name: string;
  building: number;
  building_count: number;
  business_count: number;
  car: { id: number; license: string };
  city: IdNameDto;
  completedBusiness: string;
  completedGlobal: string;
  completedHome: string;
  digits_count: number;
  district: IdNameDto;
  duration: number;
  finished_at: string;
  neighbourhood: IdNameDto;
  person: { id: number; car: null; name: string; lastname: string; birthday: string };
  principal: { id: number; text: string };
  scale: {
    id: number;
    name: string;
    logo?: string;
    project: { id: number; customer: number; user: number; name: string };
  };
  project: { name: string };
  factor: IdNameDto;
  started_at: string;
  street: string[];
  street_count: number;
  target_business: number;
  target_digit: number;
  total_duration: number;
  units_count: number;
  zone: number;
  show_address: boolean;
}

export enum PlanPrincipal {
  'Asil' = 1,
  'Yedek' = 2,
}

//@ts-nocheck
import {Button, Descriptions, Modal, Row, Col, Image, Card} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ModalData} from '../../models/system';
import {ScaleUidResDto} from '../../services/scale/scale';
import {useScaleService} from '../../services/scale/scale.service';
import {useConfig} from '../../hooks/config';
import {useAuth} from '../../hooks/auth';
import {OpinionResDto} from '../../services/dashboard/dashboard';

import axios from 'axios';

//import { useStore } from '../../store';

interface ScaleUidShowModalModalProps {
    data: ModalData<string>;
    onDone(): void;
    onCancel(): void;
}

export const ScaleUidShowModal: React.FC<ScaleUidShowModalModalProps> = (props) => {
    const [form] = useForm();
    const service = useScaleService();
    const {t} = useTranslation();
    const [state, setState] = useState<ScaleUidResDto[]>([]);
    const [personState, setPersonState] = useState<any>(null);
    const [opinions, setOpinions] = useState<OpinionResDto[]>([]);
    const {apiBaseUrl} = useConfig();
    const {authData} = useAuth();
    //const dashboardService = useDashboardService();

    const getData = useCallback(
        async (key: string) => {
            const res = await service.showUid(key);
            if (!res) return;
            let newRes: any = [];
            res.replies.forEach((item) => {
                const find = newRes.findIndex((x: any) => x.question.id === item.question.id);
                if (find !== -1) {
                    newRes[find].answer.answer.text = `${newRes[find].answer.answer.text}, ${item.answer.answer.text}`;
                } else {
                    newRes.push(item);
                }
            });
            setState(newRes);
            setPersonState(res.person);
        },
        [service, setState],
    );

    const getOpinions = useCallback(
        async (key: string) => {
            let res = null;
            res = await axios
                .get(`${apiBaseUrl}opinions/${key}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
                        Authorization: `${authData.token.token_type} ${authData.token.access_token}`,
                    },
                })
                .then((res) => res.data)
                .then((res) => res)
                .catch((err) => {
                    setOpinions([]);
                    return (res = null);
                });
            if (res === null) return;
            setOpinions(res);
        },
        [apiBaseUrl, authData.token.access_token, authData.token.token_type],
    );

    useEffect(() => {
        if (props.data.data) {
            getData(props.data.data);
            getOpinions(props.data.data);
        }
        // eslint-disable-next-line
    }, [props.data.data]);

    const onReset = useCallback(async () => {
        form.resetFields();
        setState([]);
    }, [form]);

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
        setOpinions([]);
    }, [onReset, props]);

    return (
        <Modal
            open={props.data.open}
            title={<div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '26px', marginBottom: '10px', textAlign: 'center', width: '100%'}}>Personel: {personState?.name} {personState?.lastname}</span>
                <span style={{marginBottom: '20px', textAlign: 'center', width: '100%'}}>Personel E-mail: {personState?.email}</span>
                <h3>Ölçek Cevapları</h3>
            </div>}
            onCancel={() => onCancel()}
            bodyStyle={{maxHeight: '600px', overflowY: 'auto'}}
            footer={[<Button onClick={() => onCancel()}>{t('Kapat')}</Button>]}
            style={{
                minWidth: '80vw',
            }}>
            {state.map((item, index) => (
                <Descriptions key={index} bordered style={{marginBottom: '25px'}}>
                    <Descriptions.Item
                        labelStyle={{color: 'blue', width: '150px'}}
                        label={`Soru ${index + 1}`}
                        style={{fontWeight: 700, marginBottom: '24px'}}
                        span={4}>
                        {item.question.question.text}
                    </Descriptions.Item>
                    <Descriptions.Item label='Cevap' span={4}>
                        {(item.text === null || item.text === '')
                            ? item.answer !== null
                                ? item.answer.answer.text
                                : ''
                            : (item.text !== null || item.text !== '')
                                ? item.text.replace('(Yazınız)', '')
                                : ''}
                    </Descriptions.Item>
                </Descriptions>
            ))}
            {opinions !== null && (
                <Row justify='center' gutter={[24, 24]} style={{marginBottom: '40px'}}>
                    <Col span={8}>
                        {opinions.suggest !== null && (
                            <Card>
                                <Card.Meta
                                    title='İstek Öneri'
                                    description={
                                        <>
                                            {opinions.suggest}
                                            {opinions?.suggestImage && (
                                                <>
                                                    <br/>
                                                    <br/>
                                                    <Image style={{borderRadius: 10}} width={150}
                                                           src={opinions?.suggestImage}/>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </Card>
                        )}
                    </Col>
                    <Col span={8}>
                        {opinions.complaint !== null && (
                            <Card>
                                <Card.Meta
                                    title='Şikayet'
                                    description={
                                        <>
                                            {opinions.complaint}
                                            {opinions?.complaintImage && (
                                                <>
                                                    <br/>
                                                    <br/>
                                                    <Image style={{borderRadius: 10}} width={150}
                                                           src={opinions?.complaintImage}/>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </Card>
                        )}
                    </Col>
                    <Col span={8}>
                        {opinions.note !== null && (
                            <Card>
                                <Card.Meta
                                    title='Not'
                                    description={
                                        <>
                                            {opinions.note}
                                            {opinions?.noteImage && (
                                                <>
                                                    <br/>
                                                    <br/>
                                                    <Image style={{borderRadius: 10}} width={150}
                                                           src={opinions?.noteImage}/>
                                                </>
                                            )}
                                        </>
                                    }
                                />
                            </Card>
                        )}
                    </Col>
                </Row>
            )}
        </Modal>
    );
};

import { useCallback } from 'react';
import { IdNameDto, NameDto } from '..';
import { useHttp } from '../../hooks/http';
import { IndependentReqDto, BuildingReqDto, DistrictCreateDto, NeighbourhoodReqDto, StreetReqDto } from './cities';

export interface BuildingDto extends IdNameDto {
  street: number;
  independent: IdNameDto[];
}

export interface StreetDto extends IdNameDto {
  neighbourhood: number;
  buildings: BuildingDto[];
}

export const useCitiesService = () => {
  const http = useHttp();

  const cities = useCallback(async () => {
    return await http.get<IdNameDto[]>('/city');
  }, [http]);

  const cityCreate = useCallback(
    async (data: NameDto) => {
      return await http.post<NameDto, IdNameDto>('/city', data);
    },
    [http],
  );

  const district = useCallback(
    async (cityId: number) => {
      return await http.get<IdNameDto[]>(`/district/${cityId}`);
    },
    [http],
  );

  const districtCreate = useCallback(
    async (data: DistrictCreateDto) => {
      return await http.post<DistrictCreateDto, IdNameDto>('/district', data);
    },
    [http],
  );

  const neighbourhood = useCallback(
    async (districtId: number) => {
      return await http.get<IdNameDto[]>(`/neighbourhood/${districtId}`);
    },
    [http],
  );

  const neighbourhoodCreate = useCallback(
    async (data: NeighbourhoodReqDto) => {
      return await http.post<NeighbourhoodReqDto, IdNameDto>('/neighbourhood', data);
    },
    [http],
  );

  const street = useCallback(
    async (neighbourhoodId: number) => {
      return await http.get<IdNameDto[]>(`/street/${neighbourhoodId}`);
    },
    [http],
  );

  const streetBuildingsCount = useCallback(
    async (streetId: number) => {
      return await http.get<StreetDto[]>(`/street/${streetId}/all`);
    },
    [http],
  );

  const streetCreate = useCallback(
    async (data: StreetReqDto) => {
      return await http.post<StreetReqDto, IdNameDto>('/street', data);
    },
    [http],
  );

  const building = useCallback(
    async (streetId: number) => {
      return await http.get<IdNameDto[]>(`/building/${streetId}`);
    },
    [http],
  );

  const buildingCreate = useCallback(
    async (data: BuildingReqDto) => {
      return await http.post<BuildingReqDto, IdNameDto>('/building', data);
    },
    [http],
  );

  const independent = useCallback(
    async (buildingId: number) => {
      return await http.get<StreetDto[]>(`/independent/${buildingId}`);
    },
    [http],
  );

  const independentCreate = useCallback(
    async (data: IndependentReqDto) => {
      return await http.post<IndependentReqDto, IdNameDto>('/independent', data);
    },
    [http],
  );

  const scaleCity = useCallback(
    async (scaleId: number) => {
      return await http.get<IdNameDto[]>(`/city?scale=${scaleId}`);
    },
    [http],
  );

  const scaleDistrict = useCallback(
    async (scaleId: number, cityId: number) => {
      return await http.get<IdNameDto[]>(`/district?scale=${scaleId}&city=${cityId}`);
    },
    [http],
  );

  const scaleNeighbourhood = useCallback(
    async (scaleId: number, districtId: number) => {
      return await http.get<IdNameDto[]>(`/neighbourhood?scale=${scaleId}&district=${districtId}`);
    },
    [http],
  );

  const scaleStreet = useCallback(
    async (scaleId: number, neighbourhoodId: number) => {
      return await http.get<IdNameDto[]>(`/street?scale=${scaleId}&neighbourhood=${neighbourhoodId}`);
    },
    [http],
  );

  const scaleBuilding = useCallback(
    async (scaleId: number, streetId: number) => {
      return await http.get<IdNameDto[]>(`/street?scale=${scaleId}&street=${streetId}`);
    },
    [http],
  );

  return {
    cities,
    cityCreate,
    district,
    districtCreate,
    neighbourhood,
    neighbourhoodCreate,
    street,
    streetCreate,
    streetBuildingsCount,
    building,
    buildingCreate,
    independent,
    independentCreate,
    scaleCity,
    scaleDistrict,
    scaleNeighbourhood,
    scaleStreet,
    scaleBuilding,
  };
};

export interface IdNameDto {
    id: number;
    name: string;
}

export type WithId<T> = T & {
    id: number;
}

export interface BaseEntityDto {
    created_at: string;
}

export interface NameDto {
    name: string;
}

export enum QuestionAnswerTypeEnum {
    QuestionManyToMany = 1,
    QuestionManyToOne = 2
}

export enum QuestionTypeEnum {
    TextQuestion = 1,
    ImageQuestion = 2
}

export enum AnswerTypeEnum {
    AnswerTypeFile = 'file',
    AnswerTypeText = 'text',
    AnswerTypeImage = 'image',
    AnswerTypeLongText = 'longText',
    AnswerTypeNumber = 'number'
}


export enum EducationEnum {
    'Okur Yazar' = 1,
    'Okur Yazar Fakat Okul Bitirmedi' = 2,
    'İlkokul Mezunu' = 3,
    'İlköğretim Mezunu' = 4,
    'Orta Okul veya Dengi Mezunu' = 5,
    'Lise veya Dengi Mezunu' = 6,
    'Yüksek Okul veya Fakülte Mezunu' = 7,
    'Yüksek Lisans Mezunu' = 8,
    'Doktora Mezunu' = 9,
}
import { createContext, useCallback, useState } from 'react';
import { ModalData, ModalDataModel } from '../models/system';
import { QuestionAnswersDto } from '../services/question/question';
import {
  ScaleAssigmentDto,
  ScaleQuestionGetDto,
  ScaleQuestionGetQuestionDto,
  ScaleQuestionSaveDto,
} from '../services/scaleQuestion/scaleQuestion';
import { useScaleQuestionService } from '../services/scaleQuestion/scaleQuestion.service';
import { ScaleQuestionSaveAnswerDto } from '../services/scaleQuestion/scaleQuestion';
import { QuestionAnswerTypeEnum } from '../services';

interface ScaleContextType {
  data: ScaleQuestionSaveDto;
  createSaveData(datas: ScaleQuestionGetDto[], scaleId: number): Promise<void>;
  addQuestion(
    questionId: number,
    answerType?: QuestionAnswerTypeEnum,
    parentQuestionId?: number,
    parentAnswerId?: number,
    hasSave?: boolean,
  ): Promise<boolean>;
  updateAnswer(id: number, value: any, propName: Extract<keyof ScaleQuestionSaveAnswerDto, string>): void;
  save(): Promise<boolean>;
  removeQuestion(questionId: number): Promise<boolean>;
  appendQuestionModal: ModalData<{ answerId?: number; questionId?: number }>;
  toggleAppendQuestionModal(data?: { answerId?: number; questionId?: number } | null): void;
  addAnswers(data: QuestionAnswersDto[], questionId: number): Promise<void>;
  init(): void;
  updateQuestionSort(id: number, sort: number): void;
}

const ScaleContextInitData: ScaleContextType = {
  data: {
    answers: [],
    questions: [],
    scale: 0,
  },
  createSaveData: async () => {},
  addQuestion: async () => true,
  updateAnswer: () => {},
  save: async () => true,
  removeQuestion: async () => true,
  appendQuestionModal: ModalDataModel(),
  toggleAppendQuestionModal: () => {},
  addAnswers: async () => {},
  init: () => {},
  updateQuestionSort: () => {},
};

export const ScaleContext = createContext<ScaleContextType>(ScaleContextInitData);

export const ScaleProvider: React.FC = (props) => {
  const scaleQuestionService = useScaleQuestionService();
  const [state, setState] = useState<ScaleQuestionSaveDto>(ScaleContextInitData.data);
  const [appendModal, setAppendModal] = useState<ModalData<{ answerId?: number; questionId?: number }>>(
    ModalDataModel(),
  );

  const delay = useCallback(async (time: number = 200) => {
    return await new Promise((r) => setTimeout(() => r(true), time));
  }, []);

  const createQuestion = useCallback(
    async (
      data: ScaleQuestionGetQuestionDto,
      answerType?: number,
      id?: number,
      parentId?: number,
      parentAnswer?: number,
    ) => {
      const questionId = id || data.id;
      setState((p) => {
        if (!p.questions.find((q) => q.questionId === questionId)) {
          p.questions.push({
            questionId: questionId,
            parentAnswer: parentAnswer || null,
            parentQuestionId: parentId || null,
            answerType: answerType || null,
            sort: data.sort,
          });
        }

        if (data.assigment) {
          data.assigment.map((e) => createAnswer(e));
        }

        if (data.parent) {
          data.parent.question.map((e) =>
            createQuestion(e, data.parent ? Number(data.parent.answer_type.id) : undefined, undefined, data.id),
          );
        }

        return p;
      });
      await delay();
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState],
  );

  const createAnswer = useCallback(
    async (data: ScaleAssigmentDto) => {
      const answerId = data.answer.id;
      setState((p) => {
        if (!p.answers.find((a) => a.id === answerId)) {
          p.answers.push({
            closed: data.closed,
            factor: data.factor,
            id: data.answer.id,
            point: data.point,
            questionId: data.question,
            sort: data.sort,
            status: data.status,
            text: data.text,
            subQuestion: data.realeted ? data.realeted.id : null,
            answerType: Number(data.answer.type.id),
          });
        }
        if (data.realeted) {
          createQuestion(data.realeted, undefined, undefined, undefined, data.answer.id);
        }

        return p;
      });
      await delay();
    },
    [setState, createQuestion, delay],
  );

  const createSaveData = useCallback(
    async (datas: ScaleQuestionGetDto[], scaleId: number): Promise<void> => {
      setState(ScaleContextInitData.data);
      await delay();
      setState((p) => {
        p.scale = scaleId;
        return p;
      });
      await delay();
      datas.forEach(async (data) => {
        await data.question.forEach(async (e) => {
          await createQuestion({ ...e, sort: data.sort }, Number(data.answer_type.id));
        });
      });
      await delay();
      // await new Promise(r => {
      //     setState(ScaleContextInitData.data);
      //     setTimeout(() => r(true), 500)
      // })
      // await new Promise(r => {
      //     setState(p => { p.scale = scaleId; return p })
      //     r(true)
      // });
      // await new Promise(r => {
      //     datas.forEach(data => {
      //         data.question.forEach(e => {
      //             createQuestion(e, Number(data.answer_type.id))
      //         })
      //     })
      //     setTimeout(() => r(true), 200)
      // });
    },
    [createQuestion, delay],
  );

  const save = useCallback(async () => {
    if (state.scale === 0) return false;
    const res = await scaleQuestionService.save(state);
    if (!res) return false;
    setState((p) => ScaleContextInitData.data);
    await delay();
    // createSaveData(res, state.scale);
    return res ? true : false;
  }, [scaleQuestionService, state, setState, delay]);

  const addQuestion = useCallback(
    async (
      questionId: number,
      answerType?: QuestionAnswerTypeEnum,
      parentQuestionId?: number,
      parentAnswerId?: number,
      hasSave?: boolean,
    ) => {
      await new Promise((r) => {
        setState((p) => {
          if (p.questions.find((q) => q.questionId === questionId)) return p;
          p.questions.push({
            questionId: questionId,
            parentAnswer: parentAnswerId || null,
            parentQuestionId: parentQuestionId || null,
            answerType: parentAnswerId ? null : answerType ? answerType : null,
            sort: 1,
          });

          if (parentAnswerId) {
            updateAnswer(parentAnswerId, questionId, 'subQuestion');
            updateAnswer(parentAnswerId, answerType, 'answerType');
          }

          return p;
        });

        setTimeout(() => r(true), 200);
      });
      if (!hasSave) {
        return await save();
      }
      return true;
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save, setState],
  );

  const addAnswers = useCallback(async (data: QuestionAnswersDto[], questionId: number) => {
    await new Promise((r) => {
      data.forEach((answer) => {
        setState((p) => {
          if (!p.answers.find((e) => e.id === answer.id)) {
            p.answers.push({
              closed: false,
              factor: answer.factor,
              id: answer.id,
              point: answer.point,
              questionId: questionId,
              sort: 1,
              status: true,
              text: false,
              subQuestion: null,
              answerType: null,
            });
          }
          return p;
        });
      });

      setTimeout(() => r(true), 200);
    });
  }, []);

  const removeQuestion = useCallback(
    async (questionId: number) => {
      await new Promise((r) => {
        setState((p) => {
          const questions = [...p.questions.filter((q) => q.questionId !== questionId)];
          const answers = [
            ...p.answers
              .filter((a) => a.questionId !== questionId)
              .map((answer) => {
                if (answer.subQuestion === questionId) {
                  answer.subQuestion = null;
                }
                return answer;
              }),
          ];
          p.answers = answers;
          p.questions = questions;
          return p;
        });
        setTimeout(() => r(true), 200);
      });

      return await save();
    },
    [save],
  );

  const updateAnswer = useCallback(
    async (id: number, value: any, propName: Extract<keyof ScaleQuestionSaveAnswerDto, string>) => {
      setState((p) => {
        const answers = [...p.answers];
        answers.forEach((answer) => {
          if (answer.id === id) {
            (answer as any)[propName] = value;
          }
        });
        p.answers = [...answers];

        return { ...p };
      });
    },
    [],
  );

  const toggleAppendQuestionModal = useCallback((data: { answerId?: number; questionId?: number } | null = null) => {
    setAppendModal((p) => ({
      data: data,
      open: !p.open,
    }));
  }, []);

  const init = useCallback(async () => {
    return await new Promise((r) => {
      setState((p) => ({ answers: [], questions: [], scale: 0 }));
      r(true);
    });
  }, [setState]);

  const updateQuestionSort = useCallback(async (id: number, sort: number) => {
    await new Promise((r) => {
      setState((p) => {
        p.questions.forEach((q) => {
          if (q.questionId === id) {
            q.sort = sort;
          }
        });
        return p;
      });
      setTimeout(() => r(true), 200);
    });
  }, []);

  return (
    <ScaleContext.Provider
      value={{
        data: state,
        save,
        init,
        addAnswers,
        addQuestion,
        updateAnswer,
        removeQuestion,
        createSaveData,
        updateQuestionSort,
        toggleAppendQuestionModal,
        appendQuestionModal: appendModal,
      }}>
      {props.children}
    </ScaleContext.Provider>
  );
};

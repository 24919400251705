import {Card, Col, Collapse, Row, Tooltip} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ScaleAssigmentDto, ScaleQuestionGetDto} from '../../services/scaleQuestion/scaleQuestion';
import {Chart} from './chart';

interface ReportQuestionProps {
    data: ScaleQuestionGetDto;
    question_id: number;
    scale_id: number;
}

export const ReportQuestion: React.FC<ReportQuestionProps> = (props) => {
    const {question} = props.data;
    const {t} = useTranslation();
    return (
        <Card size='small' title={<Tooltip title={question[0].question?.text}>{question[0].question?.text}</Tooltip>}>
            <Chart data={question[0].assigment as ScaleAssigmentDto[]} question_id={props.question_id} scale_id={props.scale_id}/>
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    {question[0].parent && (
                        <Collapse>
                            <Collapse.Panel header={t('ReleatedQuestion')} key='ReleatedQuestion'>
                                <ReportQuestion data={question[0].parent} question_id={props.question_id} scale_id={props.scale_id}/>
                            </Collapse.Panel>
                        </Collapse>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

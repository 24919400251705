import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AuthUpdateResDto } from './services/auth/auth';
import { IdNameDto } from './services';
import { useCarService } from './services/car/car.service';
import { CarListDto } from './services/car/car';
import { useCitiesService } from './services/cities/cities.service';
import { CustomerListResDto } from './services/customer/customer';
import { useCustomerService } from './services/customer/customer.service';
import { usePermissionService } from './services/permission/permission.service';
import { usePersonService } from './services/person/person.service';
import { PersonListDto } from './services/person/person';
import { ProjectGetListDto } from './services/project/project';
import { useProjectService } from './services/project/project.service';
import { useQuestionFactorService } from './services/questionFactor/questionFactor';
import { QuestionPoolDto } from './services/questionPool/questionPool';
import { useQuestionPoolService } from './services/questionPool/questionPool.service';
import { QuotaListResDto } from './services/quota/quota';
import { useQuotaService } from './services/quota/quota.service';
import { useRoleService } from './services/role/role.service';
import { ScaleListResDto } from './services/scale/scale';
import { useScaleService } from './services/scale/scale.service';
import { useScaleFactorService } from './services/scaleFactor/scaleFactor.service';
import { useScaleJobService } from './services/scaleJob/scaleJob.service';
import { InstitutionListDto } from './services/institution/institution';
import { useInstitutionService } from './services/institution/institution.service';
import { useAuthService } from './services/auth/auth.service';

export interface BuildingDto extends IdNameDto {
  street: number;
  independent: IdNameDto[];
}

export interface StreetDto extends IdNameDto {
  neighbourhood: number;
  buildings: BuildingDto[];
}

interface StoreDataType {
  authMe: AuthUpdateResDto;
  userPermissions: string[];
  roleList: IdNameDto[];
  cityList: IdNameDto[];
  district: IdNameDto[];
  customerList: CustomerListResDto[];
  projectList: ProjectGetListDto[];
  neighbourhoodList: IdNameDto[];
  questionPoolList: QuestionPoolDto[];
  quotaList: QuotaListResDto[];
  scaleFactorList: IdNameDto[];
  scaleJobList: IdNameDto[];
  scaleList: ScaleListResDto[];
  questionFactorList: IdNameDto[];
  permissionList: string[];
  personList: PersonListDto[];
  carList: CarListDto[];
  streetList: IdNameDto[];
  streetBuildingList: StreetDto[];
  buildingList: IdNameDto[];
  independentList: IdNameDto[];
  institutionList: InstitutionListDto[];
}

const storeInitialData: StoreDataType = {
  authMe: {} as AuthUpdateResDto,
  userPermissions: [],
  roleList: [],
  cityList: [],
  permissionList: [],
  questionFactorList: [],
  district: [],
  customerList: [],
  projectList: [],
  neighbourhoodList: [],
  questionPoolList: [],
  quotaList: [],
  scaleFactorList: [],
  scaleJobList: [],
  scaleList: [],
  carList: [],
  personList: [],
  streetList: [],
  streetBuildingList: [],
  buildingList: [],
  independentList: [],
  institutionList: [],
};

interface StoreType {
  data: StoreDataType;
  getAuthMe: () => void;
  getUserPermissions: (role_id: number) => void;
  setAuthMe: (authMe: AuthUpdateResDto) => void;
  setUserPermissions: (permissions: string[]) => void;
  getRoleList(): void;
  getProjectList(): void;
  getCityList(): void;
  getDistrict(cityId: number): void;
  getCustomers(): void;
  getNeighbourhood(districtId: number): void;
  getQuestionPoolList(): void;
  getQuotaList(): void;
  getScaleFactorList(): void;
  getScaleJobList(): void;
  getScaleList(): void;
  getQuestionFactors(): void;
  getAllPermissions(): void;
  getAllPerson(): void;
  getAllCars(): void;
  getStreetList(id: number): void;
  getStreetBuildingList(id: number): void;
  getBuildingList(id: number): void;
  getIndependentList(id: number): void;
  getAllInstitution(): void;
}

export const StoreContext = createContext<StoreType>({
  data: storeInitialData,
  getAuthMe: () => {},
  getUserPermissions: () => {},
  setAuthMe: () => {},
  setUserPermissions: () => {},
  getCityList: () => {},
  getDistrict: (cityId: number) => {},
  getProjectList: () => {},
  getRoleList: () => {},
  getCustomers: () => {},
  getNeighbourhood: () => {},
  getQuestionPoolList: () => {},
  getQuotaList: () => {},
  getScaleFactorList: () => {},
  getScaleJobList: () => {},
  getScaleList: () => {},
  getQuestionFactors: () => {},
  getAllPermissions: () => {},
  getAllPerson: () => {},
  getAllCars: () => {},
  getStreetList: () => {},
  getStreetBuildingList: () => {},
  getBuildingList: () => {},
  getIndependentList: () => {},
  getAllInstitution: () => {},
});

interface StoreProviderProps {
  children: React.ReactNode;
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
}

export const StoreProvider: React.FC<StoreProviderProps> = ({ children, permissions, setPermissions }) => {
  const [state, setState] = useState<StoreDataType>(storeInitialData);
  const roleService = useRoleService();
  const cityService = useCitiesService();
  const customerService = useCustomerService();
  const projectService = useProjectService();
  const questionPoolService = useQuestionPoolService();
  const quotaService = useQuotaService();
  const scaleFactorService = useScaleFactorService();
  const scaleJobService = useScaleJobService();
  const scaleService = useScaleService();
  const questionFactorService = useQuestionFactorService();
  const permissionService = usePermissionService();
  const personService = usePersonService();
  const carSerivce = useCarService();
  const institutionService = useInstitutionService();
  const authService = useAuthService();

  useEffect(() => {
    const permissionEngine = async () => {
      //console.log(permissions);
      if (permissions.length > 0) return;
      const getUserAuthMeData = await authService.me();
      if (Object.keys(getUserAuthMeData).length === 0) return;
      if (!getUserAuthMeData) return;
      setState((p) => ({ ...p, authMe: getUserAuthMeData }));
      const getUserPermissions = await permissionService.show(getUserAuthMeData.role);
      if (!getUserPermissions) return;
      setState((p) => ({ ...p, userPermissions: getUserPermissions.permission }));
      setPermissions(getUserPermissions.permission);
    };
    permissionEngine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  const getAuthMe = useCallback(async () => {
    const res = await authService.me();
    if (!res && res !== null) return;
    setState((p) => ({ ...p, authMe: res }));
  }, [authService, setState]);

  const getUserPermissions = useCallback(
    async (role_id: number) => {
      if (state.userPermissions.length) return;
      const res = await permissionService.show(role_id);
      if (!res) return;
      setState((p) => ({ ...p, userPermissions: res.permission }));
    },
    [permissionService, setState, state],
  );

  const setAuthMe = useCallback((authMe: AuthUpdateResDto) => {
    setState((p) => ({ ...p, authMe }));
  }, []);

  const setUserPermissions = useCallback(
    (permissions: string[]) => {
      setState((p) => ({ ...p, userPermissions: permissions }));
      setPermissions(permissions);
    },
    [setPermissions],
  );

  const getAllPerson = useCallback(async () => {
    if (state.personList.length) return;
    const res = await personService.list();
    if (!res) return;
    setState((p) => ({ ...p, personList: res }));
  }, [personService, setState, state]);

  const getAllCars = useCallback(async () => {
    if (state.carList.length) return;
    const res = await carSerivce.list();
    if (!res) return;
    setState((p) => ({ ...p, carList: res }));
  }, [carSerivce, setState, state]);

  const getRoleList = useCallback(async () => {
    if (state.roleList.length) return;
    const res = await roleService.list();
    if (!res) return;
    setState((p) => ({ ...p, roleList: res }));
  }, [setState, roleService, state]);

  const getProjectList = useCallback(async () => {
    if (state.projectList.length) return;
    const res = await projectService.list();
    if (!res) return;
    setState((p) => ({ ...p, projectList: res }));
  }, [projectService, setState, state]);

  const getCityList = useCallback(async () => {
    if (state.cityList.length) return;
    const res = await cityService.cities();
    if (!res) return;
    setState((p) => ({
      ...p,
      cityList: res.map((e) => {
        e.name = e.name.toUpperCase();
        return e;
      }),
    }));
  }, [setState, cityService, state]);

  const getDistrict = useCallback(
    async (cityId: number) => {
      const res = await cityService.district(cityId);
      if (!res) return;
      setState((p) => ({
        ...p,
        district: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
      return res;
    },
    [cityService, setState],
  );

  const getCustomers = useCallback(async () => {
    if (state.customerList.length) return;
    const res = await customerService.list();
    if (!res) return;
    setState((p) => ({ ...p, customerList: res }));
  }, [customerService, setState, state]);

  const getNeighbourhood = useCallback(
    async (districtId: number) => {
      const res = await cityService.neighbourhood(districtId);
      if (!res) return;
      setState((p) => ({
        ...p,
        neighbourhoodList: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
    },
    [cityService, setState],
  );

  const getQuestionPoolList = useCallback(async () => {
    if (state.questionPoolList.length) return;
    const res = await questionPoolService.list();
    if (!res) return;
    setState((p) => ({ ...p, questionPoolList: res }));
  }, [setState, questionPoolService, state.questionPoolList.length]);

  const getQuotaList = useCallback(async () => {
    if (state.quotaList.length) return;
    const res = await quotaService.list();
    if (!res) return;
    setState((p) => ({ ...p, quotaList: res }));
  }, [setState, quotaService, state.quotaList.length]);

  const getScaleFactorList = useCallback(async () => {
    if (state.scaleFactorList.length) return;
    const res = await scaleFactorService.list();
    if (!res) return;
    setState((p) => ({ ...p, scaleFactorList: res }));
  }, [scaleFactorService, setState, state]);

  const getScaleJobList = useCallback(async () => {
    if (state.scaleJobList.length) return;
    const res = await scaleJobService.list();
    if (!res) return;
    setState((p) => ({ ...p, scaleJobList: res }));
  }, [setState, state, scaleJobService]);

  const getScaleList = useCallback(async () => {
    const res = await scaleService.list();
    if (!res) return;
    setState((p) => ({ ...p, scaleList: res }));
  }, [scaleService, setState]);

  const getQuestionFactors = useCallback(async () => {
    // if (state.questionFactorList.length) return;
    const res = await questionFactorService.list();
    if (!res) return;
    setState((p) => ({ ...p, questionFactorList: res }));
  }, [questionFactorService, setState]);

  const getAllPermissions = useCallback(async () => {
    if (state.permissionList.length) return;
    const res = await permissionService.list();
    if (!res) return;
    setState((p) => ({ ...p, permissionList: res }));
  }, [setState, permissionService, state]);

  const getStreetList = useCallback(
    async (neighborhoodId: number) => {
      const res = await cityService.street(neighborhoodId);
      if (!res) return;
      setState((p) => ({
        ...p,
        streetList: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
    },
    [setState, cityService],
  );

  const getStreetBuildingList = useCallback(
    async (streetId: number) => {
      const res = await cityService.streetBuildingsCount(streetId);
      if (!res) return;
      setState((p) => ({
        ...p,
        streetBuildingList: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
      return res;
    },
    [setState, cityService],
  );

  const getBuildingList = useCallback(
    async (streetId: number) => {
      const res = await cityService.building(streetId);
      if (!res) return;
      setState((p) => ({
        ...p,
        buildingList: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
      return res;
    },
    [setState, cityService],
  );

  const getIndependentList = useCallback(
    async (BuildingId: number) => {
      const res = await cityService.independent(BuildingId);
      if (!res) return;
      setState((p) => ({
        ...p,
        independentList: res.map((e) => {
          e.name = e.name.toUpperCase();
          return e;
        }),
      }));
    },
    [setState, cityService],
  );

  const getAllInstitution = useCallback(async () => {
    const res = await institutionService.list();
    if (!res) return;
    setState((p) => ({ ...p, institutionList: res }));
  }, [institutionService, setState]);

  return (
    <StoreContext.Provider
      value={{
        data: state,
        getAuthMe,
        getUserPermissions,
        setAuthMe,
        setUserPermissions,
        getRoleList,
        getStreetList,
        getStreetBuildingList,
        getBuildingList,
        getIndependentList,
        getAllInstitution,
        getProjectList,
        getCityList,
        getDistrict,
        getCustomers,
        getNeighbourhood,
        getQuestionPoolList,
        getQuotaList,
        getScaleFactorList,
        getScaleJobList,
        getScaleList,
        getQuestionFactors,
        getAllPermissions,
        getAllPerson,
        getAllCars,
      }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(StoreContext);

  return {
    ...store,
  };
};

import { CloudUploadOutlined } from '@ant-design/icons';
import { message, Upload, UploadProps } from 'antd';
import React, { useCallback } from 'react';

interface ImageUploadProps extends UploadProps {
    value?: string;
    showStockSelect?: boolean;
}


export const ImageUpload: React.FC<ImageUploadProps> = props => {


    const getBase64 = useCallback((img: any, callback: (base64: any) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }, [])

    const beforeUpload = useCallback((file: any) => {
        const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg';
        if (!isJpgOrPng) {
            message.error('Sadece PNG formatında yükleme yapınız.');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Maksimum 2MB boyutunda fotoğraf ekleyiniz.');
        }
        return isJpgOrPng && isLt2M;
    }, [])

    const handleChange = useCallback((info: any) => {
        if (info.file.status === 'uploading') {
            // this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, base64 => {
                props.onChange && props.onChange(base64 as any);
            });
        }
    }, [getBase64, props])

    const customAction = useCallback(async ({ onSuccess }: any): Promise<any> => {
        setTimeout(() => {
            onSuccess("ok");
        }, 500);
    }, []);

    return (
        <>
            <Upload
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={e => customAction(e)}
                beforeUpload={beforeUpload.bind(this)}
                onChange={handleChange.bind(this)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CloudUploadOutlined style={{ fontSize: '22px', zIndex: 3, padding: '10px', color: 'white', background: 'rgba(0,0,0,0.5)', position: 'absolute' }} />
                {props.value && typeof props.value === 'string' && <img width={"80%"} src={props.value} alt="" />}
            </Upload>
        </>
    )
}
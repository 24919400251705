import { Col, Form, Modal, Row, Tabs } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { ImageUpload } from '../../components/imageUpload';
import { ObjectNestedAssign } from '../../helpers/utils';
import { ModalData } from '../../models/system';
import { WithId } from '../../services';
import { CustomerCreateDto, CustomerIdDto, CustomerUpdateReqDto } from '../../services/customer/customer';
import { useCustomerService } from '../../services/customer/customer.service';
import { useStore } from '../../store';

interface CustomerManagementModalProps {
  data: ModalData<number>;
  onDone(): void;
  onCancel(): void;
}

export const CustomerManagementModal: React.FC<CustomerManagementModalProps> = (props) => {
  const [form] = useForm();
  const service = useCustomerService();
  const { t } = useTranslation();
  const [state, setState] = useState<WithId<CustomerIdDto> | null>(null);
  const [formData, setFormData] = useState<Partial<CustomerUpdateReqDto>>({});
  const store = useStore();
  const [selectedIns, setSelectedIns] = useState<number>(0);

  const getData = useCallback(
    async (id: number) => {
      const res = await service.getById(id);
      if (!res) return;
      form.setFieldsValue({
        ...res,
        institution: res.institution.id,
      });

      if (res.institution.id) {
        store.getAllInstitution();
        setSelectedIns(res.institution.id);
      }
      setState(res);
    },
    [form, setState, service, setSelectedIns, store],
  );

  useEffect(() => {
    if (props.data.data && !state) {
      getData(props.data.data);
    }
    // eslint-disable-next-line
  }, [props.data.data]);

  const onReset = useCallback(async () => {
    form.resetFields();
    setState(null);
    setSelectedIns(0);
    setFormData({});
  }, [form, setState, setFormData]);

  const onDone = useCallback(async () => {
    onReset();
    props.onDone();
  }, [onReset, props]);

  const onCancel = useCallback(async () => {
    onReset();
    props.onCancel();
  }, [onReset, props]);

  const onSubmit = useCallback(
    async (data: CustomerUpdateReqDto) => {
      let res: WithId<CustomerCreateDto> | false = false;
      if (props.data.data) {
        res = await service.update(props.data.data, formData);
      } else {
        if (!data.customer) {
          data.information.customer = data.information.manager;
        }
        res = await service.create(data);
      }

      if (!res) return;
      onDone();
    },
    [onDone, service, props.data.data, formData],
  );

  return (
    <Modal
      open={props.data.open}
      width={'50vw'}
      title={t(props.data.data ? 'Update' : 'New')}
      onCancel={() => onCancel()}
      okButtonProps={{
        htmlType: 'submit',
        form: 'UserManagementForm',
      }}>
      <Form
        form={form}
        onValuesChange={(e) => setFormData((p) => ObjectNestedAssign(Object.create(p), e))}
        id='UserManagementForm'
        onFinish={(data) => onSubmit(data)}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              rules={[{ required: true }]}
              name={['logo']}
              label='Logo'
              labelCol={{
                span: 24,
              }}>
              <ImageUpload />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[{ required: true }]}
              name={'institution'}
              label={t('Institution')}
              labelCol={{
                span: 24,
              }}>
              <StyledSelect
                onSelect={(v) => {
                  setSelectedIns(Number(v));
                  setFormData((p) => ({ ...p, institution: Number(v) }));
                  // form.resetFields(['sector'])
                }}
                onDropdownVisibleChange={() => store.getAllInstitution()}
                options={store.data.institutionList.map((e) => ({ label: e.name, value: e.id }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={['sector']}
              label={t('Sector')}
              labelCol={{
                span: 24,
              }}>
              <StyledSelect
                onSelect={(v) => {
                  setFormData((p) => ({ ...p, sector: Number(v) }));
                }}
                options={store.data.institutionList
                  .find((e) => e.id === selectedIns)
                  ?.sector.map((e) => ({ label: e.name, value: e.id }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Tabs
          defaultActiveKey='manager'
          items={[
            {
              label: t('Manager'),
              key: 'manager',
              children: (
                <>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Name')}
                        name={['information', 'manager', 'name']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Email')}
                        name={['information', 'manager', 'email']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[
                          { required: true },
                          {
                            validator: (r, v, c) => {
                              c(String(v).length < 11 ? ' ' : undefined);
                            },
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        label={t('PhoneNumber')}
                        name={['information', 'manager', 'phone']}>
                        <StyledInput pattern='[0-9]*' />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Address')}
                        name={['information', 'manager', 'address']}>
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              label: t('Customer'),
              key: 'customer',
              children: (
                <>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Name')}
                        name={['information', 'customer', 'name']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Email')}
                        name={['information', 'customer', 'email']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[
                          { required: true },
                          {
                            validator: (r, v, c) => {
                              c(String(v).length < 11 ? ' ' : undefined);
                            },
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        label={t('PhoneNumber')}
                        name={['information', 'customer', 'phone']}>
                        <StyledInput pattern='[0-9]*' />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Address')}
                        name={['information', 'customer', 'address']}>
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
            {
              label: t('ContactManager'),
              key: 'contactManager',
              children: (
                <>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Name')}
                        name={['information', 'contactManager', 'name']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Email')}
                        name={['information', 'contactManager', 'email']}>
                        <StyledInput />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        rules={[
                          { required: true },
                          {
                            validator: (r, v, c) => {
                              c(String(v).length < 11 ? ' ' : undefined);
                            },
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        label={t('PhoneNumber')}
                        name={['information', 'contactManager', 'phone']}>
                        <StyledInput pattern='[0-9]*' />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        rules={[{ required: true }]}
                        labelCol={{ span: 24 }}
                        label={t('Address')}
                        name={['information', 'contactManager', 'address']}>
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ),
            },
          ]}
        />
      </Form>
    </Modal>
  );
};

import { useCallback } from "react";
import { useHttp } from "../../hooks/http"
import { InstitutionListDto } from "./institution";

export const useInstitutionService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<InstitutionListDto[]>('/institution');
    }, [http]);

    return { list }
}
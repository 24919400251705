import React, { useCallback } from 'react';
import { PageCard } from '../../components/pageCard';
import { Button, Col, Form, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { StyledInput, StyledSelect } from '../../components/form';
import { ComponentTypes } from '../../models/system';
import { useCreateFormItems } from '../../helpers/form';
import { t } from 'i18next';
import { IdNameDto } from '../../services';
import { StreetCreateDto } from '../../services/cities/cities';
import { useCitiesService } from '../../services/cities/cities.service';
import { useStore } from '../../store';

export const Street: React.FC = (props) => {
  const [form] = useForm();
  const service = useCitiesService();
  const [messageApi, contextHolder] = message.useMessage();
  const store = useStore();

  const formItems = useCreateFormItems<any>([
    {
      formItem: {
        name: 'city',
        label: 'City',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 8,
      },
      element: (
        <StyledSelect
          onDropdownVisibleChange={() => store.getCityList()}
          options={store.data.cityList.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => store.getDistrict(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'district',
        label: 'District',
        required: true,
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.district.map((e) => ({ label: e.name, value: e.id }))}
          onSelect={(v) => store.getNeighbourhood(Number(v))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      colProps: {
        span: 8,
      },
      formItem: {
        name: 'neighbourhood',
        label: 'Neighbourhood',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      element: (
        <StyledSelect
          showSearch
          optionFilterProp='label'
          options={store.data.neighbourhoodList.map((e) => ({ label: e.name, value: e.id }))}
        />
      ),
      elementType: ComponentTypes.Input,
    },
    {
      formItem: {
        name: 'name',
        label: 'Street',
        required: true,
        rules: [
          {
            required: true,
          },
        ],
      },
      colProps: {
        span: 24,
      },
      element: <StyledInput />,
      elementType: ComponentTypes.Input,
    },
  ]);

  const success = useCallback(
    (neighbourhood: IdNameDto) => {
      messageApi.open({
        type: 'success',
        content: `${neighbourhood.name} ilçesi başarıyla eklendi.`,
      });
    },
    [messageApi],
  );

  const onReset = useCallback(async () => {
    form.resetFields();
  }, [form]);

  const onDone = useCallback(async () => {
    onReset();
  }, [onReset]);

  const onSubmit = useCallback(
    async (data: StreetCreateDto) => {
      const { neighbourhood, name } = data;
      const res = await service.streetCreate({ neighbourhood: neighbourhood, name: name });
      if (!res) return;
      success(res);
      onDone();
    },
    [onDone, service, success],
  );

  return (
    <>
      {contextHolder}
      <PageCard>
        <Form form={form} id='CityAdd' onFinish={(data) => onSubmit(data)}>
          <Row gutter={24}>{formItems}</Row>
          <Row gutter={24} style={{ justifyContent: 'end' }}>
            <Col span={6} style={{ marginTop: '40px' }}>
              <Button block htmlType='submit' type='primary'>
                {t('Save')}
              </Button>
            </Col>
          </Row>
        </Form>
      </PageCard>
    </>
  );
};

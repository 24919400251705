import { useCallback } from "react";
import { WithId } from "..";
import { useHttp } from "../../hooks/http"
import { CustomerCreateDto, CustomerIdDto, CustomerListResDto, CustomerUpdateReqDto } from "./customer";

export const useCustomerService = () => {
    const http = useHttp();

    const list = useCallback(async () => {
        return await http.get<CustomerListResDto[]>('/customer');
    }, [http])

    const create = useCallback(async (req: CustomerCreateDto) => {
        return await http.post<CustomerCreateDto, WithId<CustomerCreateDto>>('/customer', req);
    }, [http])

    const update = useCallback(async (id: number, req: Partial<CustomerUpdateReqDto>) => {
        return await http.patch<Partial<CustomerUpdateReqDto>, WithId<CustomerUpdateReqDto>>(`/customer/${id}`, req);
    }, [http])

    const getById = useCallback(async (id: number) => {
        return await http.get<WithId<CustomerIdDto>>(`/customer/${id}`);
    }, [http])

    const deleteById = useCallback(async (id: number) => {
        return await http.delete(`/customer/${id}`);
    }, [http])

    return {
        list,
        create,
        update,
        getById,
        deleteById
    }
}
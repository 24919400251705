import { Button, Layout, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NotAuthProps {}

export const NotAuth: React.FC<NotAuthProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Layout style={{ height: '100vh' }}>
      <Result
        status={'403'}
        title='Not Found'
        subTitle='Sorry, the page you visited does not exist.'
        extra={<Button onClick={() => navigate('/')}>Home</Button>}
      />
    </Layout>
  );
};

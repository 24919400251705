import {
  AppstoreOutlined,
  BarChartOutlined,
  BranchesOutlined,
  CarOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileOutlined,
  ForkOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  TeamOutlined,
  UserOutlined,
  FileTextOutlined,
  EnvironmentOutlined,
  BorderOutlined,
} from '@ant-design/icons';
import { Main } from '../components/system/main';
import { Dashboard } from '../pages/dashboard/dashboard';
import { Error404 } from '../pages/404';
import { Login } from '../pages/login/login';
import { Register } from '../pages/register/register';
import { UserManagement } from '../pages/userManagement/userManagement';
import { CustomerManagement } from '../pages/customerManagement/customerManagement';
import { ProjectManagement } from '../pages/projectManagement/projectManagement';
import { QuotaManagement } from '../pages/quotaManagement/quotaManagement';
import { ScaleManagement } from '../pages/scaleManagement/scaleManagement';
import { QuestionManagement } from '../pages/questionManagement/questionManagement';
import { PlanningAndGoalManagement } from '../pages/planningAndGoalManagement/planningAndGoalManagement';
import { Cards } from '../pages/cards/cards';
import { UserRoleManagement } from '../pages/userManagement/roleManagement/roleManagement';
import { QuotaTypeManagement } from '../pages/quotaManagement/quotaTypeManagement/quotaTypeManagement';
import { ScaleFactorManagement } from '../pages/scaleManagement/scaleFactorManagement/scaleFactorManagement';
import { ScaleJobManagement } from '../pages/scaleManagement/scaleJobManagement/scaleJobManagement';
import { City } from '../pages/address/city';
import { QuestionFactorManagement } from '../pages/questionManagement/questionFactorManagement/questionFactorManagement';
import { QuestionPoolManagement } from '../pages/questionManagement/questionPoolManagement/questionPoolManagement';
import { ScaleManageQuestion } from '../pages/scaleManagement/scaleManageQuestion';
import { ReportsIframe } from '../pages/reportsIframe/reports';
import { Reports } from '../pages/reports/reports';
import { PersonManagement } from '../pages/personManagement/personManagement';
import { CarManagement } from '../pages/carManagement/carManagement';
import { AuthRouteObject } from 'react-router-auth-plus';
import { District } from '../pages/address/district';
import { Neighbourhood } from '../pages/address/neighbourhood';
import { Street } from '../pages/address/street';
import { Building } from '../pages/address/building';
import { IndependentSection } from '../pages/address/independentSection';
import { ScaleUidManagement } from '../pages/scaleUidManagement/scaleUidManagement';

export const mainRouteList: AuthRouteObject[] = [
  {
    path: '/login',
    element: <Login />,
    id: 'login',
  },
  {
    path: '/register',
    element: <Register />,
    id: 'register',
  },
  {
    path: '/reportsIframe',
    element: <ReportsIframe />,
    id: 'reportsIframe',
  },
  {
    element: <Main />,
    isDashboard: true,
    id: 'layout',
    children: [
      {
        path: '/',
        element: <Dashboard />,
        id: 'dashboard',
        handle: {
          title: 'Dashboard',
          icon: DashboardOutlined,
        },
      },
      {
        path: '/user-management',
        id: 'user-management',
        handle: {
          icon: UserOutlined,
          title: 'UserManagement',
        },
        auth: ['*', 'user.index', 'role.index'],
        children: [
          {
            path: '/user-management/list',
            element: <UserManagement />,
            id: 'user-management-list',
            handle: {
              icon: UserOutlined,
              title: 'UserManagement',
            },
            auth: ['*', 'user.index'],
          },
          {
            path: '/user-management/role-management',
            element: <UserRoleManagement />,
            id: 'user-role-management',
            handle: {
              icon: BranchesOutlined,
              title: 'RoleManagement',
            },
            auth: ['*', 'role.index'],
          },
        ],
      },
      {
        path: '/customer-management',
        element: <CustomerManagement />,
        id: 'customer-management',
        handle: {
          icon: UserOutlined,
          title: 'CustomerManagement',
        },
        auth: ['*', 'customer.index'],
      },
      {
        path: '/project-management',
        element: <ProjectManagement />,
        id: 'project-management',
        handle: {
          icon: AppstoreOutlined,
          title: 'ProjectManagement',
        },
        auth: ['*', 'project.index'],
      },
      {
        path: '/quota-management',
        id: 'quota-management',
        handle: {
          icon: DatabaseOutlined,
          title: 'QuotaManagement',
        },
        auth: ['*', 'quota.index', 'quota-type.index'],
        children: [
          {
            path: '/quota-management/list',
            element: <QuotaManagement />,
            id: 'quota-management-list',
            handle: {
              icon: DatabaseOutlined,
              title: 'QuotaManagement',
            },
            auth: ['*', 'quota.index'],
          },
          {
            path: '/quota-management/type-management',
            element: <QuotaTypeManagement />,
            id: 'quota-type-management',
            handle: {
              title: 'QuotaTypeManagement',
              icon: DatabaseOutlined,
            },
            auth: ['*', 'quota-type.index'],
          },
        ],
      },
      {
        path: '/question-management',
        id: 'question-management',
        handle: {
          icon: QuestionCircleOutlined,
          title: 'QuestionManagement',
        },
        auth: ['*', 'question.index', 'question-factor.index', 'question-pool.index'],
        children: [
          {
            path: '/question-management/list',
            element: <QuestionManagement />,
            id: 'question-management-list',
            handle: {
              icon: QuestionCircleOutlined,
              title: 'QuestionManagement',
            },
            auth: ['*', 'question.index'],
          },
          {
            path: '/question-management/factor',
            element: <QuestionFactorManagement />,
            id: 'question-management-factor',
            handle: {
              icon: QuestionCircleOutlined,
              title: 'QuestionFactorManagement',
            },
            auth: ['*', 'question-factor.index'],
          },
          {
            path: '/question-management/pool',
            element: <QuestionPoolManagement />,
            id: 'question-management-pool',
            handle: {
              icon: QuestionCircleOutlined,
              title: 'QuestionPoolManagement',
            },
            auth: ['*', 'question-pool.index'],
          },
        ],
      },
      {
        path: '/scale-management',
        id: 'scale-management',
        handle: {
          icon: ForkOutlined,
          title: 'ScaleManagement',
        },
        auth: ['*', 'scale.index', 'scale-factor.index', 'scale-business.index'],
        children: [
          {
            path: '/scale-management/job',
            element: <ScaleJobManagement />,
            id: 'scale-management-job',
            handle: {
              icon: ForkOutlined,
              title: 'ScaleJobManagement',
            },
            auth: ['*', 'scale-business.index'],
          },
          {
            path: '/scale-management/factor',
            element: <ScaleFactorManagement />,
            id: 'scale-management-factor',
            handle: {
              icon: ForkOutlined,
              title: 'ScaleFactorManagement',
            },
            auth: ['*', 'scale-factor.index'],
          },
          {
            path: '/scale-management/list',
            element: <ScaleManagement />,
            id: 'scale-management-list',
            handle: {
              icon: ForkOutlined,
              title: 'ScaleManagement',
            },
            auth: ['*', 'scale.index'],
          },
          {
            path: '/scale-management/manage-question/:id',
            element: <ScaleManageQuestion />,
            id: 'scale-manage-question',
            handle: {
              icon: ForkOutlined,
              title: 'ScaleQuestionManagement',
              noMenu: true,
            },
          },
        ],
      },
      {
        path: '/person-management',
        element: <PersonManagement />,
        id: 'person-management',
        handle: {
          icon: TeamOutlined,
          title: 'PersonManagement',
        },
        auth: ['*', 'person.index'],
      },
      {
        path: '/car',
        id: 'car',
        element: <CarManagement />,
        handle: {
          title: 'CarManagement',
          icon: CarOutlined,
        },
        auth: ['*', 'car.index'],
      },
      {
        path: '/planing-and-goal-management',
        element: <PlanningAndGoalManagement />,
        id: 'planing-and-goal-management',
        handle: {
          icon: PushpinOutlined,
          title: 'PlanningAndGoalManagement',
        },
        auth: ['*', 'plan.index'],
      },
      {
        path: '/reports',
        id: 'reports',
        handle: {
          icon: BarChartOutlined,
          title: 'Report',
        },
        element: <Reports />,
        auth: ['*', 'scale.index'],
      },
      {
        path: '/scale-uids',
        id: 'scale-uid',
        handle: {
          icon: FileTextOutlined,
          title: 'ScaleUid',
        },
        element: <ScaleUidManagement />,
        auth: ['*', 'scale.index'],
      },
      {
        path: '/cards',
        element: <Cards />,
        id: 'Cards',
        handle: {
          icon: FileOutlined,
          title: 'Cards',
        },
      },
      {
        path: '/address-management',
        id: 'address-management',
        handle: {
          icon: EnvironmentOutlined,
          title: 'AddressManagement',
        },
        auth: [
          '*',
          'city.store',
          'district.store',
          'neighbourhood.store',
          'street.store',
          'building.store',
          'independent.store',
        ],
        children: [
          {
            path: '/address-management/city',
            element: <City />,
            id: 'address-management-city',
            handle: {
              icon: BorderOutlined,
              title: 'CityAdd',
            },
            auth: ['*', 'city.store'],
          },
          {
            path: '/address-management/district',
            element: <District />,
            id: 'address-management-district',
            handle: {
              icon: BorderOutlined,
              title: 'DistrictAdd',
            },
            auth: ['*', 'district.store'],
          },
          {
            path: '/address-management/neighbourhood',
            element: <Neighbourhood />,
            id: 'address-management-neighbourhood',
            handle: {
              icon: BorderOutlined,
              title: 'NeighbourhoodAdd',
            },
            auth: ['*', 'neighbourhood.store'],
          },
          {
            path: '/address-management/street',
            element: <Street />,
            id: 'address-management-street',
            handle: {
              icon: BorderOutlined,
              title: 'StreetAdd',
            },
            auth: ['*', 'street.store'],
          },
          {
            path: '/address-management/building',
            element: <Building />,
            id: 'address-management-building',
            handle: {
              icon: BorderOutlined,
              title: 'BuildingAdd',
            },
            auth: ['*', 'building.store'],
          },
          {
            path: '/address-management/independent',
            element: <IndependentSection />,
            id: 'address-management-independent',
            handle: {
              icon: BorderOutlined,
              title: 'UnitAdd',
            },
            auth: ['*', 'independent.store'],
          },
        ],
      },
    ],
  },
  {
    id: 'error-page',
    element: <Error404 />,
    path: '*',
  },
];

export const dashboardRoutes = (): AuthRouteObject[] => {
  const dashboardRoute = mainRouteList.find((route) => route.isDashboard);
  if (!dashboardRoute) return [];
  return dashboardRoute.children || [];
};

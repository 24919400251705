import { Form, Input, InputProps, Select } from "antd";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export const StyledFormItem = styled(Form.Item)`
`

export const StyledInput = styled(Input)`
`;

export const StyledSelect = styled(Select)`
`;

export const StyledNumberInput: React.FC<InputProps> = props => {
    const [val, setVal] = useState(props.value || props.defaultValue || props.min || "");

    useEffect(() => {
        if(!props.value) return;
        setVal(props.value);
    }, [props.value])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value = e.target.value.replace(/\D/g, '');
        let v = Number(e.target.value);
        if (props.min && v < props.min) {
            v = Number(props.min);
            e.target.value = String(v);
        }
        setVal(String(v))
        props.onChange && props.onChange(e);
    }, [props])
    return (
        <StyledInput
            {...props}
            value={val}
            inputMode="numeric"
            onChange={(e) => onChange(e)}
        />
    )
}

export const DecimalInput: React.FC<InputProps> = props => {

    const onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
        let start = e.target.selectionStart
        let val = e.target.value.toString();
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        if (!match) return;
        let value: any = match[1] + match[2];
        value = Number(value)
        e.target.value = value;
        props.onChange && props.onChange(value);
        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            e.target.setSelectionRange(start, start);
            props.onChange && props.onChange(Number(Number(value).toFixed(2)) as any);
        }
    }, [props])

    return (
        <StyledInput
            {...props}
            inputMode="decimal"
            value={String(Number(props.value).toFixed(2))}
            onChange={e => onChange(e)}
        />
    )

}
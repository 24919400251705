import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput } from '../../../components/form';
import { useCreateFormItems } from '../../../helpers/form';
import { ComponentTypes, ModalData } from '../../../models/system';
import { RoleCreateDto, RoleGetByIdDto, RoleUpdate } from '../../../services/role/role';
import { useRoleService } from '../../../services/role/role.service';

interface RoleManagementModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const RoleManagementModal: React.FC<RoleManagementModalProps> = props => {
    const [form] = useForm();
    const service = useRoleService();
    const { t } = useTranslation();
    const [state, setState] = useState<RoleGetByIdDto | null>(null);

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res) return;
        form.setFieldsValue(res);
        setState(res);
    }, [form, service, setState])

    useEffect(() => {
        if (props.data.data && !state) {
            getData(props.data.data)
        }
        // eslint-disable-next-line
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
        setState(null)
    }, [form, setState])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: RoleUpdate) => {
        let res: RoleCreateDto | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service, props.data.data])

    const formItems = useCreateFormItems<RoleCreateDto>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        }
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'RoleManagementForm'
            }}
        >
            <Form form={form} id="RoleManagementForm" onFinish={(data) => onSubmit(data)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
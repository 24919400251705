import { Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelect } from '../../../components/form';
import { useCreateFormItems } from '../../../helpers/form';
import { ComponentTypes, ModalData } from '../../../models/system';
import { PermissionResDto } from '../../../services/permission/permission';
import { usePermissionService } from '../../../services/permission/permission.service';
import { useStore } from '../../../store';

interface PermissionModalProps {
  data: ModalData<number>;
  onDone(): void;
}

export const PermissionModal: React.FC<PermissionModalProps> = (props) => {
  const service = usePermissionService();
  const store = useStore();
  const [, setState] = useState<PermissionResDto>();
  const [form] = useForm();
  const { t } = useTranslation();

  const getData = useCallback(
    async (id: number) => {
      const res = await service.show(id);
      if (!res) return;
      store.getAllPermissions();
      setState(res);
      form.setFieldsValue(res);
    },
    [setState, service, form, store],
  );

  useEffect(() => {
    if (!props.data.data) return;
    getData(props.data.data);
    // eslint-disable-next-line
  }, [props.data.data]);

  const onReset = useCallback(async () => {
    setState(undefined);
    form.resetFields();
  }, [form, setState]);

  const onDone = useCallback(async () => {
    onReset();
    props.onDone();
  }, [props, onReset]);

  const onSubmit = useCallback(
    async (data: PermissionResDto) => {
      if (!props.data.data) return;
      const res = await service.update(props.data.data, data);
      if (!res) return;
      onDone();
    },
    [props, service, onDone],
  );

  const formItems = useCreateFormItems<PermissionResDto>([
    {
      colProps: {
        span: '24',
      },
      element: (
        <StyledSelect
          mode='tags'
          options={store.data.permissionList.map((e) => ({ label: e, value: e }))}
          onDropdownVisibleChange={() => store.getAllPermissions()}
        />
      ),
      elementType: ComponentTypes.Input,
      formItem: {
        label: 'Permissions',
        name: 'permission',
      },
    },
  ]);

  return (
    <>
      <Modal
        title={t('Permission')}
        onCancel={() => onDone()}
        okButtonProps={{
          htmlType: 'submit',
          form: 'PermissionModal',
        }}
        open={props.data.open}>
        <Form form={form} id='PermissionModal' onFinish={(data) => onSubmit(data)}>
          <Row gutter={24}>{formItems}</Row>
        </Form>
      </Modal>
    </>
  );
};

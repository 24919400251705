import { Form, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledSelect } from '../../components/form';
import { useCreateFormItems } from '../../helpers/form';
import { ComponentTypes, ModalData } from '../../models/system';
import { UserCreateReqDto, UserCreateResDto } from '../../services/users/user';
import { useUserSerivce } from '../../services/users/users.service';
import { useStore } from '../../store';

interface UserManagementModalProps {
    data: ModalData<number>;
    onDone(): void;
    onCancel(): void;
}

export const UserManagementModal: React.FC<UserManagementModalProps> = props => {
    const [form] = useForm();
    const service = useUserSerivce();
    const { t } = useTranslation();
    const store = useStore();
    const [state, setState] = useState<UserCreateResDto | null>(null);

    const getData = useCallback(async (id: number) => {
        const res = await service.getById(id);
        if (!res) return;
        form.setFieldsValue(res);
        setState(res);
    }, [form, service])

    useEffect(() => {
        store.getRoleList();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.data.data)
        getData(props.data.data)
        // eslint-disable-next-line
    }, [props.data.data])

    const onReset = useCallback(async () => {
        form.resetFields();
    }, [form])

    const onDone = useCallback(async () => {
        onReset();
        props.onDone();
    }, [onReset, props])

    const onCancel = useCallback(async () => {
        onReset();
        props.onCancel();
    }, [onReset, props])

    const onSubmit = useCallback(async (data: UserCreateReqDto) => {
        let res: UserCreateResDto | false = false;
        if (props.data.data) {
            res = await service.update(props.data.data, data);
        } else {
            res = await service.create(data);
        }

        if (!res) return;
        onDone();
    }, [onDone, service, props.data.data])

    const formItems = useCreateFormItems<UserCreateReqDto>([
        {
            formItem: {
                name: 'name',
                label: 'Name',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 24
            },
            element: <StyledInput />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'email',
                label: 'Email',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledInput />,
            elementType: ComponentTypes.InputEmail
        },
        {
            formItem: {
                name: 'password',
                label: 'Password',
                required: props.data.data ? false : true,
                rules: [
                    {
                        required: props.data.data ? false : true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledInput.Password disabled={props.data.data !== null} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'project',
                label: 'Project',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect
                options={store.data.projectList.map(e => ({ label: e.name, value: e.id }))}
                onDropdownVisibleChange={() => store.getProjectList()}
            />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'role',
                label: 'Role',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 12
            },
            element: <StyledSelect options={store.data.roleList.map(e => ({ label: e.name, value: e.id }))} />,
            elementType: ComponentTypes.Input
        },
        {
            formItem: {
                name: 'description',
                label: 'Description',
                required: true,
                rules: [
                    {
                        required: true
                    }
                ]
            },
            colProps: {
                span: 24
            },
            element: <TextArea />,
            elementType: ComponentTypes.Input
        },
    ])

    return (
        <Modal
            open={props.data.open}
            title={t(props.data.data ? 'Update' : 'New')}
            onCancel={() => onCancel()}
            okButtonProps={{
                htmlType: 'submit',
                form: 'UserManagementForm'
            }}
        >
            <Form form={form} id="UserManagementForm" onFinish={(data) => onSubmit(data)}>
                <Row gutter={24}>
                    {formItems}
                </Row>
            </Form>
        </Modal>
    )
}
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Image, List, Avatar, Row, Col, Card } from 'antd';
import { Map } from '../../components/map';
import { useTranslation } from 'react-i18next';
import { useDashboardService } from '../../services/dashboard/dashboard.service';
import { OpinionsResDto } from '../../services/dashboard/dashboard';

interface DashboardProps {}

export const Dashboard: React.FC<DashboardProps> = (props) => {
  const { t } = useTranslation();
  const service = useDashboardService();
  const [state, setState] = useState<OpinionsResDto>();

  const getOpinions = useCallback(
    async (page: number) => {
      const res = await service.listOpinions(page);
      if (!res) return;
      setState(res);
    },
    [service],
  );

  useEffect(() => {
    getOpinions(1);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography.Title>{t('Overview')}</Typography.Title>
      <Map />
      <Row gutter={[24, 24]}>
        <Col span={24} lg={18}></Col>
        <Col span={24} lg={6}>
          <Card title='İstek Şikayet Not' bordered={true} bodyStyle={{ padding: '5px' }}>
            <List
              pagination={{
                onChange: (page) => {
                  getOpinions(page);
                },
                pageSize: 15,
              }}
              itemLayout='horizontal'
              dataSource={state?.data}
              renderItem={(item, index) => {
                return (
                  <>
                    {(item.suggest !== null && item.suggest !== '')  && (
                      <List.Item key={Math.random() * (index + 1)} style={{ padding: '3px' }}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.project?.logo} />}
                          title={
                            <div
                              style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}>
                              {item.project?.name} ( İstek )
                            </div>
                          }
                          description={
                            <>
                              <div
                                style={{
                                  wordWrap: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                  overflowWrap: 'break-word',
                                  wordBreak: 'break-word',
                                }}>
                                {item.suggest}
                              </div>
                              {item?.suggestImage && (
                                <>
                                  <br />
                                  <br />
                                  <Image style={{ borderRadius: 10 }} width={150} src={item?.suggestImage} />
                                </>
                              )}
                            </>
                          }
                        />
                      </List.Item>
                    )}
                    {(item.complaint !== null && item.complaint !== '') && (
                      <List.Item key={Math.random() * (index + 1)}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.project?.logo} />}
                          title={
                            <div
                              style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}>
                              {item.project?.name} ( Şikayet )
                            </div>
                          }
                          description={
                            <>
                              <div
                                style={{
                                  wordWrap: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                  overflowWrap: 'break-word',
                                  wordBreak: 'break-word',
                                }}>
                                {item.complaint}
                              </div>
                              {item?.complaintImage && (
                                <>
                                  <br />
                                  <br />
                                  <Image style={{ borderRadius: 10 }} width={150} src={item?.complaintImage} />
                                </>
                              )}
                            </>
                          }
                        />
                      </List.Item>
                    )}
                    {(item.note !== null && item.note !== '') && (
                      <List.Item key={Math.random() * (index + 1)}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.project?.logo} />}
                          title={
                            <div
                              style={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}>
                              {item.project?.name} ( Not )
                            </div>
                          }
                          description={
                            <>
                              <div
                                style={{
                                  wordWrap: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                  overflowWrap: 'break-word',
                                  wordBreak: 'break-word',
                                }}>
                                {item.note}
                              </div>
                              {item?.noteImage && (
                                <>
                                  <br />
                                  <br />
                                  <Image style={{ borderRadius: 10 }} width={150} src={item?.noteImage} />
                                </>
                              )}
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  </>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

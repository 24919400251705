import {ResponsiveBar} from '@nivo/bar';
import {ResponsivePie} from '@nivo/pie';
import {ResponsiveFunnel} from '@nivo/funnel';
import {Theme} from '@nivo/core';
import React, {useCallback, useMemo, useState} from 'react';
import {useTheme} from '../../hooks/theme';
import {ScaleAssigmentDto} from '../../services/scaleQuestion/scaleQuestion';
import {ReportQuestion} from './reportQuestion';
import {Collapse, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import WordCloud from 'react-d3-cloud';
import {scaleOrdinal} from 'd3-scale';
import {schemePaired} from 'd3-scale-chromatic';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import {useReportService} from "../../services/report/report.service";

interface ChartProps {
    data: ScaleAssigmentDto[];
    question_id: number;
    scale_id: number;
}

interface ChartData {
    id: string;
    label: string;
    value: number;
}

export const Chart: React.FC<ChartProps> = (props) => {
    const [chartType, setChartType] = useState<'Pie' | 'Bar' | 'Funnel' | 'Cloud'>('Pie');
    const {mode} = useTheme();
    const {t} = useTranslation();
    const [scaleWordCloud, setScaleWordCloud] = useState<any[]>([]);
    const service = useReportService();

    const getWords = useCallback(async () => {
        const tagCloud = await service.getTagClouds(props.scale_id, props.question_id);
        if (!tagCloud) return;
        let wcData: any[] = [];
        tagCloud.forEach((element) => {
            wcData.push({
                text: element.text
                    .replace('(Yazınız) ', '')
                    .replace('(Neden ?) ', '')
                    .replace('(Diğer.) ', '')
                    .replace('(Açıklama Yazınız.) ', ''),
                value: element.count,
            });
        });
        setScaleWordCloud(wcData);
    }, [props, service]);

    const chartTheme = useMemo((): Theme => {
        if (mode === 'light') return {};

        return {
            textColor: '#FFF',
            labels: {
                text: {
                    background: '#FFF',
                },
            },
            tooltip: {
                container: {
                    background: '#333',
                },
            },
        };
    }, [mode]);

    const data: ChartData[] = useMemo(() => {
        return props.data.map((e) => {
            return {
                id: e.answer.id.toString(),
                label: e.answer.answer.text,
                value: e.answer.reply_count || e.answer.reply_query_count || 0,
            };
        });
    }, [props]);

    const Pie = useCallback(() => {
        return (
            <ResponsivePie
                data={data}
                arcLinkLabel='label'
                arcLabel={'value'}
                theme={chartTheme}
                tooltip={(e) => (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                            borderRadius: '25px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            color: 'black',
                        }}>
                        {e.datum.data && e.datum.data.label}
                    </div>
                )}
                margin={{top: 20, right: 80, bottom: 80, left: 80}}
                innerRadius={0.5}
                padAngle={20}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
            />
        );
    }, [data, chartTheme]);

    const Bar = useCallback(() => {
        return (
            <ResponsiveBar
                keys={['value']}
                data={data.map((e) => ({
                    value: e.value,
                    label: e.label,
                }))}
                tooltip={(e) => (
                    <div
                        style={{
                            background: 'white',
                            padding: '9px 12px',
                            border: '1px solid #ccc',
                            borderRadius: '25px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            color: 'black',
                        }}>
                        {e.data && e.data.label}
                    </div>
                )}
                valueScale={{type: 'linear'}}
                theme={chartTheme}
                margin={{top: 50, right: 0, bottom: 50, left: 60}}
                padding={0.4}
                colors='#3182CE'
                animate={true}
                enableLabel={false}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Oran',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                indexBy='label'
            />
        );
    }, [data, chartTheme]);

    const Funnel = useCallback(() => {
        return (
            <ResponsiveFunnel
                data={data}
                margin={{top: 20, right: 20, bottom: 20, left: 20}}
                valueFormat='>-.4s'
                colors={{scheme: 'spectral'}}
                borderWidth={20}
                labelColor={{
                    from: 'color',
                    modifiers: [['darker', 3]],
                }}
                enableLabel={true}
                // theme={chartTheme}
                beforeSeparatorLength={100}
                beforeSeparatorOffset={20}
                afterSeparatorLength={100}
                afterSeparatorOffset={20}
                currentPartSizeExtension={10}
                currentBorderWidth={40}
                motionConfig='wobbly'
            />
        );
    }, [data, chartTheme]);

    const schemeCategory10ScaleOrdinal = scaleOrdinal(schemePaired);

    const chartTypeOptions = useMemo(() => {
        return [
            {label: 'Pie', value: 'Pie'},
            {label: 'Bar', value: 'Bar'},
            {label: 'Funnel', value: 'Funnel'},
            {label: 'Cloud', value: 'Cloud'}
        ];
    }, []);

    return (
        <>
            <Select
                defaultValue={chartType}
                style={{width: '150px', marginBottom: '20px'}}
                options={chartTypeOptions}
                onSelect={(v) => {
                    setChartType(v)
                    if (v === 'Cloud') {
                        getWords().then(r => console.log(r));
                    }
                }}
            />
            <div style={{height: '350px', width: '100%'}}>
                {chartType === 'Pie' && Pie()}
                {chartType === 'Bar' && Bar()}
                {chartType === 'Funnel' && Funnel()}
                {chartType === 'Cloud' && (
                    <WordCloud
                        // @ts-ignore
                        data={scaleWordCloud || []}
                        width={250}
                        height={150}
                        font='Times'
                        fontWeight='bold'
                        fontSize={(word: any) => Math.log(word.value + 5) * 10}
                        spiral='rectangular'
                        padding={5}
                        fill={(d: any, i: string) => schemeCategory10ScaleOrdinal(i)}
                        rotate={() => 0}
                    />
                )}
            </div>
            <Collapse>
                {props.data.map((a) => {
                    if (a.realeted) {
                        return (
                            <Collapse.Panel header={t('ConditionalQuestionHeader', {value: a.answer.answer.text})}
                                            key={a.id}>
                                <ReportQuestion
                                    data={{
                                        ...a,
                                        answer_type: {id: '', text: ''},
                                        parent_id: 0,
                                        question_id: 0,
                                        scale_id: 0,
                                        question: [
                                            {
                                                ...(a as any),
                                                assigment: a.realeted.assigment,
                                                question: {
                                                    text: a.realeted.question.text,
                                                },
                                                parent: a.realeted.parent,
                                            },
                                        ],
                                        sort: 0,
                                    }}
                                    question_id={props.question_id}
                                    scale_id={props.scale_id}
                                />
                            </Collapse.Panel>
                        );
                    }
                    return null;
                })}
            </Collapse>
        </>
    );
};
